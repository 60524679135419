import {
  EnsembleAction,
  useRegisterBindings,
} from "@ensembleui/react-framework";
import { useEnsembleAction, WidgetRegistry } from "@ensembleui/react-runtime";
import React, { useState } from "react";
import { ColorPicker as AntdColorPicker } from "antd";
import { Color } from "antd/es/color-picker";
interface ColorPickerProps {
  text: string;
  id: string;
  selectedColor?: string;
  onChange?: EnsembleAction;
  onBlur?: EnsembleAction;
}

export const ColorPicker: React.FC<ColorPickerProps> = ({
  text,
  id,
  selectedColor = "ffffff",
  onChange,
  onBlur,
}) => {
  const { values } = useRegisterBindings(
    {
      selectedColor,
    },
    id,
  );
  const [color, setColor] = useState<string>(
    values?.selectedColor ?? selectedColor,
  );
  const changeAction = useEnsembleAction(onChange);
  const blurAction = useEnsembleAction(onBlur);

  const handleColorChange = (color: Color) => {
    const newColor = `#${color.toHex()}`;
    setColor(newColor);
    if (changeAction) {
      changeAction.callback({ value: newColor });
    }
  };
  const handleOnBlur = () => {
    if (blurAction) {
      blurAction.callback({ value: color });
    }
  };
  return (
    <div>
      <p style={{ fontSize: "12px", color: "white", fontWeight: "500" }}>
        {text}
      </p>

      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <AntdColorPicker
          value={color}
          defaultFormat="hex"
          disabledAlpha
          onChangeComplete={handleColorChange}
          onOpenChange={(open) => {
            if (!open) handleOnBlur();
          }}
        >
          <div
            className={"color-picker-trigger"}
            style={{ alignItems: "center", gap: 10, display: "flex" }}
          >
            <div
              className={"color-box"}
              style={{ backgroundColor: color }}
            ></div>
            <p style={{ margin: 0 }}>{color}</p>
          </div>
        </AntdColorPicker>
      </div>
    </div>
  );
};

WidgetRegistry.register("ColorPicker", ColorPicker);
