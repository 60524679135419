import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  MessageModel,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useAppContext } from "../../pages/AppPagesWrapper";

import "./ChatAssistant.sass";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import ReactMarkdown from "react-markdown";
import { useYamlDoc } from "../../hooks/useYamlDoc";
import { CST, Document, Node } from "yaml";
import { last } from "lodash-es";
import { useSchemas } from "../../hooks/useSchemas";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";
import { cloudFunctions } from "../../config/firebase";

export type ChatAssistantProps = {
  artifactId: string;
};
export const ChatAssistant: React.FC<ChatAssistantProps> = ({ artifactId }) => {
  const { assistantEnabled } = useFeatureFlag();
  const appContext = useAppContext();
  const { doc, updateDocContent } = useYamlDoc();
  const schemaStore = useSchemas();
  const [messages, setMessages] = useState<MessageModel[]>([]);
  const [threadId, setThreadId] = useState<string>();
  const sendMessage = useMutation(
    async ({ query, doc }: { query: string; doc: Document<Node, true> }) => {
      const result = await cloudFunctions.builder_query_doc({
        appId: appContext.app.id,
        screenId: artifactId,
        threadId,
        query,
        edl: CST.stringify(doc.contents!.srcToken!),
      });

      return {
        result,
      };
    },
  );

  useEffect(() => {
    if (sendMessage.isSuccess) {
      console.log(JSON.stringify(sendMessage.data));
      const edl = (sendMessage.data.result as any).data.output.edl;

      // we are not apply any changes yet
      // updateDocContent(ArtifactType.screen, edl, schemaStore, true);

      setMessages((previousMessages) => {
        if (last(previousMessages)?.sender !== "Ensemble Assistant") {
          previousMessages.push({
            sender: "Ensemble Assistant",
            message: edl,
            position: "normal",
            sentTime: new Date().toLocaleDateString(),
            direction: "incoming",
          });
          return [...previousMessages];
        }
        return previousMessages;
      });
      setThreadId((sendMessage.data.result as any).data.output.threadId);
    }
  }, [sendMessage.isSuccess, updateDocContent, schemaStore, sendMessage.data]);

  if (!assistantEnabled) {
    return null;
  }

  return (
    <div>
      <MainContainer>
        <ChatContainer>
          <MessageList
            typingIndicator={sendMessage.isLoading ? <TypingIndicator /> : null}
            style={{ height: "600px" }}
          >
            {messages.map((msg) => (
              <Message key={msg.sentTime} model={msg}>
                <Message.CustomContent>
                  <ReactMarkdown>{msg.message}</ReactMarkdown>
                </Message.CustomContent>
              </Message>
            ))}
          </MessageList>
          <MessageInput
            placeholder="Ask the assistant"
            sendButton={true}
            attachButton={false}
            onSend={async (_, textContent) => {
              if (!doc) {
                return;
              }
              sendMessage.mutate({ query: textContent, doc });
              setMessages((prevMsgs) => {
                prevMsgs.push({
                  message: textContent,
                  sender: "You",
                  direction: "outgoing",
                  position: "normal",
                  sentTime: new Date().toLocaleDateString(),
                });
                return prevMsgs;
              });
            }}
          />
        </ChatContainer>
      </MainContainer>
    </div>
  );
};
