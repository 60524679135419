import { Form } from "antd";
import { toast } from "react-toastify";
import LabelHeader from "./LabelHeader";
import LabelManagerForm from "./LabelManagerFrom";
import { labelGroupData } from "../../config/interfaces";
import { useUpdateLabels } from "../../hooks/useFirebase";
import { useAppContext } from "../../pages/AppPagesWrapper";
import { useEffect, useState, useCallback, useMemo } from "react";
import { Button, ButtonVariant } from "../../components/Button/Button";

interface LabelManagerProps {
  onClose: () => void;
  onSubmit: () => void;
}

interface LabelData {
  name: string;
  screens?: number;
  widgets?: number;
  scripts?: number;
}

const LabelManager = ({ onClose, onSubmit }: LabelManagerProps) => {
  const [form] = Form.useForm();
  const { app } = useAppContext();
  const updateLabelQuery = useUpdateLabels(app.id);

  // Initialize labels from app context
  const [deletedLabels, setDeletedLabels] = useState<string[]>([]);
  const [newLabels, setNewLabels] = useState(new Map<string, string>());

  // Converting value from string to LabelData
  const initialLabels = useMemo(() => {
    const countItemsByLabelGroup = (items: any[], map: Map<string, number>) => {
      items?.forEach((item) => {
        const labelGroupId = item.labelGroup?.id;
        if (labelGroupId) {
          map.set(labelGroupId, (map.get(labelGroupId) ?? 0) + 1);
        }
      });
    };

    const screensCount = new Map<string, number>();
    const widgetsCount = new Map<string, number>();
    const scriptsCount = new Map<string, number>();

    // Count screens, widgets, and scripts by label group
    countItemsByLabelGroup(app.screens ?? [], screensCount);
    countItemsByLabelGroup(app.internalWidgets ?? [], widgetsCount);
    countItemsByLabelGroup(app.internalScripts ?? [], scriptsCount);

    return new Map(
      Array.from(app?.groupLabels ?? new Map<string, string>()).map(
        ([key, value]) => [
          key,
          {
            name: value,
            screens: screensCount.get(key) ?? 0,
            widgets: widgetsCount.get(key) ?? 0,
            scripts: scriptsCount.get(key) ?? 0,
          },
        ],
      ),
    );
  }, [app?.groupLabels, app.internalScripts, app.internalWidgets, app.screens]);

  const [labels, setLabels] = useState<Map<string, LabelData>>(
    () =>
      new Map<string, LabelData>([
        ...initialLabels,
        [Date.now().toString(), { name: "" }],
      ]),
  );

  const handleInputChange = (labelId: string, index: number, value: string) => {
    const newLabelsMap = new Map(labels);
    const labelData = labels.get(labelId) ?? {};

    newLabelsMap.set(labelId, { ...labelData, name: value.trim() });

    if (value && index === labels.size - 1)
      newLabelsMap.set(Date.now().toString(), { name: "" });

    if (!app.groupLabels?.has(labelId)) {
      const updatedNewLabels = new Map(newLabels).set(labelId, value.trim());
      setNewLabels(updatedNewLabels);
    }

    setLabels(newLabelsMap);
    form.setFieldsValue({ [labelId]: value }); // Ensure the form reflects the state change
  };

  const filterUpdatedLabels = () => {
    const updatedLabels: labelGroupData[] = [];

    if (app.groupLabels)
      for (const [key, value] of labels) {
        const labelName = app.groupLabels.get(key);
        if (labelName && labelName !== value.name)
          updatedLabels.push({ id: key, name: value.name });
      }

    return updatedLabels;
  };

  const validateUniqueNames = (): boolean => {
    const nameCount: Record<string, number> = {};
    let hasDuplicates = false;

    labels.forEach(({ name }) => {
      const trimmedName = name.trim();
      nameCount[trimmedName] = (nameCount[trimmedName] || 0) + 1;
      if (nameCount[trimmedName] > 1) hasDuplicates = true;
    });

    if (hasDuplicates) {
      form.setFields(
        [...labels.entries()].map(([key, labelData]) => ({
          name: key,
          errors:
            nameCount[labelData.name.trim()] > 1
              ? ["Duplicate names are not allowed."]
              : [],
        })),
      );
    }

    return !hasDuplicates;
  };

  const handleSubmit = () => {
    form.validateFields().then(() => {
      if (!validateUniqueNames()) return;

      const newLabelsData = Array.from(newLabels.values()).filter(Boolean);
      updateLabelQuery.mutate({
        newLabels: newLabelsData,
        deletedLabels,
        updatedLabels: filterUpdatedLabels(),
      });
    });
  };

  const handleRemoveLabel = useCallback(
    (labelId: string) => {
      const updatedLabels = new Map(labels);
      updatedLabels.delete(labelId);

      const updatedNewLabels = new Map(newLabels);
      updatedNewLabels.delete(labelId);

      setLabels(updatedLabels);
      setNewLabels(updatedNewLabels);

      if (app.groupLabels?.has(labelId))
        setDeletedLabels((prev) => [...prev, labelId]);
    },
    [labels, newLabels, app.groupLabels],
  );

  const submitSuccess = useCallback(() => {
    toast.success("Labels have been updated.", {
      theme: "dark",
      position: "top-right",
    });
    onSubmit();
  }, [onSubmit]);

  const submitError = useCallback(() => {
    toast.error("Something went wrong while updating the labels", {
      theme: "dark",
      position: "top-right",
    });
  }, []);

  useEffect(() => {
    if (updateLabelQuery.isSuccess) submitSuccess();
    else if (updateLabelQuery.isError) submitError();
  }, [
    submitError,
    submitSuccess,
    updateLabelQuery.isSuccess,
    updateLabelQuery.isError,
  ]);

  return (
    <div className="label-manager">
      <LabelHeader appId={app.id} appName={app?.name ?? ""} onClick={onClose} />
      <div className="label-manager__content">
        <div className="label-manager__heading">
          <h4>Labels</h4>
          <span className="label-manager__description">
            Deleting a label does not impact the labeled artifacts.
          </span>
        </div>

        <LabelManagerForm
          form={form}
          labels={labels}
          initialLabels={app?.groupLabels}
          onInputChange={handleInputChange}
          onRemoveLabel={handleRemoveLabel}
        />

        <div className="label-manager__submit">
          <Button
            onClick={onClose}
            variant={ButtonVariant.SECONDARY}
            disabled={updateLabelQuery.isLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant={ButtonVariant.PRIMARY}
            disabled={updateLabelQuery.isLoading}
          >
            {updateLabelQuery.isLoading ? "Saving..." : "Save"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LabelManager;
