import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { addSecret, removeSecret } from "../hooks/useAPIs";
import { ReactComponent as IconDelete } from "../assets/icon_delete.svg";
import { SECRETS_QUERY_ID } from "../pages/AppSettings";
import Modal from "../components/Modal";
import { Button, ButtonVariant } from "../components/Button/Button";

export interface SecretProps {
  appId: string;
  userId: string;
  isLoading: boolean;
  secrets?: Map<string, unknown>;
}

export const Secrets: React.FC<SecretProps> = ({
  appId,
  userId,
  isLoading,
  secrets,
}) => {
  const [newKey, setNewKey] = useState("");
  const [newValue, setNewValue] = useState("");
  const [doWarn, setDoWarn] = useState(false);
  const queryClient = useQueryClient();

  const useAddSecret = useMutation<
    unknown,
    unknown,
    { newKey: string; newValue: string }
  >(
    async () => {
      await addSecret(appId, userId, newKey, newValue);
    },
    {
      onSuccess: () => queryClient.invalidateQueries(SECRETS_QUERY_ID),
    },
  );

  const useRemoveSecret = useMutation<unknown, unknown, { envKey: string }>(
    async ({ envKey }) => {
      await removeSecret(appId, userId, envKey);
    },
    {
      onSuccess: () => queryClient.invalidateQueries(SECRETS_QUERY_ID),
    },
  );

  const onAddSecretSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (secrets?.has(newKey)) {
      setDoWarn(true);
    } else {
      useAddSecret.mutate({
        newKey,
        newValue,
      });
    }
  };

  const onRemoveSecretClick = async (envKey: string) => {
    useRemoveSecret.mutate({ envKey });
  };

  return (
    <div>
      <p className="small">
        Use secrets.secret_name to access them throughout your app.
        <br />
        Note that if you are referring to a variable in YAML, you need to wrap
        it inside {"${secrets.secret_name}"}.{" "}
        <a
          href="https://docs.ensembleui.com/#/build/secrets?id=application-secrets"
          target="blank"
        >
          Docs
        </a>
      </p>
      <div>
        {secrets &&
          !isLoading &&
          Array.from(secrets.keys()).map((key) => {
            const value = String(secrets?.get(key));
            return (
              <div className="env-card" key={key}>
                <p>{key}</p>
                <p>{value.substring(0, 3) + "*".repeat(13)}</p>
                <IconDelete
                  className="button__icon"
                  onClick={() => onRemoveSecretClick(key)}
                />
              </div>
            );
          })}
      </div>

      {/*add new environment variable*/}
      <form onSubmit={onAddSecretSubmit}>
        <div className="env-card-editable">
          <div>
            <label>Key</label>
            <input
              type="text"
              value={newKey}
              onChange={(event) => setNewKey(event.currentTarget.value)}
            />
          </div>
          <div className="env-card-editable-inputs">
            <label>Value</label>
            <input
              type="text"
              value={newValue}
              style={{ width: "400px" }}
              onChange={(event) => setNewValue(event.currentTarget.value)}
            />
          </div>
          <div>
            <button
              type="submit"
              className="button__primary"
              disabled={useAddSecret.isLoading || !newKey}
            >
              {(useAddSecret.isIdle || useAddSecret.isSuccess) && <>Add</>}
              {useAddSecret.isLoading && <>Adding ...</>}
            </button>
          </div>
        </div>
        <span className="status" style={{ padding: "0 10px" }}>
          {useAddSecret.isSuccess && <>Secret added !</>}
          {useAddSecret.isError && <>Ooops ! We have a problem.</>}
        </span>
      </form>
      <Modal
        modalContent={
          <>
            <div style={{ marginBottom: "20px" }}>
              You are overriding the value for secret &quot;{newKey}&quot;. Are
              you sure?
            </div>
            <div>
              <Button
                variant={ButtonVariant.PRIMARY}
                onClick={() => setDoWarn(false)}
                style={{ marginRight: "8px" }}
              >
                Cancel
              </Button>
              <Button
                variant={ButtonVariant.SECONDARY}
                onClick={() => {
                  useAddSecret.mutate({
                    newKey,
                    newValue,
                  });
                  setDoWarn(false);
                }}
              >
                Overwrite
              </Button>
            </div>
          </>
        }
        onHide={() => setDoWarn(false)}
        isModalDisplayed={doWarn}
        headerText={"Overwrite Secret"}
      />
    </div>
  );
};
