import dayjs from "dayjs";
import config from "../config/config";
import { JSONSchema7 } from "json-schema";
import isYesterday from "dayjs/plugin/isYesterday";
import { KeyValuePair } from "../config/interfaces";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(isYesterday);
dayjs.extend(relativeTime);

export const SESSION_FORCED_LOCALE = "forcedLocale";

export const parseWidgetNames = (schema: JSONSchema7) => {
  const widgets = [];
  const data = (schema?.$defs?.Widget as JSONSchema7).oneOf ?? [];
  for (const item in data) {
    const base = (data[item] as JSONSchema7).properties ?? {};
    const name = Object.keys(base)[0];
    const description = (base[name] as JSONSchema7).description;
    const exampleLinkStart = description?.indexOf("https://");
    widgets.push({
      key: name,
      exampleLink: description?.substring(exampleLinkStart ?? 0),
    });
  }

  const system = Object.keys(schema?.properties ?? {});

  return { widgets: widgets, systemProps: system };
};

export const createArrayOfNumbers = (length: number) =>
  Array.from({ length: length }, (_, i) => i);

export const getDateTime = (date: Date) =>
  date.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

export const timePassed = (date: Date): string => {
  const dayjsDate = dayjs(date);

  if (dayjsDate.isYesterday())
    return `Yesterday at ${dayjsDate.format("h:mm A")}`;

  const diffInDays = dayjs().diff(dayjsDate, "day");
  if (diffInDays >= 1) return dayjsDate.format("MMM D, YYYY");

  return dayjsDate.fromNow();
};

export const formatWidgetName = (str: string) => {
  // Remove spaces and special characters
  str = str.replace(/[^\w\s]/gi, "").replace(/\s+/g, "");
  // Convert first character to uppercase
  str = str.charAt(0).toUpperCase() + str.slice(1);
  return str;
};

export const formatScriptName = (str: string) => {
  // Remove spaces and special characters
  str = str.replace(/[^\w\s]/gi, "").replace(/\s+/g, "");
  return str;
};

export const getInputParams = (inputs: KeyValuePair[]) => {
  let paramsUrl = "";
  inputs.forEach((input) => {
    if (input.key)
      paramsUrl = paramsUrl.concat(
        `&inputs-${input.key}=${encodeURIComponent(input.value)}`,
      );
  });
  return paramsUrl;
};

export const getArtifactPreviewUrl = (
  appId: string,
  artifactId: string,
  inputParams: KeyValuePair[],
  useReact?: boolean,
) => {
  if (useReact) {
    const inputQueryString = new URLSearchParams(
      inputParams.map(({ key, value }) => [key, value]),
    ).toString();
    return `${config.previewReact.url}/preview/${appId}?screenId=${artifactId}${inputQueryString}`;
  }
  const forcedLocale = sessionStorage.getItem(SESSION_FORCED_LOCALE);
  const localeParam = forcedLocale ? `&forcedLocale=${forcedLocale}` : "";

  return `${
    config.preview.relativeUrl
  }?appId=${appId}&screenId=${artifactId}&showAction=false&devicePreview=true&instantPreview=true${localeParam}${getInputParams(
    inputParams,
  )}`;
};
