import { DataNode } from "rc-tree/lib/interface";
import { Pair, Scalar, YAMLMap } from "yaml";
import { NodeAction } from "../../hooks/useYamlDoc";

// our TreeNode with additional YAML Node reference
export type TreeNode = DataNode & {
  ref: NodeRef;
  payload?: TreeItemPayload;
};

// a node reference with its parent
export type NodeRef = {
  node: Pair | Scalar;
  parent: YAMLMap | null;
  category?: NodeCategory;
  action?: NodeAction;
};

export enum NodeCategory {
  ViewRoot = "viewRoot",
  ViewGroupRoot = "viewGroupRoot",
  WidgetRoot = "widgetRoot",

  CustomWidget = "customWidget",
  API = "api",
  CodeBlock = "codeBlock",

  Header = "header",
  Footer = "footer",
}

export interface TreeItemPayload {}

// payload for setting the widget map in the tree
export class TreeItemWidgetPayload implements TreeItemPayload {
  constructor(
    readonly property: string,
    readonly label: string,
  ) {}
}

/**
 * Containers normally would render their children under the property "children".
 * You may override this behavior in the schema in two ways:
 * 1. Specifying a itemTemplate-like on how to find the children array and the item to each path/subtitle:
 *    a. @childrenOverride <children_array_path>:<child_item_path>.
 *       Use array_path to get to the children array, which the item_path is the path to each item itself
 *       For example TabBar would be "@childrenOverride items:widget"
 *    b. @childrenOverrideSubtitle <path_to_subtitle>.
 *       Use this to specify the path to the subtitle of each child item.
 *  2. Manually list out each child and its label:
 *    a. @childrenOverride [child1_path, child2_path|child2_fallback_path,...]
 *    b. @childrenOverrideSubtitle [child1_label, child2_label,...]
 */
export class ChildrenOverride {
  constructor(
    readonly override: string,
    readonly overrideSubtitle?: string,
    readonly showSubtitleLabel?: boolean, // default don't show the subtitle label
  ) {}

  getChildrenArray(): ChildPath[] | undefined {
    const arrayRegex = /^\[(.*)]$/;
    const match = this.override.match(arrayRegex);
    if (match) {
      const tokens = match[1].split(",").map((token) => token.trim());

      // check if we have labels too
      let labelTokens: string[] = [];
      const labelMatch = this.overrideSubtitle?.match(arrayRegex);
      if (labelMatch) {
        labelTokens = this.overrideSubtitle!.split(",").map((token) =>
          token.trim(),
        );
      }

      return tokens.map((token, index) => {
        return {
          path: token,
          label: index < labelTokens.length ? labelTokens[index] : undefined,
          showLabel: this.showSubtitleLabel,
        };
      });
    }
  }

  getChildrenTemplate(): ChildrenTemplate | undefined {
    if (this.override.startsWith("[") && this.override.endsWith("]")) return;

    const tokens = this.override.split(":").map((token) => token.trim());
    if (tokens.length <= 2) {
      let child: ChildPath | undefined;
      if (tokens.length === 2) {
        child = {
          path: tokens[1],
          label: this.overrideSubtitle,
          showLabel: this.showSubtitleLabel,
        };
      }
      return {
        property: tokens[0],
        childPath: child,
      };
    }
  }
}

export interface ChildrenTemplate {
  property: string;
  childPath?: ChildPath;
}

export interface ChildPath {
  path: string;
  label?: string;
  showLabel?: boolean; // whether we should show the label together with the value (default false)
}
