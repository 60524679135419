import React, { useMemo, useRef } from "react";
import "./FieldTemplate.sass";
import { ADDITIONAL_PROPERTY_FLAG, FieldTemplateProps } from "@rjsf/utils";
import { camelCaseToWords } from "../utils/propertyPanelUtils";
import { Tooltip } from "antd";
import {
  CustomJSONSchema7,
  CustomUIField,
} from "../utils/propertyPanelSchemaUtils";
import { RemixIcon } from "../../../components/Widgets";
import { ActionMenu } from "../../../components/VisualEditor/ActionMenu";

/**
 * This template wraps around EVERY field, including objects and arrays
 */
export const FieldTemplate: React.FC<FieldTemplateProps> = (props) => {
  const {
    id,
    label,
    displayLabel,
    required,
    children,
    schema,
    uiSchema,
    formContext,
    onKeyChange,
    onDropPropertyClick,
  } = props;
  const hiddenRemoveButtonRef = useRef(null);

  const tooltipTitle = useMemo(() => {
    const description = schema.description;
    const defaultValue = (schema as CustomJSONSchema7).defaultValue;

    if (!description && !defaultValue) {
      return null;
    }

    return (
      <>
        {schema.description}
        {(schema as CustomJSONSchema7).defaultValue && (
          <>
            <br />
            Default:{" "}
            <span className="property-panel-tooltip-emphasized-token">
              {(schema as CustomJSONSchema7).defaultValue?.toString()}
            </span>
          </>
        )}
      </>
    );
  }, [schema]);

  const additionalProperties = ADDITIONAL_PROPERTY_FLAG in schema;
  if (additionalProperties) {
    // When rendering key/value pairs and the value is null (e.g. Event's data where only the key is used), we want a few UI optimizations:
    //  1. don't show the card-like vertical bar that group the key/value pair (since it only has a key and no value)
    //  2. don't bother render the children since that will add some additional empty spacing
    const hasEmptyValue = schema.type === "null";

    // Note that the label and Add button are rendered
    // in KeyValuePairFieldTemplate (variant of ObjectFieldTemplate)
    // The below is the individual key/value pair
    return (
      <div className={"field-template-additional-properties-item"}>
        <div className={"field-template-additional-properties-item-actions"}>
          {/* terrible RJSF forces deletion to be a button since it calls event.preventDefault() so we simulate a button click from menu here */}
          <button
            ref={hiddenRemoveButtonRef}
            style={{ display: "none" }}
            onClick={onDropPropertyClick(label)}
          />
          <ActionMenu
            anchor={
              <span
                className={"field-template-additional-properties-item-remove"}
              >
                <RemixIcon name={"more-2-line"} />
              </span>
            }
            items={[
              {
                iconName: "delete-bin-7-line",
                label: "Delete",
                value: "delete",
              },
            ]}
            onSelect={(value) => {
              if (value === "delete") {
                (hiddenRemoveButtonRef.current as any)?.click();
                formContext.submitFormLater();
              }
            }}
          />
          {/* add a Card-like left border if both key and value are there */}
          {!hasEmptyValue && (
            <div
              className={
                "field-template-additional-properties-item-actions-separator"
              }
            ></div>
          )}
        </div>

        <div className={"field-template-additional-properties-item-content"}>
          {/* render additionalProperties's key as an input field */}
          <div className={"custom-field-template-content"}>
            <div className={"field-widget"}>
              <input
                type="text"
                id={`${id}-key`}
                onBlur={(event) => {
                  onKeyChange(event.target.value);
                  formContext.submitFormLater();
                }}
                defaultValue={label}
              />
            </div>
          </div>

          {/* now the regular children widgets */}
          {!hasEmptyValue &&
            (schema.type !== "object" ? (
              <div className="custom-field-template-content">
                <div className={"field-widget"}>{children}</div>
              </div>
            ) : (
              <>{children}</>
            ))}
        </div>
      </div>
    );
  }

  // let object and array handle their own layout
  const isGroupField = schema.type === "object" || schema.type === "array";
  if (isGroupField) {
    return <>{children}</>;
  }

  // RJSF determines displayLabel based on a few criteria https://github.com/rjsf-team/react-jsonschema-form/blob/main/packages/utils/src/schema/getDisplayLabel.ts
  // This means by default all custom Fields and some more do not show label.
  // This functions let the FieldTemplate shows the label so we don't have to handle it in all fields.
  const shouldDisplayLabel = (): boolean => {
    // RJSF butchered the displayLabel (ui:option's showLabel) logic so we can't really use it effectively.
    // Hence we use our own showLabelOverride instead
    const customUiField = uiSchema?.["ui:options"] as CustomUIField | undefined;
    const showLabelOverride = customUiField?.showLabelOverride;
    if (showLabelOverride != null) {
      return showLabelOverride;
    }
    // not sure why RJSF disables label for boolean. We need it.
    if (schema.type === "boolean") {
      return true;
    }
    return displayLabel !== false || uiSchema?.["ui:field"] !== undefined;
  };

  return (
    <div className="custom-field-template-content">
      {shouldDisplayLabel() && (
        <Tooltip
          overlayClassName={"property-panel-tooltip"}
          title={tooltipTitle}
          placement={"left"}
        >
          <label
            htmlFor={id}
            className={`field-label ${required ? "required" : ""}`}
          >
            {camelCaseToWords(label)}
          </label>
        </Tooltip>
      )}
      <div className={"field-widget"}>{children}</div>
    </div>
  );
};
