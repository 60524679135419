import { NodeRef } from "./TreeNode";
import React from "react";
import { TreePanel } from "./TreePanel";
import { ArtifactType } from "../../pages/EditorPage";
import { RemixIcon } from "../../components/Widgets";
import { ErrorBoundary } from "../../components/ErrorBoundary";
import { ChatAssistant } from "../ChatAssistant/ChatAssistant";

export type NavigatorPanelProps = {
  onNodeSelected: (node: NodeRef | null) => Promise<boolean>;
  artifactId: string;
  artifactType: ArtifactType;
  treeClosed: boolean;
  hideTreeView: () => void;
};

// const actionTabsList = [{ id: "tab-1", label: "Navigator" }];

export const NavigatorPanel: React.FC<NavigatorPanelProps> = ({
  onNodeSelected,
  artifactType,
  artifactId,
  treeClosed,
  hideTreeView,
}) => {
  // const [selectedTab, setSelectedTab] = useState<string>("tab-1");

  if (treeClosed) {
    return (
      <div className="action-tab-closed">
        <div>
          <button onClick={hideTreeView} className={"widget-toggle-btn"}>
            <div className={"tooltip-container navigator-panel-control"}>
              <RemixIcon name={"expand-right-line"} />
              <span className={"tooltip"}>Expand</span>
            </div>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="navigator-panel">
      <div className="action-tab-container">
        {/*<div className="action-tab-section">*/}
        {/*  {actionTabsList.map((act) => {*/}
        {/*    const labelStyle = act.id === selectedTab ? "active" : "inactive";*/}
        {/*    return (*/}
        {/*      <button*/}
        {/*        key={act.id}*/}
        {/*        className={`tab-label ${labelStyle}`}*/}
        {/*        onClick={() => setSelectedTab(act.id)}*/}
        {/*      >*/}
        {/*        {act.label}*/}
        {/*      </button>*/}
        {/*    );*/}
        {/*  })}*/}
        {/*</div>*/}
        <div>
          <button onClick={hideTreeView} className={"widget-toggle-btn"}>
            <div className={"tooltip-container navigator-panel-control"}>
              <RemixIcon name={"expand-left-line"} />
              <span className={"tooltip"}>Collapse</span>
            </div>
          </button>
        </div>
      </div>
      <div className="navigator-panel-content">
        <ErrorBoundary
          fallback={
            <div className={"error-boundary"}>
              <p>Error rendering the Preview Tree</p>
            </div>
          }
        >
          <TreePanel
            artifactType={artifactType}
            onNodeSelected={onNodeSelected}
          />
          <ChatAssistant artifactId={artifactId} />
        </ErrorBoundary>
      </div>
    </div>
  );
};
