import React from "react";
import AssetsCard, { AssetsCardProps } from "./AssetsCard";
import { EnsembleAssetData } from "../../config/interfaces";
import "./SelectableAssetCard.sass";
import { RemixIcon } from "../Widgets";

interface SelectableAssetCardProps extends AssetsCardProps {
  selected?: boolean;
  onSelect: (asset: EnsembleAssetData) => void;
}

export const SelectableAssetCard: React.FC<SelectableAssetCardProps> = ({
  asset,
  isReadOnly,
  selected,
  onSelect,
}) => {
  const handleSelect = () => {
    onSelect(asset as EnsembleAssetData);
  };

  return (
    <div
      className={`selectable-asset-card ${
        selected ? "selected-asset-card" : ""
      }`}
    >
      <AssetsCard asset={asset} isReadOnly={isReadOnly} />
      {selected ? (
        <div className={"selected-overlay"}>
          <RemixIcon name={"shield-check-line"} />
        </div>
      ) : (
        <div className="select-button-overlay">
          <button className="select-button" onClick={handleSelect}>
            Select
          </button>
        </div>
      )}
    </div>
  );
};
