import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import * as monaco from "monaco-editor";

import { useAppContext } from "../pages/AppPagesWrapper";
import { AuthContext } from "../App";

// APIs
import { useGetArtifactHistory, useUpdateTheme } from "../hooks/useAPIs";

// Interfaces
import {
  EnsembleArtifactHistoryData,
  EnsembleThemeData,
  IDropdownItem,
} from "../config/interfaces";
import { getDateTime } from "../utils";
import CheckinModalContent from "./CheckinModalContent";
import Dropdown from "./Dropdown";
import Modal from "./Modal";
import { EnsembleSchema } from "../hooks/useSchemas";
import { configureYamlSchema } from "../config/monacoYamlConfig";

interface IThemeEditorProps {
  theme: EnsembleThemeData;
  schema?: EnsembleSchema;
}

const editorConfig: monaco.editor.IStandaloneDiffEditorConstructionOptions = {
  automaticLayout: true,
  theme: "vs-dark",
  minimap: {
    enabled: false,
  },
  quickSuggestions: {
    other: true,
    strings: true,
  },
  scrollbar: {
    vertical: "hidden",
    horizontal: "hidden",
  },
  wordWrap: "off",
  fontSize: 14,
};

const ThemeEditor: React.FC<IThemeEditorProps> = ({ theme, schema }) => {
  const { currentUser } = useContext(AuthContext);
  const [selectedHistory, setSelectedHistory] =
    useState<EnsembleArtifactHistoryData | null>(null);
  const [themeContent, setThemeContent] = useState(theme.content);
  const { app, isAppReadOnly } = useAppContext();
  const artifactHistoryQuery = useGetArtifactHistory(app.id, theme.id);
  const updateTheme = useUpdateTheme(app.id, themeContent, currentUser);
  const editorRef = useRef<HTMLDivElement>(null);
  const diffEditorRef = useRef<HTMLDivElement>(null);
  const [diffEditorInstance, setDiffEditorInstance] =
    useState<monaco.editor.IStandaloneDiffEditor | null>(null);
  const [checkinModalTrigger, setCheckinModalTrigger] =
    useState<boolean>(false);

  useEffect(() => {
    configureYamlSchema(schema);
  }, [schema]);

  useEffect(() => {
    if (selectedHistory) {
      if (!diffEditorInstance && diffEditorRef.current) {
        const diffEditor = monaco.editor.createDiffEditor(
          diffEditorRef.current,
          editorConfig,
        );
        setDiffEditorInstance(diffEditor);

        const originalModel = monaco.editor.createModel(
          selectedHistory.content,
        );
        const modifiedModel = monaco.editor.createModel(
          themeContent ?? theme.content,
        );
        modifiedModel.onDidChangeContent(() => {
          setThemeContent(modifiedModel.getValue());
        });
        diffEditor.setModel({
          original: originalModel,
          modified: modifiedModel,
        });
      } else {
        const originalModel = monaco.editor.createModel(
          selectedHistory.content,
        );
        const modifiedModel = monaco.editor.createModel(
          themeContent ?? theme.content,
        );
        diffEditorInstance?.setModel({
          original: originalModel,
          modified: modifiedModel,
        });
      }
    } else {
      setDiffEditorInstance(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHistory]);

  useEffect(() => {
    if (!selectedHistory && editorRef.current) {
      const yamlInstance = monaco.editor.create(editorRef.current, {
        ...editorConfig,
        language: "yaml",
        value: themeContent ?? theme.content,
      });
      yamlInstance.onDidChangeModelContent(() => {
        setThemeContent(yamlInstance.getValue());
        if (!isAppReadOnly)
          yamlInstance.addCommand(
            monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS,
            () => updateTheme.mutate(),
          );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHistory]);

  useEffect(() => {
    if (updateTheme.isSuccess) {
      artifactHistoryQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTheme.status]);

  const saveContent = () => {
    updateTheme.mutate();
  };

  // Build history list dropdown
  const historyList = useMemo(() => {
    const history = artifactHistoryQuery.data?.pages.flatMap(
      (page) => page.history,
    );
    const historyPickList: IDropdownItem[] = [];
    if (app?.screens && history) {
      history?.map((item) => {
        historyPickList.push({
          id: item.id,
          text: (
            <div className="history-dropdown-item">
              {item.label ? (
                <>
                  <span className="item-label">{item.label}</span>
                  <br />
                </>
              ) : null}
              <span className={item.label ? "item-name" : "item-time"}>
                {getDateTime(item.updatedAt.toDate())}
              </span>
              <br />
              <span className="item-name">{item.updatedBy?.name ?? ""}</span>
            </div>
          ),
          clickHandler: () => {
            setSelectedHistory(item);
          },
        });
      });
    }
    return historyPickList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artifactHistoryQuery.data]);

  const handleCloseDiffEditor = () => {
    setSelectedHistory(null);
  };
  const handleRestoreVersion = () => {
    setThemeContent(selectedHistory?.content ?? themeContent);
    setSelectedHistory(null);
  };

  return (
    <div key={theme.id}>
      <div className="theme-editor">
        {isAppReadOnly && (
          <p className="readOnlyNote">
            This app is read-only, and your changes will be lost.
          </p>
        )}
        {selectedHistory && (
          <div className="title-bar">
            <span className="title">
              {`${
                selectedHistory.label ??
                getDateTime(selectedHistory.updatedAt.toDate())
              } ${
                selectedHistory.updatedBy?.name
                  ? `(${selectedHistory.updatedBy?.name})`
                  : ""
              }`}
            </span>
            <span className="title current-title">Current</span>
          </div>
        )}
        {selectedHistory ? (
          <div
            ref={diffEditorRef}
            style={{
              height: window.innerHeight - 150 + "px",
              width: window.innerWidth - 150 + "px",
            }}
          />
        ) : (
          <div
            key={selectedHistory}
            ref={editorRef}
            style={{
              height: window.innerHeight - 150 + "px",
              width: window.innerWidth - 150 + "px",
            }}
          />
        )}

        <Modal
          isModalDisplayed={checkinModalTrigger}
          onHide={() => setCheckinModalTrigger(false)}
          headerText="Save as a new version"
          modalContent={
            <CheckinModalContent
              app={app}
              screenId={theme.id}
              editorContent={themeContent}
              onCancel={() => setCheckinModalTrigger(false)}
            />
          }
        />

        {!isAppReadOnly && (
          <>
            {selectedHistory ? (
              <div className="selected-history-options">
                <span
                  className="close-diff-text"
                  onClick={handleCloseDiffEditor}
                >
                  Close diff editor
                </span>
                <span
                  className="close-diff-text"
                  onClick={handleRestoreVersion}
                >
                  Restore version
                </span>
              </div>
            ) : null}
            <Dropdown
              className="dropdown"
              wrapperClass="dropdown-wrapper"
              listClass="dropdown-list"
              itemClass="dropdown-item"
              activatorText={
                selectedHistory
                  ? selectedHistory.label ??
                    getDateTime(selectedHistory.updatedAt.toDate())
                  : "History"
              }
              items={historyList}
            />

            <div className="save-theme">
              <button
                disabled={updateTheme.isLoading}
                className="checkin-button"
                onClick={saveContent}
              >
                {updateTheme.isLoading ? "Saving..." : "Save"}
              </button>
              <Dropdown
                wrapperClass="save-dropdown-wrapper"
                className="save-dropdown"
                listClass="save-dropdown-list"
                itemClass="save-dropdown-item"
                activatorText=""
                items={[
                  {
                    id: "save",
                    text: "Save",
                    clickHandler: () => saveContent(),
                  },
                  {
                    id: "saveas",
                    text: "Save as",
                    clickHandler: () =>
                      setCheckinModalTrigger(!checkinModalTrigger),
                  },
                ]}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ThemeEditor;
