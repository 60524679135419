import React, { useContext, useEffect, useState } from "react";
import { Menu, MenuItem, MenuDivider, ClickEvent } from "@szhsin/react-menu";
import { useAppContext } from "../pages/AppPagesWrapper";
import { AuthContext } from "../App";
import { head } from "ramda";

import "@szhsin/react-menu/dist/index.css";

// APIs
import {
  useCreateScreens,
  useResetRootScreen,
  useUpdateScreen,
} from "../hooks/useAPIs";

// Components
import ScreenRename from "./ScreenRename";
import ScreenArchive from "./ScreenArchive";
import ScreenDraft from "./ScreenDraft";
import ScreenCategory from "./ScreenCategory";
import LabelMenu from "./ActionMenus/LabelMenu";
import { ReactComponent as IconMenu } from "../assets/icon_menu.svg";

// Interfaces
import {
  EnsembleCategoriesData,
  EnsembleScreenData,
} from "../config/interfaces";
import { toast } from "react-toastify";
import { toastOptions } from "../config/constants";
import { handleLabelingCard } from "./ActionMenus/functions";

interface ScreenActionsMenuProps {
  screen: EnsembleScreenData;
  categories: EnsembleCategoriesData[] | undefined;
  isReadOnly: boolean;
  isTemplate: boolean;
}

const ScreenActionsMenu: React.FC<ScreenActionsMenuProps> = ({
  screen,
  categories,
  isReadOnly,
  isTemplate,
}) => {
  const { app } = useAppContext();
  const { currentUser } = useContext(AuthContext);
  const [renameTrigger, setRenameTrigger] = useState<boolean>(false);
  const [renameCategory, setRenameCategory] = useState<boolean>(false);
  const [archiveTrigger, setArchiveTrigger] = useState<boolean>(false);
  const [draftTrigger, setDraftTrigger] = useState<boolean>(false);
  const updateScreenQuery = useUpdateScreen(currentUser, app.id, screen.id);
  const resetRootScreenQuery = useResetRootScreen(
    app.id,
    screen.id,
    screen.name,
  );
  const createScreenQuery = useCreateScreens(currentUser, app.id, [
    {
      name: screen?.name + " copy",
      description: screen?.description ?? "",
      content: screen.content,
      isRoot: false,
      isArchived: false,
      labelGroup: screen.labelGroup?.id,
    },
  ]);

  useEffect(() => {
    if (createScreenQuery.isLoading) return;
    if (createScreenQuery.isSuccess) {
      const newScreenId = head(
        createScreenQuery.data?.insert_screen?.returning || [],
      )?.id;

      if (newScreenId) {
        const newTab = window.open(
          `/app/${app.id}/screen/${newScreenId}`,
          "_blank",
        );
        if (newTab) newTab.focus();
      } else
        toast.error("Something went wrong. Please try again.", toastOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createScreenQuery.status]);

  const handleRename = (event: ClickEvent) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();
    setRenameTrigger(true);
  };

  const handleCategory = (event: ClickEvent) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();
    setRenameCategory(true);
  };

  const handleArchive = (event: ClickEvent) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();
    setArchiveTrigger(true);
  };

  const handleDraft = (event: ClickEvent) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();
    setDraftTrigger(true);
  };

  const handleRootUpdate = (event: ClickEvent) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();

    resetRootScreenQuery.mutate();
  };

  const createScreenCopy = (event: ClickEvent) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();

    createScreenQuery.mutate();
  };

  const HandleUpdateLabel = (labelId: string) =>
    handleLabelingCard(
      labelId,
      updateScreenQuery.mutate,
      screen.labelGroup?.id,
    );

  return (
    <>
      <Menu
        menuButton={
          <button
            className="actions-menu-button"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <IconMenu />
          </button>
        }
        align="end"
        position="auto"
        direction="top"
        offsetY={8}
        menuClassName="actions-menu"
      >
        {!isReadOnly && (
          <>
            <MenuItem onClick={handleRename}>Rename</MenuItem>

            {isTemplate && (
              <MenuItem onClick={handleCategory}>Add Category</MenuItem>
            )}

            {isTemplate && !screen.isDraft && (
              <MenuItem onClick={handleDraft}>Set as Draft</MenuItem>
            )}
            {isTemplate && screen.isDraft && (
              <MenuItem onClick={handleDraft}>Remove From Draft</MenuItem>
            )}

            {!screen.isRoot && (
              <MenuItem onClick={handleRootUpdate}>Set as Home Screen</MenuItem>
            )}
            <MenuItem onClick={createScreenCopy}>Make a Copy</MenuItem>
            <MenuDivider />
            <LabelMenu
              app={app}
              isLabeled={!!screen.labelGroup}
              onUpdateLabel={HandleUpdateLabel}
            />
            <MenuDivider />
            <MenuItem
              className="actions-menu__delete-item"
              onClick={handleArchive}
            >
              Archive
            </MenuItem>
          </>
        )}
      </Menu>

      <ScreenRename
        screen={screen}
        displayRenameModal={renameTrigger}
        setRenameTrigger={setRenameTrigger}
      />

      <ScreenCategory
        screen={screen}
        categories={categories}
        displayCategoryModal={renameCategory}
        setCategoryTrigger={setRenameCategory}
      />

      <ScreenDraft
        screen={screen}
        displayDraftModal={draftTrigger}
        setDraftTrigger={setDraftTrigger}
      />

      <ScreenArchive
        screen={screen}
        displayArchiveModal={archiveTrigger}
        setArchiveTrigger={setArchiveTrigger}
      />
    </>
  );
};

export default ScreenActionsMenu;
