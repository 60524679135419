import { type FunctionComponent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "./AppPagesWrapper";

// APIs
import { useModal } from "../hooks/useModal";

// Components
import DemoAppBadge from "../components/DemoAppBadge";
import WidgetCard from "../components/cards/WidgetCard";
import Modal from "../components/Modal";
import AppClone from "../components/AppClone";
import WidgetCreateModal from "../components/modals/WidgetCreateModal";
import SearchBar from "../components/SearchBar";
import AppBodyContainer from "../containers/AppBodyContainer";

const WidgetScreens: FunctionComponent = () => {
  const { app, isAppReadOnly, isDemoApp } = useAppContext();
  const [cloneTrigger, setCloneTrigger] = useState<boolean>(false);

  const { isModalDisplayed, toggleModal } = useModal();
  const onCancel = () => toggleModal();

  useEffect(() => {
    if (app.name) document.title = app.name;
  });

  const handleClone = () => {
    setCloneTrigger(true);
  };

  return (
    <div className="screen-content" key={app.id}>
      {/* breadcrumb */}
      <div className="breadcrumb">
        <Link to="/">Apps</Link>

        {app.isPublic && !isDemoApp && (
          <span>
            {" "}
            / <span className="public-app-badge">Public</span>
          </span>
        )}

        {isDemoApp && (
          <span>
            {" "}
            / <DemoAppBadge />
          </span>
        )}
      </div>

      <div className="page-header">
        <h1>Custom Widgets</h1>

        {/* Search Bar  */}
        {!!app.internalWidgets?.length && (
          <SearchBar searchType="widget" searchPlaceholder="Search..." />
        )}

        <div className="page-actions">
          {isAppReadOnly ? (
            <button className="button__primary" onClick={handleClone}>
              Clone this app
            </button>
          ) : (
            <button className="button__primary" onClick={toggleModal}>
              Create new widget
            </button>
          )}
        </div>
      </div>

      <AppBodyContainer
        appId={app.id}
        cardName="widget"
        CardComponent={WidgetCard}
        isReadOnly={isAppReadOnly}
        itemList={app.internalWidgets}
        dataNotFound="This app does not have custom widgets."
      />

      {/* App clone */}
      {app && (
        <AppClone
          app={app}
          displayCloneModal={cloneTrigger}
          setCloneTrigger={setCloneTrigger}
        />
      )}

      {app && !isAppReadOnly && (
        <Modal
          isModalDisplayed={isModalDisplayed}
          onHide={toggleModal}
          headerText="Create widget"
          modalContent={<WidgetCreateModal onCancel={onCancel} />}
        />
      )}
    </div>
  );
};

export default WidgetScreens;
