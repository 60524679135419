import Dropdown from "./Dropdown";
import { useState, useMemo } from "react";
import { IDropdownItem } from "../config/interfaces";
import { Button, ButtonVariant } from "./Button/Button";
import LabelManagerModal from "./modals/LabelManagerModal";
import { CardGroupOptions, CardSortOptions } from "../utils/constants";

interface LabelActionBarProps {
  className?: string;
  sortTrigger: {
    value: CardSortOptions;
    setter: (value: CardSortOptions) => void;
  };
  groupTrigger: {
    value: CardGroupOptions;
    setter: (value: CardGroupOptions) => void;
  };
  setIsExpanded: (value: boolean) => void;
  setIsCollapsed: (value: boolean) => void;
}

const LabelActionBar = ({
  className = "",
  sortTrigger,
  groupTrigger,
  setIsExpanded,
  setIsCollapsed,
}: LabelActionBarProps) => {
  const [displayLabelsModal, setDisplayLabelsModal] = useState(false);

  const createDropdownOptions = <S,>(
    labels: { [key: string]: string },
    handler: (value: S) => void,
  ): IDropdownItem[] =>
    Object.values(labels).map((value) => ({
      id: value,
      text: value,
      clickHandler: () => handler(value as S),
    }));

  const sortOptions = useMemo(
    () => createDropdownOptions(CardSortOptions, sortTrigger.setter),
    [sortTrigger.setter],
  );

  const groupOptions = useMemo(
    () => createDropdownOptions(CardGroupOptions, groupTrigger.setter),
    [groupTrigger.setter],
  );

  const openLabelManager = () => setDisplayLabelsModal(true);
  const handleExpandCollapse = (value: boolean) => {
    setIsExpanded(value);
    setIsCollapsed(!value);
  };

  return (
    <div className={`label-bar ${className}`}>
      <DropdownSection
        heading="Sort By:"
        activatorText={sortTrigger.value}
        items={sortOptions}
      />
      <DropdownSection
        heading="Group By:"
        activatorText={groupTrigger.value}
        items={groupOptions}
      />
      <Button
        className="label-bar__button"
        variant={ButtonVariant.LINK}
        onClick={openLabelManager}
      >
        Manage Labels
      </Button>
      {groupTrigger.value !== CardGroupOptions.None && (
        <>
          <Button
            className="label-bar__button"
            variant={ButtonVariant.LINK}
            onClick={() => handleExpandCollapse(false)}
          >
            Collapse
          </Button>
          <Button
            className="label-bar__button"
            variant={ButtonVariant.LINK}
            onClick={() => handleExpandCollapse(true)}
          >
            Expand
          </Button>
        </>
      )}
      <LabelManagerModal
        isModalOpen={displayLabelsModal}
        setModalOpen={setDisplayLabelsModal}
      />
    </div>
  );
};

interface DropdownSectionProps {
  heading: string;
  activatorText: string;
  items: IDropdownItem[];
}

const DropdownSection = ({
  heading,
  activatorText,
  items,
}: DropdownSectionProps) => (
  <div className="label-bar__drop-down-container">
    <span className="label-bar__heading">{heading}</span>
    <Dropdown
      className="label-bar__drop-down"
      itemClass="label-bar__drop-items"
      activatorText={activatorText}
      items={items}
    />
  </div>
);

export default LabelActionBar;
