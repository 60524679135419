import React, { ReactNode, useEffect, useState } from "react";
import { Tree } from "antd";
import { NodeRef, TreeNode } from "./TreeNode";
import { getTreeIcon } from "../../utils/treeUtils";
import { isSeq, Pair, Scalar, visit, YAMLMap } from "yaml";
import { NodeAction, useYamlDoc } from "../../hooks/useYamlDoc";
import { TreeItemPayload, TreeItemWidgetPayload } from "./TreeNode";
import { WidgetItemPicker } from "../../components/VisualEditor/ItemPicker";
import { getNodeName } from "../../utils/docUtils";
import { TreeProps } from "antd";

interface TreeViewProps {
  treeData: TreeNode[] | null | undefined;
  selectedKey: string | null;
  onSelect: (key: string | null, ref: NodeRef | null) => void;
  onWidgetSelectedAction?: (
    parentNode: YAMLMap,
    widgetKey: string,
    action: NodeAction,
    payload?: TreeItemPayload,
  ) => void;
  expandFirstNode?: boolean;
  autoSelect?: boolean; // select first node if none is selected
  searchKey?: string;
  draggable?: boolean;
}

export const TreeView: React.FC<TreeViewProps> = ({
  treeData,
  selectedKey,
  onSelect,
  onWidgetSelectedAction,
  expandFirstNode,
  autoSelect = false,
  searchKey,
  draggable,
}) => {
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [internalTreeData, setInternalTreeData] = useState<TreeNode[]>(
    treeData ?? [],
  );

  const { moveNode } = useYamlDoc();

  useEffect(() => {
    setInternalTreeData(treeData ?? []);
  }, [treeData]);

  const onTreeSelect = (
    selectedKeys: React.Key[],
    info: { node: TreeNode },
  ) => {
    if (selectedKeys.length > 0) {
      onSelect(selectedKeys[0].toString(), info.node.ref);
    } else {
      onSelect(null, null);
    }
  };

  const onDragEnter: TreeProps["onDragEnter"] = (info) => {
    setExpandedKeys(info.expandedKeys);
  };

  const onDrop: TreeProps<TreeNode>["onDrop"] = (info) => {
    const { node, dragNode, dropPosition } = info;

    if (dragNode.ref?.category === "customWidget") {
      return;
    }

    const dropPos = node.pos.split("-");
    const dropPosIndex = Number(dropPos[dropPos.length - 1]);
    const adjustedDropPosition = dropPosition - dropPosIndex;

    let parentNode = dragNode.ref.parent as YAMLMap;
    if (!isSeq(parentNode)) {
      visit(parentNode, {
        Seq(_, seqNode) {
          parentNode = seqNode as YAMLMap;
          return visit.BREAK;
        },
      });
    }

    const newNode = new Pair(
      (dragNode.ref.node as Pair).key,
      (dragNode.ref.node as Pair).value,
    );

    moveNode(
      newNode,
      parentNode,
      dragNode.ref.node as Pair,
      node.ref.node as Pair,
      adjustedDropPosition,
    );
  };

  useEffect(() => {
    if (autoSelect && !selectedKey && treeData && treeData.length > 0) {
      onSelect(String(treeData[0].key), treeData[0].ref);
    }
  }, [autoSelect, onSelect, selectedKey, treeData]);

  useEffect(() => {
    if (!searchKey) {
      // Expand first node if applicable
      if (expandFirstNode && treeData && treeData.length > 0) {
        setExpandedKeys([treeData[0].key]);
      }
    } else {
      // Expand nodes based on searchKey
      const expandedKeys: React.Key[] = [];

      const expandNodes = (nodes: TreeNode[], parentKeys: React.Key[]) => {
        for (const node of nodes) {
          if (
            (node.title &&
              typeof node.title === "string" &&
              node.title.toLowerCase().includes(searchKey.toLowerCase())) ||
            // Check if the node value contains the search key
            getNodeValue(node)
              ?.toString()
              .toLowerCase()
              .includes(searchKey.toLowerCase())
          ) {
            // Expand parent nodes recursively
            for (const key of parentKeys) {
              if (!expandedKeys.includes(key)) {
                expandedKeys.push(key);
              }
            }
          }

          if (node.children) {
            // Expand child nodes recursively
            expandNodes(node.children as TreeNode[], [...parentKeys, node.key]);
          }
        }
      };

      expandNodes(treeData ?? [], []);

      setExpandedKeys(expandedKeys);
    }
  }, [expandFirstNode, searchKey, treeData]);

  const treeItemRenderer = (treeNode: TreeNode) => {
    const action = treeNode.ref.action;
    if (action === NodeAction.SetWidgetBody) {
      return (
        <WidgetItemPicker
          onItemSelect={(widgetKey) => {
            onWidgetSelectedAction
              ? onWidgetSelectedAction(treeNode.ref.parent!, widgetKey, action)
              : null;
          }}
          trigger={<div className={"set-widget-body"}>Set Body Widget</div>}
        />
      );
    } else if (action === NodeAction.AddChild) {
      return (
        <WidgetItemPicker
          trigger={
            <div className={"tree-node-add"}>
              {getTreeIcon("iconadd")}
              <span>add child widget</span>
            </div>
          }
          onItemSelect={(widgetKey) =>
            onWidgetSelectedAction
              ? onWidgetSelectedAction(treeNode.ref.parent!, widgetKey, action)
              : null
          }
          parentNode={treeNode.ref.parent!}
        />
      );
    } else if (action === NodeAction.SetTreeItemWidget) {
      if (treeNode.payload instanceof TreeItemWidgetPayload) {
        return (
          <WidgetItemPicker
            trigger={
              <div className={"tree-node-add"}>
                {getTreeIcon("iconadd")}
                <span>{treeNode.payload.label ?? "set widget"}</span>
              </div>
            }
            onItemSelect={(widgetKey) =>
              onWidgetSelectedAction
                ? onWidgetSelectedAction(
                    treeNode.ref.parent!,
                    widgetKey,
                    action,
                    treeNode.payload,
                  )
                : null
            }
          />
        );
      }
    }
    if (searchKey) {
      if (!treeNode.children) {
        const nodeTitle = getNodeName(treeNode.ref.node);
        const highlightedTitle = highlightSearchTerm(
          nodeTitle as string,
          searchKey,
        );
        const highlightedValue = highlightSearchTerm(
          getNodeValue(treeNode),
          searchKey,
        );
        return (
          <div>
            {highlightedTitle ? (
              <>{highlightedTitle}</>
            ) : (
              <>{treeNode.title?.toString()}</>
            )}
            <br />
            {highlightedValue && <>{highlightedValue}</>}
          </div>
        ) as ReactNode;
      }
    }
    return treeNode.title as ReactNode;
  };

  return internalTreeData ? (
    <Tree
      titleRender={treeItemRenderer}
      treeData={internalTreeData}
      onSelect={onTreeSelect}
      selectedKeys={selectedKey ? [selectedKey] : undefined}
      showIcon={true}
      blockNode={true}
      expandedKeys={expandedKeys}
      onExpand={setExpandedKeys}
      draggable={draggable}
      onDragEnter={onDragEnter}
      onDrop={onDrop}
    />
  ) : (
    <div></div>
  );
};

const getNodeValue = (node: TreeNode): string => {
  const nodeValue = node.ref.node.value as YAMLMap;
  const items = nodeValue.items as Pair[];
  if (items && items.length > 0) {
    return (
      items
        .filter((item) => {
          const key = item.key as Scalar;
          return key.value !== "styles" && key.value !== "onTap";
        })
        .map((item) => (item.value as Scalar).value)
        .join(", ") || (node.title as string)
    );
  } else {
    return node.title as string;
  }
};

const highlightSearchTerm = (text: string, searchKey: string) => {
  if (!searchKey) return text;

  const searchText = text.toString();

  const escapeRegExp = (string: string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  const escapedSearchKey = escapeRegExp(searchKey);

  const highlight = (str: string) => {
    return str
      .split(new RegExp(`(${escapedSearchKey})`, "gi"))
      .map((part, index) =>
        part.toLowerCase() === searchKey.toLowerCase() ? (
          <mark key={index}>{part}</mark>
        ) : (
          part
        ),
      );
  };

  const highlightExpressions = (str: string) => {
    const regex = /\$\{(.*?)\}/g;
    let match;
    let lastIndex = 0;
    const elements = [];

    while ((match = regex.exec(str)) !== null) {
      const beforeExpression = str.substring(lastIndex, match.index);
      const expression = match[0];

      elements.push(highlight(beforeExpression));

      const highlightedExpression = (
        <span style={{ color: "yellow" }} key={match.index}>
          {highlight(expression)}
        </span>
      );

      elements.push(highlightedExpression);
      lastIndex = regex.lastIndex;
    }

    elements.push(highlight(str.substring(lastIndex)));
    return elements.flat();
  };

  return (
    <span
      style={{
        width: "240px",
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
      title={searchText}
    >
      {highlightExpressions(searchText)}
    </span>
  );
};
