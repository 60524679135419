import React from "react";
import { Icon } from "@iconify/react";

// widget to link to our documentation at https://docs.ensembleui.com
// The doc server has another redirect from /doc/:topicName to the actual doc page
interface ExternalDocProps {
  topicName: string;
  label?: string;
}
export const ExternalDoc: React.FC<ExternalDocProps> = ({
  topicName,
  label,
}) => {
  return (
    <a
      style={{ display: "flex", alignItems: "center", gap: "3px" }}
      href={`https://docs.ensembleui.com/doc/${topicName}`}
      target="_blank"
      rel="noopener noreferrer"
      // a click on Tooltip's URL shouldn't trigger closing the ActionMenu
      onMouseDown={(e) => e.stopPropagation()}
    >
      <Icon
        icon="material-symbols-light:book-5-outline"
        style={{ fontSize: "18px" }}
      />
      {label ?? "How to use this?"}
    </a>
  );
};

// some hardcoded topics that we use but not in the schema
export enum DocTopic {
  dataBinding = "data-binding",
  customWidgets = "custom-widgets",
}
