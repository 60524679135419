import React, {
  useState,
  useMemo,
  useRef,
  useCallback,
  useEffect,
} from "react";
import { EnsembleScreenData, EnsembleWidgetData } from "../config/interfaces";
import "../styles/search-dialog.scss";
import { Link } from "react-router-dom";
import { useAppContext } from "../pages/AppPagesWrapper";

interface SearchDialogProps {
  search: string;
  searchType: string;
}

interface MatchedResults {
  line: string;
  previousLine?: string;
  nextLine?: string;
}

interface SearchResult {
  matchedLines: MatchedResults;
  lineNumber: number;
  screenName: string;
  screenId: string;
}

type TabType = "screen" | "widget" | "script";

const SearchDialog: React.FC<SearchDialogProps> = ({ search, searchType }) => {
  const [currentTab, setCurrentTab] = useState<TabType>(searchType as TabType);
  const [currentPage, setCurrentPage] = useState(1);
  const containerRef = useRef<HTMLDivElement>(null);

  const { app } = useAppContext();
  const resultsPerPage = 50;

  const searchResults = useMemo(() => {
    if (!search || !app) return [];

    const getResultsByType: Record<TabType, () => SearchResult[]> = {
      screen: () => getSearchResults(app.screens!, search),
      widget: () => getSearchResults(app.internalWidgets!, search),
      script: () => getSearchResults(app.internalScripts!, search),
    };

    setCurrentPage(1);
    return getResultsByType[currentTab]();
  }, [app, search, currentTab]);

  const groupedResults = useMemo(() => {
    return groupResultsByScreen(searchResults, currentPage, resultsPerPage);
  }, [searchResults, currentPage, resultsPerPage]);

  const handleScroll = useCallback(() => {
    if (
      containerRef.current &&
      containerRef.current.scrollTop + containerRef.current.clientHeight >=
        containerRef.current.scrollHeight * 0.9
    ) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, []);

  useEffect(() => {
    const currentRef = containerRef.current;
    currentRef?.addEventListener("scroll", handleScroll);
    return () => currentRef?.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <div className="search-dialog">
      <TabSelector currentTab={currentTab} setCurrentTab={setCurrentTab} />
      <div
        className="search-results"
        onScroll={handleScroll}
        ref={containerRef}
      >
        {SearchedResults(
          searchResults,
          groupedResults,
          app?.id,
          currentTab,
          search,
          currentPage,
          resultsPerPage,
          setCurrentPage,
        )}
      </div>
    </div>
  );
};

const TabSelector: React.FC<{
  currentTab: TabType;
  setCurrentTab: (tab: TabType) => void;
}> = ({ currentTab, setCurrentTab }) => {
  const tabs: TabType[] = ["screen", "widget", "script"];
  return (
    <div className="modal_tabs">
      {tabs.map((tab) => (
        <button
          key={tab}
          className={currentTab === tab ? "tab_active" : "tab__button"}
          onClick={() => setCurrentTab(tab)}
        >
          {tab.charAt(0).toUpperCase() + tab.slice(1)}s
        </button>
      ))}
    </div>
  );
};

const SearchedResults = (
  results: SearchResult[],
  groupedResults: { [screenId: string]: SearchResult[] },
  appId: string | undefined,
  currentTab: TabType,
  search: string,
  currentPage: number,
  resultsPerPage: number,
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>,
) => {
  if (results.length === 0) return <p>No matching results found.</p>;

  return (
    <>
      {Object.entries(groupedResults).map(([screenId, matchedLines]) => (
        <div key={screenId}>
          <h3>{matchedLines[0].screenName}</h3>
          {matchedLines.map((line, index) => (
            <Link
              to={`/app/${appId}/${currentTab}/${screenId}?line=${
                line.lineNumber + 1
              }&searchTerm=${search}`}
              key={index}
              className="matching-item"
            >
              <SearchResultBlock
                matchedLines={line.matchedLines}
                search={search}
              />
            </Link>
          ))}
        </div>
      ))}
      {currentPage < Math.ceil(results.length / resultsPerPage) && (
        <button
          className="button__load-more"
          onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
        >
          Load More
        </button>
      )}
    </>
  );
};

const searchThroughScreensContent = (
  screen: EnsembleScreenData | EnsembleWidgetData,
  searchTerm: string,
  alreadyMatchedLinesSet: Set<number>,
): SearchResult[] => {
  const content = screen.content ?? "";
  const lines = content.split("\n");
  const matchingLines: SearchResult[] = [];

  lines.forEach((line, lineNumber) => {
    if (
      !alreadyMatchedLinesSet.has(lineNumber) &&
      !alreadyMatchedLinesSet.has(lineNumber - 1) &&
      !alreadyMatchedLinesSet.has(lineNumber + 1) &&
      line.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      const previousLine = lineNumber > 0 ? lines[lineNumber - 1] : undefined;
      const nextLine =
        lineNumber < lines.length - 1 ? lines[lineNumber + 1] : undefined;

      matchingLines.push({
        matchedLines: { line, previousLine, nextLine },
        lineNumber,
        screenId: screen.id,
        screenName: screen.name,
      });

      alreadyMatchedLinesSet.add(lineNumber);
      if (previousLine) alreadyMatchedLinesSet.add(lineNumber - 1);
      if (nextLine) alreadyMatchedLinesSet.add(lineNumber + 1);
    }
  });

  return matchingLines;
};

const getSearchResults = (
  screens: (EnsembleScreenData | EnsembleWidgetData)[],
  search: string,
): SearchResult[] => {
  return screens.flatMap((screen) => {
    const alreadyMatchedLinesSet: Set<number> = new Set();
    return searchThroughScreensContent(screen, search, alreadyMatchedLinesSet);
  });
};

const groupResultsByScreen = (
  results: SearchResult[],
  page: number,
  perPage: number,
): { [screenId: string]: SearchResult[] } => {
  const groupedResults: { [screenId: string]: SearchResult[] } = {};
  results.slice(0, page * perPage).forEach((result) => {
    if (!groupedResults[result.screenId]) {
      groupedResults[result.screenId] = [];
    }
    groupedResults[result.screenId].push(result);
  });
  return groupedResults;
};

const SearchResultBlock: React.FC<{
  matchedLines: MatchedResults;
  search: string;
}> = ({ matchedLines, search }) => {
  const escapeRegExp = (string: string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  const highlightText = (text: string) => {
    const escapedSearch = escapeRegExp(search);
    return text
      .split(new RegExp(`(${escapedSearch})`, "gi"))
      .map((part, index) =>
        index % 2 === 0 ? part : <mark key={index}>{part}</mark>,
      );
  };

  return (
    <>
      {matchedLines.previousLine && (
        <span>
          {highlightText(matchedLines.previousLine)}
          <br />
        </span>
      )}
      <span>{highlightText(matchedLines.line)}</span>
      {matchedLines.nextLine && (
        <>
          <br />
          <span>{highlightText(matchedLines.nextLine)}</span>
        </>
      )}
    </>
  );
};

export default SearchDialog;
