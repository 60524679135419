import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { db, cloudFunctions } from "../config/firebase";
import {
  collection,
  getDocs,
  DocumentReference,
  QueryDocumentSnapshot,
  SnapshotOptions,
  query,
  doc,
  where,
  getDoc,
  setDoc,
  addDoc,
  writeBatch,
  serverTimestamp,
  updateDoc,
  limit,
  orderBy,
  Timestamp,
  onSnapshot,
  startAfter,
  QueryConstraint,
} from "firebase/firestore";
import {
  EnsembleScreenData,
  EnsembleNewScreenData,
  EnsembleWidgetData,
  EnsembleNewThemeData,
  EnsembleArtifactHistoryData,
  IEnsembleUserData,
  EnsembleCategoriesData,
  EnsembleTranslationData,
  labelGroupData,
  EnsembleNewWidgetData,
} from "../config/interfaces";
import config from "../config/config";
import { addEnvironmentVariable, cloneApp, getArtifacts } from "./useAPIs";
import { toast } from "react-toastify";
import { ArtifactType } from "../pages/EditorPage";
import { getLanguageCode } from "../utils/Util";
import { last } from "lodash-es";
import { CollectionName } from "../utils/constants";
import { type HttpsCallableResult } from "firebase/functions";

type ArtifactCollectionType = "artifacts" | "internal_artifacts";

const screenDataConverter = {
  toFirestore: (screenData: EnsembleScreenData) => {
    return {
      id: screenData.id,
      name: screenData.name,
      isArchived: screenData.isArchived,
      isRoot: screenData.isRoot,
      description: screenData.description,
      content: screenData.content,
    };
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): EnsembleScreenData => {
    const data = snapshot.data(options)!;
    return {
      id: snapshot.id,
      name: data.name,
      isRoot: data.isRoot,
      content: data.content,
      isArchived: data.isArchived,
      description: data.description,
    };
  },
};

const translationDataConverter = {
  toFirestore: (translationData: EnsembleTranslationData) => {
    return {
      id: translationData.id,
      name: translationData.name ?? getLanguageCode(translationData),
      isArchived: translationData.isArchived,
      isRoot: translationData.isRoot,
      type: translationData.type,
      content: translationData.content,
      defaultLocale: translationData.defaultLocale,
    };
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): EnsembleTranslationData => {
    const data = snapshot.data(options)!;
    return {
      id: snapshot.id,
      name: data.name,
      type: data.type,
      isRoot: data.isRoot,
      content: data.content,
      isArchived: data.isArchived,
      defaultLocale: data.defaultLocale,
    };
  },
};

// update artifact metadata only (no content changes so no history)
export const useUpdateArtifactMetadata = (
  appId: string,
  artifactId: string,
) => {
  return useMutation(async (changes: object): Promise<boolean> => {
    try {
      const artifactRef = doc(
        db,
        "apps",
        appId,
        CollectionName.artifacts,
        artifactId,
      );
      await updateDoc(artifactRef, changes);
      return true;
    } catch (error) {
      console.error("Error updating artifact metadata:", error);
      return false;
    }
  });
};

// update artifact content and metadata. If only metadata changes, use updateArtifactMetadata().
export const updateArtifact = async (
  currentUser: IEnsembleUserData,
  appId: string,
  artifactId: string,
  content: string,
  changes?: object,
): Promise<boolean> => {
  try {
    const artifactRef = doc(
      db,
      "apps",
      appId,
      CollectionName.artifacts,
      artifactId,
    );

    // make a history entry
    await addHistoryOfArtifact(
      currentUser,
      appId,
      artifactId,
      CollectionName.artifacts,
      {
        ...changes,
        content: content,
      },
    );

    // update the artifact
    await updateDoc(artifactRef, {
      ...changes,
      content: content || {},
      updatedAt: serverTimestamp(),
      updatedBy: getUserRef(currentUser.id),
    });
    return true;
  } catch (error) {
    console.error("Error updating artifact metadata:", error);
    return false;
  }
};

export function useGetUserDetails(user_id: string) {
  return useQuery(["user", user_id], async () => {
    return {
      user: await getUserDetails(user_id),
    };
  });
}

export async function getUserDetails(user_id: string) {
  const ref = doc(db, "users", user_id);
  const user = await getDoc(ref);

  const data = user.data();
  return data;
}

// export function useCreateUser(auth_id: string, email: string, name: string) {
//   return useMutation(async () => {
//     const timestamp = serverTimestamp();
//     const newUserRef = collection(db, "users");
//     const addedUser = await addDoc(newUserRef, {
//       authId: auth_id,
//       email: email,
//       name: name,
//       questionnaireAttempted: false,
//       createdAt: timestamp,
//       updatedAt: timestamp,
//     });
//
//     return {
//       insert_user: {
//         returning: [
//           {
//             id: addedUser.id,
//           },
//         ],
//       },
//     };
//   });
// }

export function useUpdateUser(
  user_id: string,
  auth_id: string,
  email: string,
  name: string,
  photoURL: string,
) {
  return useMutation(async () => {
    const existingUserRef = doc(db, "users", user_id);
    await updateDoc(existingUserRef, {
      authId: auth_id,
      email: email,
      name: name,
      avatar: photoURL,
    });
  });
}

export function useGetAuthedUserDetails(email: string | undefined) {
  return useQuery(
    "userId",
    async () => {
      const q = query(collection(db, "users"), where("email", "==", email));

      const result = await getDocs(q).then((snapshot) => {
        const user = snapshot.docs[0];
        return {
          data: { user: user },
        };
      });
      return result.data;
    },
    {
      enabled: !!email,
    },
  );
}

export function useGetCategories() {
  return useQuery(["screenCategories"], async () => {
    return {
      category: await getCategories(),
    };
  });
}

async function getCategories() {
  const artifacts = collection(db, "categories");
  const screens = await getDocs(query(artifacts));
  const data = [];
  for (const history of screens.docs) {
    data.push(
      new EnsembleCategoriesData(
        history.id,
        history.data().name,
        history.data().type,
        history.data().isActive,
      ),
    );
  }

  return data;
}

export function useGetTemplateScreens() {
  return useQuery(["templateScreen"], async () => {
    return {
      screens: await getTemplateScreens(),
    };
  });
}

async function getTemplateScreens() {
  const artifacts = collection(
    db,
    "apps",
    "e5y6nAgJ3ReN94rm2arJ",
    CollectionName.artifacts,
  );
  const screens = await getDocs(query(artifacts));
  const data: EnsembleScreenData[] = [];
  for (const doc of screens.docs) {
    const screenData = doc.data();
    if (
      !screenData.isDraft &&
      !screenData.isArchived &&
      screenData.type === "screen"
    ) {
      data.push({
        id: doc.id,
        name: screenData.name,
        isRoot: screenData.isRoot,
        content: screenData.content,
        isDraft: screenData.isDraft,
        category: screenData.category,
        isArchived: screenData.isArchived,
        description: screenData.description,
      });
    }
  }

  return data;
}

export function useGetScreenDetails(
  app_id: string,
  screen_id: string | undefined,
  artifactCollection?: ArtifactCollectionType,
) {
  return useQuery(["screen", screen_id], async () => {
    return {
      screen: await getScreenDetails(
        app_id,
        screen_id!,
        artifactCollection ?? CollectionName.artifacts,
      ),
    };
  });
}

async function getScreenDetails(
  app_id: string,
  screen_id: string,
  artifactCollection: ArtifactCollectionType,
) {
  const ref = doc(
    db,
    "apps",
    app_id,
    artifactCollection,
    screen_id,
  ).withConverter(screenDataConverter);
  const screen = await getDoc(ref);

  const data = screen.data();
  return data;
}

export async function getScreenDetailsWithSnapShot(
  app_id: string,
  screen_id: string | undefined,
  artifactCollection: ArtifactCollectionType,
  callback: (data: EnsembleScreenData) => void,
) {
  if (screen_id)
    onSnapshot(
      doc(db, "apps", app_id, artifactCollection, screen_id).withConverter(
        screenDataConverter,
      ),
      (snapshot) => {
        callback(
          snapshot.data() || {
            id: "",
            name: "",
            isArchived: true,
            isRoot: false,
          },
        );
      },
    );
}

const ARTIFACT_HISTORY_CHUNK_SIZE = 10;

export function useGetArtifactHistory(
  app_id: string,
  artifact_id: string | undefined,
  artifactCollection?: ArtifactCollectionType,
) {
  return useInfiniteQuery(
    ["history", artifact_id],
    async ({ pageParam = undefined }) => {
      return {
        history: await getArtifactHistory(
          app_id,
          artifact_id!,
          artifactCollection ?? CollectionName.artifacts,
          pageParam,
        ),
      };
    },
    {
      getNextPageParam: (lastPage) =>
        lastPage.history.length < ARTIFACT_HISTORY_CHUNK_SIZE
          ? undefined
          : last(lastPage.history)?.id,
    },
  );
}

async function getArtifactHistory(
  app_id: string,
  artifact_id: string,
  artifactCollection: ArtifactCollectionType,
  lastArtifactId?: string,
) {
  const constraints: QueryConstraint[] = [
    orderBy("updatedAt", "desc"),
    limit(ARTIFACT_HISTORY_CHUNK_SIZE),
  ];
  if (lastArtifactId) {
    constraints.push(
      startAfter(
        await getDoc(
          doc(
            db,
            "apps",
            app_id,
            artifactCollection,
            artifact_id,
            "history",
            lastArtifactId,
          ),
        ),
      ),
    );
  }
  const ref = query(
    collection(db, "apps", app_id, artifactCollection, artifact_id, "history"),
    ...constraints,
  );
  const snapshot = await getDocs(ref);
  const data = [];
  for (const history of snapshot.docs) {
    const docData = history.data();
    data.push(
      new EnsembleArtifactHistoryData(
        history.id,
        docData.content,
        docData.label,
        docData.comment,
        docData.updatedAt,
        docData.updatedBy,
      ),
    );
  }
  return data;
}

function addHistoryOfArtifact(
  currentUser: IEnsembleUserData | null,
  app_id: string,
  artifact_id: string,
  collectionName: CollectionName,
  changes: object,
) {
  const timestamp = serverTimestamp();
  const historyRef = collection(
    db,
    "apps",
    app_id,
    collectionName,
    artifact_id!,
    "history",
  );

  return addDoc(historyRef, {
    ...changes,
    updatedAt: timestamp,
    updatedBy: {
      id: currentUser?.id,
      name: currentUser?.name,
      email: currentUser?.email,
    },
  });
}

export function useUpdateScreen(
  currentUser: IEnsembleUserData | null,
  app_id: string,
  screen_id: string,
) {
  const queryClient = useQueryClient();
  return useMutation<unknown, unknown, Partial<EnsembleScreenData>>(
    async (changes) => {
      const timestamp = serverTimestamp();
      const screenRef = doc(
        db,
        "apps",
        app_id,
        CollectionName.artifacts,
        screen_id!,
      );
      await addHistoryOfArtifact(
        currentUser,
        app_id,
        screen_id,
        CollectionName.artifacts,
        changes,
      );
      await updateDoc(screenRef, {
        ...changes,
        updatedAt: timestamp,
        updatedBy: getUserRef(currentUser!.id),
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["screen", screen_id]);
        queryClient.invalidateQueries(["app", app_id]);
        queryClient.invalidateQueries(["history", screen_id]);
      },
      onError: (error) => {
        console.log(error);
      },
    },
  );
}

export function useCloneApp(
  currentUser: IEnsembleUserData | null,
  app_id: string,
  cloneCollaborators: boolean,
) {
  const queryClient = useQueryClient();
  return useMutation(
    async () => {
      const cloneAppResponse = await cloneApp(app_id, cloneCollaborators);
      if (cloneAppResponse && cloneAppResponse.data?.appId) {
        return {
          clone_app: {
            app_id: cloneAppResponse.data.appId,
          },
        };
      }
      return {};
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user", currentUser!.id]);
      },
    },
  );
}

export function useCreateApp(
  currentUser: IEnsembleUserData | null,
  app_name: string,
  screens: EnsembleNewScreenData[],
  theme?: EnsembleNewThemeData,
  widgets?: EnsembleWidgetData[],
  scripts?: EnsembleWidgetData[],
  translations?: EnsembleTranslationData[],
  environmentVariables?: Map<string, unknown>,
  app_description?: string,
  isReact: boolean = false,
) {
  const queryClient = useQueryClient();
  return useMutation(
    async () => {
      const timestamp = serverTimestamp();

      // first create the App and mark the user as the owner
      const userKey = "users_" + currentUser!.id;
      const ensembleCollaboratorKey = "users_" + config.collaboratorId;
      const userRef = doc(db, "users", currentUser!.id);
      const newAppRef = await addDoc(collection(db, "apps"), {
        name: app_name,
        description: app_description || "",
        isPublic: false,
        isArchived: false,
        createdAt: timestamp,
        isReact,
        collaborators: {
          [userKey]: "owner",
          [ensembleCollaboratorKey]: "write",
        },
        createdBy: userRef,
        createdByName: currentUser!.name,
        createdByEmail: currentUser!.email,
      });

      // then batch the creation of the artifacts together. This will
      // pass the security rule since the user is the owner of the app.

      const batch = writeBatch(db);

      let rootScreenRef: DocumentReference | undefined;
      // add the screens
      screens.forEach((screen) => {
        const newScreenRef = doc(
          collection(newAppRef, CollectionName.artifacts),
        );
        const historyRef = doc(collection(newScreenRef, "history"));
        batch.set(newScreenRef, {
          type: ArtifactType.screen,
          name: screen.name,
          content: screen.content || "",
          isRoot: screen.isRoot,
          isArchived: screen.isArchived,
          createdAt: timestamp,
          updatedAt: timestamp,
          createdBy: userRef,
          updatedBy: userRef,
        });
        batch.set(historyRef, {
          content: screen.content || "",
          updatedAt: timestamp,
          updatedBy: {
            id: currentUser?.id,
            name: currentUser?.name,
            email: currentUser?.email,
          },
        });
        if (screen.isRoot) {
          rootScreenRef = newScreenRef;
        }
      });
      // add the theme
      if (theme != null) {
        const newThemeRef = doc(
          collection(newAppRef, CollectionName.artifacts),
        );
        batch.set(newThemeRef, {
          type: ArtifactType.theme,
          content: theme.content,
          isRoot: true,
          isArchived: theme.isArchived,
          createdAt: timestamp,
          updatedAt: timestamp,
          createdBy: userRef,
          updatedBy: userRef,
        });
      }

      // add the custom widgets
      if (widgets) {
        widgets.forEach((widget) => {
          const newWidgetRef = doc(
            collection(newAppRef, CollectionName.internal_artifacts),
          );
          const historyRef = doc(collection(newWidgetRef, "history"));
          batch.set(newWidgetRef, {
            type: ArtifactType.internal_widget,
            name: widget.name,
            content: widget.content || "",
            isArchived: widget.isArchived,
            createdAt: timestamp,
            updatedAt: timestamp,
            createdBy: userRef,
            updatedBy: userRef,
          });
          batch.set(historyRef, {
            content: widget.content || "",
            updatedAt: timestamp,
            updatedBy: {
              id: currentUser?.id,
              name: currentUser?.name,
              email: currentUser?.email,
            },
          });
        });
      }

      // add the custom scripts
      if (scripts) {
        scripts.forEach((script) => {
          const newScriptRef = doc(
            collection(newAppRef, CollectionName.internal_artifacts),
          );
          const historyRef = doc(collection(newScriptRef, "history"));
          batch.set(newScriptRef, {
            type: ArtifactType.internal_script,
            name: script.name,
            content: script.content || "",
            isArchived: script.isArchived,
            createdAt: timestamp,
            updatedAt: timestamp,
            createdBy: userRef,
            updatedBy: userRef,
          });
          batch.set(historyRef, {
            content: script.content || "",
            updatedAt: timestamp,
            updatedBy: {
              id: currentUser?.id,
              name: currentUser?.name,
              email: currentUser?.email,
            },
          });
        });
      }

      // add the translations
      if (translations) {
        translations.forEach((translation) => {
          const newTranslationRef = doc(
            newAppRef,
            CollectionName.artifacts,
            translation.id,
          );
          batch.set(newTranslationRef, {
            type: ArtifactType.i18n,
            name: translation.name ?? getLanguageCode(translation),
            content: translation.content,
            isArchived: translation.isArchived,
            isRoot: translation.isRoot,
            createdAt: timestamp,
            updatedAt: timestamp,
            createdBy: userRef,
            updatedBy: userRef,
          });
        });
      }

      //add environment variables
      if (environmentVariables) {
        environmentVariables.forEach((value, key) => {
          if (value !== undefined) {
            addEnvironmentVariable(newAppRef.id, currentUser!.id, key, value);
          }
        });
      }

      await batch.commit();
      if (rootScreenRef?.id)
        return {
          insert_app_one: {
            id: newAppRef.id,
            screens: [
              await getScreenDetails(
                newAppRef.id,
                rootScreenRef.id,
                CollectionName.artifacts,
              ),
            ],
          },
        };

      return {
        insert_app_one: {
          id: newAppRef.id,
          screens: [],
        },
      };
    },
    {
      onSuccess: () => {
        //queryClient.invalidateQueries(['userApps', user_id]);
        queryClient.invalidateQueries(["user", currentUser!.id]);
      },
    },
  );
}

export function useCreateScreens(
  currentUser: IEnsembleUserData | null,
  appId: string,
  screens: EnsembleNewScreenData[],
) {
  const queryClient = useQueryClient();
  return useMutation(
    async () => {
      if (screens.length > 0) {
        let labelRef = null;
        const timestamp = serverTimestamp();
        const userRef = doc(db, "users", currentUser!.id);

        if (screens[0].labelGroup)
          labelRef = doc(
            db,
            "apps",
            appId,
            CollectionName.labels,
            screens[0].labelGroup,
          );

        const newScreenRef = collection(
          db,
          "apps",
          appId,
          CollectionName.artifacts,
        );
        const addedScreen = await addDoc(newScreenRef, {
          type: ArtifactType.screen,
          name: screens[0].name,
          description: screens[0].description,
          content: screens[0].content,
          isRoot: screens[0].isRoot,
          isArchived: screens[0].isArchived,
          createdAt: timestamp,
          updatedAt: timestamp,
          createdBy: userRef,
          updatedBy: userRef,
          // Add label ref if that is present on screen
          ...(labelRef && { labelGroup: labelRef }),
        });
        await addHistoryOfArtifact(
          currentUser,
          appId,
          addedScreen.id,
          CollectionName.artifacts,
          {
            content: screens[0].content,
          },
        );

        return {
          insert_screen: {
            returning: [
              {
                id: addedScreen.id,
              },
            ],
          },
        };
      }
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["app", appId]);
      },
    },
  );
}

export function useUpdateWidget(
  currentUser: IEnsembleUserData | null,
  app_id: string,
  widget_id: string,
) {
  const queryClient = useQueryClient();
  return useMutation<unknown, unknown, Partial<EnsembleWidgetData>>(
    async (changes) => {
      const timestamp = serverTimestamp();
      const screenRef = doc(
        db,
        "apps",
        app_id,
        CollectionName.internal_artifacts,
        widget_id!,
      );
      await addHistoryOfArtifact(
        currentUser,
        app_id,
        widget_id,
        CollectionName.internal_artifacts,
        changes,
      );
      await updateDoc(screenRef, {
        ...changes,
        updatedAt: timestamp,
        updatedBy: getUserRef(currentUser!.id),
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["widget", widget_id]);
        queryClient.invalidateQueries(["app", app_id]);
        queryClient.invalidateQueries(["history", widget_id]);
      },
      onError: (error) => {
        console.log(error);
      },
    },
  );
}

export function useCreateWidget(
  currentUser: IEnsembleUserData | null,
  appId: string,
  widget: EnsembleNewWidgetData,
) {
  const queryClient = useQueryClient();
  return useMutation(
    async () => {
      let labelRef = null;
      const timestamp = serverTimestamp();
      const userRef = doc(db, "users", currentUser!.id);

      if (widget.labelGroup)
        labelRef = doc(
          db,
          "apps",
          appId,
          CollectionName.labels,
          widget.labelGroup,
        );

      const newWidgetRef = collection(
        db,
        "apps",
        appId,
        CollectionName.internal_artifacts,
      );
      const addedWidget = await addDoc(newWidgetRef, {
        type: widget.type,
        name: widget.name,
        description: widget.description,
        content: widget.content,
        isArchived: false,
        createdAt: timestamp,
        updatedAt: timestamp,
        createdBy: userRef,
        updatedBy: userRef,
        // Add label ref if that is present on widget
        ...(labelRef && { labelGroup: labelRef }),
      });
      await addHistoryOfArtifact(
        currentUser,
        appId,
        addedWidget.id,
        CollectionName.internal_artifacts,
        {
          content: widget.content,
        },
      );

      return {
        insert_screen: {
          returning: [
            {
              id: addedWidget.id,
            },
          ],
        },
      };
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["app", appId]);
      },
    },
  );
}

export function useCreateTheme(
  currentUser: IEnsembleUserData | null,
  app_id: string,
) {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const timestamp = serverTimestamp();
      const userRef = doc(db, "users", currentUser!.id);

      const content = "# define your app theme here";
      const themeRef = collection(db, "apps", app_id, CollectionName.artifacts);
      const newTheme = await addDoc(themeRef, {
        type: ArtifactType.theme,
        content,
        isArchived: false,
        isRoot: true, // theme should have isRoot=true, same as root screen
        createdAt: timestamp,
        updatedAt: timestamp,
        createdBy: userRef,
        updatedBy: userRef,
      });
      await addHistoryOfArtifact(
        currentUser,
        app_id,
        newTheme.id,
        CollectionName.artifacts,
        { content },
      );

      return {
        insert_theme_one: {
          id: "whatever",
        },
      };
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["app", app_id]);
      },
    },
  );
}

const i18nPrefix = "i18n_";

export const getTranslation = async (
  appId: string,
  languageCode: string,
): Promise<EnsembleTranslationData | undefined> => {
  const ref = doc(
    db,
    "apps",
    appId,
    CollectionName.artifacts,
    i18nPrefix + languageCode,
  ).withConverter(translationDataConverter);
  return (await getDoc(ref)).data();
};

export const useUpdateTranslation = (
  currentUser: IEnsembleUserData,
  appId: string,
  languageCode: string,
  content: string,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const userRef = doc(db, "users", currentUser.id);
      const docRef = doc(
        db,
        "apps",
        appId,
        CollectionName.artifacts,
        i18nPrefix + languageCode,
      );

      try {
        let payload = {
          content: content,
          isArchived: false, // in case it was archived previously
          updatedAt: serverTimestamp(),
          name: languageCode, // to add the `name` attribute in the previous translation records
          updatedBy: userRef,
        };

        // check if there's already a default language
        const defaultLanguages = await getTranslationDefaultLanguages(appId);
        const hasExistingDefaultLanguage =
          defaultLanguages.filter((doc) => doc.data().isArchived !== true)
            .length > 0;
        if (!hasExistingDefaultLanguage) {
          payload = {
            ...payload,
            ...{ defaultLocale: true },
          };
        }

        const doc = await getDoc(docRef);
        if (!doc.exists()) {
          payload = {
            ...payload,
            ...{
              type: ArtifactType.i18n,
              isArchived: false,
              isRoot: true,
              createdAt: serverTimestamp(),
              createdBy: userRef,
            },
          };
        }
        await setDoc(docRef, payload, { merge: true });
        // add history entry
        await addHistoryOfArtifact(
          currentUser,
          appId,
          i18nPrefix + languageCode,
          CollectionName.artifacts,
          {
            content: content,
          },
        );
      } catch (error) {
        console.error("Error updating/creating translation:", error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["app", appId]);
        queryClient.invalidateQueries(["history", i18nPrefix + languageCode]);
      },
    },
  );
};

// get the default languages (should only be at most 1) for an App
const getTranslationDefaultLanguages = async (appId: string) => {
  const q = query(
    collection(db, "apps", appId, CollectionName.artifacts),
    where("type", "==", "i18n"),
    where("defaultLocale", "==", true),
  );
  const snapshot = await getDocs(q);
  return snapshot.docs;
};

// set a default language (and reset previous default language)
export const useSetDefaultTranslationLanguage = (
  appId: string,
  languageCode: string,
  onSuccess: () => void,
) => {
  return useMutation(
    async () => {
      const batch = writeBatch(db);

      // find existing default locale(s) to unset
      const defaultLanguages = await getTranslationDefaultLanguages(appId);
      defaultLanguages.forEach((doc) => {
        batch.update(doc.ref, {
          defaultLocale: false,
        });
      });

      // set new default locale
      batch.update(
        doc(
          db,
          "apps",
          appId,
          CollectionName.artifacts,
          i18nPrefix + languageCode,
        ),
        {
          defaultLocale: true,
        },
      );

      await batch.commit();
    },
    {
      onSuccess: onSuccess,
    },
  );
};

export const useArchiveTranslation = (
  appId: string,
  languageId: string,
  onSuccess: () => void,
  onError: (error: Error) => void,
) => {
  return useMutation(
    async () => {
      const q = query(
        collection(db, "apps", appId, CollectionName.artifacts),
        where("type", "==", "i18n"),
      );
      const snapshot = await getDocs(q);
      const languageCount = snapshot.docs.filter(
        (doc) => doc.data().isArchived !== true,
      ).length;

      const foundDoc = snapshot.docs.find((doc) => doc.id === languageId);
      if (!foundDoc) {
        throw new Error("Unable to archive language.");
      }
      // default language cannot be archived (unless it's the only language - i.e. user don't want to use translation at all)
      else if (languageCount > 1 && foundDoc.data().defaultLocale) {
        throw new Error(
          "Cannot archive the default language. Please set another language as the default before continuing.",
        );
      }
      // archive this language
      await updateDoc(foundDoc.ref, {
        isArchived: true,
      });
    },
    {
      onSuccess: onSuccess,
      onError: onError,
    },
  );
};

function getUserRef(userId: string) {
  return doc(db, "users", userId);
}

export function useUpdateTheme(
  app_id: string,
  content: string,
  currentUser: IEnsembleUserData | null,
) {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const timestamp = serverTimestamp();
      const userRef = doc(db, "users", currentUser!.id);
      const artifacts = collection(
        db,
        "apps",
        app_id,
        CollectionName.artifacts,
      );
      const themes = await getDocs(
        query(artifacts, where("type", "==", "theme"), limit(1)),
      );
      if (themes.size > 0) {
        await addHistoryOfArtifact(
          currentUser,
          app_id,
          themes.docs[0].ref.id,
          CollectionName.artifacts,
          { content },
        );
        await updateDoc(themes.docs[0].ref, {
          content: content,
          updatedAt: timestamp,
          updatedBy: userRef,
        });
        return {
          insert_theme_one: {
            id: "whatever",
          },
        };
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["app", app_id]);
      },
    },
  );
}

export function useUpdateApp(
  app_id: string,
  user_id: string | undefined,
  changes: object,
) {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      // { name: 'new name' }
      const appRef = doc(db, "apps", app_id);
      await updateDoc(appRef, changes);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["app", app_id]);
        queryClient.invalidateQueries(["user", user_id]);
        queryClient.invalidateQueries(["userApps", user_id]);

        // e.g. when archive an App, refresh the list of Apps
        queryClient.invalidateQueries("apps");
      },
      onError: (error) => {
        console.log("error" + error);
      },
    },
  );
}

export function useGetAppFullContent(app_id: string | undefined) {
  return useQuery(["appScreensContent", app_id], async () => {
    const appPayload = await getArtifacts(doc(db, "apps", app_id!));
    return {
      app_by_pk: appPayload,
    };
  });
}

export function useResetRootScreen(
  appId: string,
  newRootScreenId: string,
  screenName?: string,
) {
  const queryClient = useQueryClient();
  return useMutation(
    async () => {
      const batch = writeBatch(db);

      // de-root existing root screen(s) (screens only, exclude items like theme)
      const fetchQuery = query(
        collection(db, "apps", appId, CollectionName.artifacts),
        where("isRoot", "==", true),
        where("type", "==", "screen"),
      );
      await getDocs(fetchQuery).then((snapshot) => {
        snapshot.docs.forEach((myDoc) => {
          batch.update(myDoc.ref, {
            isRoot: false,
          });
        });
      });

      // set new screen as root
      batch.update(
        doc(db, "apps", appId, CollectionName.artifacts, newRootScreenId),
        {
          isRoot: true,
        },
      );

      await batch.commit();
    },
    {
      onSuccess: () => {
        toast.success(
          `${
            screenName ? screenName : "Current screen"
          } is set as the app home screen.`,
          {
            position: "top-right",
            type: toast.TYPE.SUCCESS,
            theme: "dark",
          },
        );
        queryClient.invalidateQueries(["screen", newRootScreenId]);
        queryClient.invalidateQueries(["app", appId]);
      },
    },
  );
}

export const createSession: (
  uid: string,
  userAgent: string,
) => Promise<string> = async (uid, userAgent) => {
  const sessionRef = doc(collection(db, "users", uid, "sessions"));

  const sessionData = {
    signin_time: Timestamp.now(),
    user_agent: userAgent,
    session_id: sessionRef.id,
  };

  await setDoc(sessionRef, sessionData);

  return sessionRef.id;
};

export const updateSession = async (uid: string, sessionId: string) => {
  const sessionRef = doc(db, "users", uid, "sessions", sessionId);

  await updateDoc(sessionRef, {
    signout_time: Timestamp.now(),
  });
};

export const useUpdateLabels = (appId: string) => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    unknown,
    {
      newLabels?: string[];
      deletedLabels?: string[];
      updatedLabels?: labelGroupData[];
    }
  >(
    async (changes) => {
      const promises: Promise<HttpsCallableResult<unknown>>[] = [];

      if (changes.deletedLabels?.length)
        promises.push(
          cloudFunctions.studio_delete_labels({
            appId,
            labels: changes.deletedLabels,
          }),
        );

      if (changes.updatedLabels?.length)
        promises.push(
          cloudFunctions.studio_update_labels({
            appId,
            labels: changes.updatedLabels,
          }),
        );

      // Delete or Updates labels before adding new
      await Promise.all(promises);

      if (changes.newLabels?.length)
        await cloudFunctions.studio_add_labels({
          appId,
          labels: changes.newLabels,
        });
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["app", appId]);
      },
    },
  );
};
