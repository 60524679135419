import { useState, useMemo } from "react";
import { CardGroupOptions, CardSortOptions } from "../utils/constants";
import { groupCardListBy, sortCardListBy } from "../components/cards/functions";

const useCardListOperations = <S,>(list: S[]) => {
  const [sort, setSort] = useState<CardSortOptions>(CardSortOptions.Name);
  const [group, setGroup] = useState<CardGroupOptions>(CardGroupOptions.Labels);

  // Memoize the lists to avoid unnecessary re-computations
  const sortedList = useMemo(() => sortCardListBy<S>(list, sort), [list, sort]);
  const groupedList = useMemo(
    () => groupCardListBy<S>(list, group, sort),
    [list, group, sort],
  );

  return {
    sortedList,
    groupedList,
    sortTrigger: {
      value: sort,
      setter: (value: CardSortOptions) => setSort(value),
    },
    groupTrigger: {
      value: group,
      setter: (value: CardGroupOptions) => setGroup(value),
    },
  };
};

export default useCardListOperations;
