/* eslint-disable prettier/prettier */
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { isEmpty } from "ramda";
import React, { MutableRefObject, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export type ChangeHandler = (
  value: string,
  event: monaco.editor.IModelContentChangedEvent,
  model: monaco.editor.ITextModel | null,
) => void;

export type SaveHandler = () => void;

export type CursorPositionChangeHandler = (
  event: monaco.editor.ICursorPositionChangedEvent,
  model: monaco.editor.ITextModel | null,
) => void;

export type MonacoEditorProps = {
  value: string;
  editor: MutableRefObject<monaco.editor.IStandaloneCodeEditor | null>;
  modelRef?: MutableRefObject<monaco.editor.ITextModel | undefined>;
  onChange?: ChangeHandler;
  cursorOffsetRange?: number[];
  onCursorPosChange?: CursorPositionChangeHandler;
  onSave?: SaveHandler;
  options?: monaco.editor.IStandaloneEditorConstructionOptions;
  actions?: monaco.editor.IActionDescriptor[];
};

const defaultOptions: monaco.editor.IStandaloneEditorConstructionOptions = {
  tabSize: 2,
  automaticLayout: true,
  language: "yaml",
  theme: "vs-dark",
  smoothScrolling: true,
  minimap: {
    enabled: false,
  },
  quickSuggestions: {
    other: true,
    strings: true,
  },
  scrollbar: {
    horizontal: "hidden",
  },
  wordWrap: "on",
  fontSize: 14,
  wordBasedSuggestions: "off",
};

export const MonacoEditor: React.FC<MonacoEditorProps> = ({
  value,
  editor,
  modelRef,
  onChange,
  cursorOffsetRange,
  onCursorPosChange,
  onSave,
  options,
  actions,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const search = queryParams.get("searchTerm") ?? "";
  const line = queryParams.get("line") ?? "";
  const lineNumber = parseInt(line);

  const containerElement = useRef<HTMLDivElement | null>(null);

  const _changeSubscription = useRef<monaco.IDisposable | null>(null);
  const _cursorSubscription = useRef<monaco.IDisposable | null>(null);

  const __prevent_trigger_change_event = useRef<boolean | null>(null);

  const _actionsDisposables = useRef<monaco.IDisposable[] | null>(null);

  // Init monaco editor
  useEffect(() => {
    if (containerElement.current) {
      const newEditor = monaco.editor.create(containerElement.current, {
        ...defaultOptions,
        ...options,
        value: value,
      });
      editor.current = newEditor;
      const model = editor.current.getModel();
      if (modelRef && model) {
        modelRef.current = model;
      }

      if (lineNumber) {
        newEditor.revealLineInCenter(lineNumber);
        newEditor.setSelection({
          startLineNumber: lineNumber,
          endLineNumber: lineNumber,
          startColumn: 1,
          endColumn: 1,
        });
      }

      if (search) {
        const range = model?.findMatches(
          search,
          true,
          false,
          false,
          null,
          false,
        );
        if (range) {
          newEditor.setSelection({
            startLineNumber: range[0].range.startLineNumber,
            startColumn: range[0].range.startColumn,
            endLineNumber: range[0].range.endLineNumber,
            endColumn: range[0].range.endColumn,
          });
        }

        setTimeout(() => {
          const startFindAction = newEditor.getAction(
            "editor.action.startFindAction",
          );
          if (startFindAction) {
            startFindAction.run();
          }
          const findAction = newEditor.getAction("actions.find");
          if (findAction) {
            findAction.run();
          }
        }, 500);
      }

      if (onChange) {
        _changeSubscription.current = newEditor.onDidChangeModelContent(
          (event) => {
            if (!__prevent_trigger_change_event.current && editor.current) {
              onChange(
                editor.current.getValue(),
                event,
                editor.current.getModel(),
              );
            }
          },
        );
      }

      if (onCursorPosChange) {
        _cursorSubscription.current = newEditor.onDidChangeCursorPosition(
          (event) => onCursorPosChange(event, newEditor.getModel()),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentEditor = editor.current;
    if (!currentEditor || !actions) {
      return;
    }

    if (!isEmpty(actions) && !isEmpty(_actionsDisposables)) {
      _actionsDisposables.current?.forEach((disposable) =>
        disposable.dispose(),
      );
    }

    _actionsDisposables.current = actions.map((action) =>
      currentEditor.addAction(action),
    );
  }, [actions, editor]);

  useEffect(() => {
    if (editor.current && onSave) {
      editor.current.addCommand(
        monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS,
        onSave,
      );
    } else if (editor.current && !onSave) {
      editor.current.addCommand(
        monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS,
        () => null,
      );
    }
  }, [editor, onSave]);

  // Move cursor if necessary
  useEffect(() => {
    const currentEditor = editor.current;
    const model = currentEditor?.getModel();
    if (!currentEditor || !model || !cursorOffsetRange) {
      return;
    }
    const start = cursorOffsetRange[0];

    // why is this here? this causes the View to not selectable initialy until the cursor is moved
    // const [ start, end ] = cursorOffsetRange;
    // const currentPosition = currentEditor.getPosition();
    // const currentOffset = currentPosition ? model.getOffsetAt(currentPosition) : -1;
    // if (currentOffset >= start && currentOffset <= end) {
    //   return;
    // }

    const newPosition = model.getPositionAt(start);
    currentEditor.revealPositionInCenter({
      lineNumber: newPosition.lineNumber,
      column: 0,
    });
    const decorations = editor.current?.createDecorationsCollection([
      {
        range: {
          startLineNumber: newPosition.lineNumber,
          endLineNumber: newPosition.lineNumber,
          startColumn: 0,
          endColumn: 100,
        },
        options: {
          inlineClassName: "lineDecoration",
        },
      },
    ]);
    setTimeout(() => {
      decorations?.clear();
    }, 1000);
  }, [cursorOffsetRange, editor]);

  // Update value
  useEffect(() => {
    if (editor.current) {
      const curValue = editor.current.getValue();
      if (value === curValue) {
        return;
      }

      const model = editor.current.getModel();
      if (!model) {
        return;
      }
      __prevent_trigger_change_event.current = true;
      editor.current.executeEdits("", [
        {
          range: model.getFullModelRange(),
          text: value,
          forceMoveMarkers: curValue.length === 0,
        },
      ]);
      editor.current.pushUndoStop();
      __prevent_trigger_change_event.current = false;
    }
  }, [editor, value]);

  // Clean up editor, model, and event handlers
  useEffect(
    () => () => {
      if (editor.current) {
        editor.current?.dispose();
        const model = editor.current.getModel();
        if (model) {
          model?.dispose();
        }
      }
      if (_changeSubscription.current) {
        _changeSubscription.current?.dispose();
      }
      if (_cursorSubscription.current) {
        _cursorSubscription.current?.dispose();
      }
      if (!isEmpty(_actionsDisposables)) {
        _actionsDisposables.current?.forEach((disposable) =>
          disposable.dispose(),
        );
      }
    },
    [editor],
  );

  return <div ref={containerElement} className="monaco-base" />;
};
