import { type FunctionComponent, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppContext } from "./AppPagesWrapper";
import { Icon } from "@iconify/react";

// APIs
import { useModal } from "../hooks/useModal";

// Components
import AppActionsMenu from "../components/AppActionsMenu";
import DemoAppBadge from "../components/DemoAppBadge";
import Modal from "../components/Modal";
import AppClone from "../components/AppClone";
import ScreenCreateModal from "../components/ScreenCreateModal";
import { getBuildConfig, useGetCategories } from "../hooks/useAPIs";
import CollaboratorAvatar from "../components/CollaboratorAvatar";
import DevicePreviewModal from "../components/DevicePreviewModal";
import { AccessType, AppCategory } from "../models/model";
import { useFeatureFlag } from "../hooks/useFeatureFlag";
import SearchBar from "../components/SearchBar";
import { AuthContext } from "../App";
import AppBodyContainer from "../containers/AppBodyContainer";
import ScreenCard from "../components/cards/ScreenCard";

const VisibleAvatarsCount = 5;
const AppScreens: FunctionComponent = () => {
  const navigate = useNavigate();
  const categories = useGetCategories();
  const { currentUser } = useContext(AuthContext);
  const { screenTemplateEnabled } = useFeatureFlag();
  const { app, isAppReadOnly, isDemoApp } = useAppContext();

  const [cloneTrigger, setCloneTrigger] = useState<boolean>(false);
  const [displayPreviewModal, setDevicePreviewModal] = useState<boolean>(false);

  const { isModalDisplayed, toggleModal } = useModal();
  const onCancel = () => toggleModal();
  const onClosePreview = () => setDevicePreviewModal(false);
  const displayCollaborators =
    app.collaborators && Array.from(app?.collaborators?.keys());

  useEffect(() => {
    if (app.name) document.title = app.name;
  });

  const handleClone = () => {
    setCloneTrigger(true);
  };

  const onClickCreateScreen = () => {
    if (screenTemplateEnabled) {
      navigate(`/app/${app.id}/screens/create`);
    } else {
      toggleModal();
    }
  };

  useEffect(() => {
    const accessType = app.collaborators?.get(currentUser?.id ?? "");
    if (accessType !== AccessType.read) {
      getBuildConfig(app.id).catch((error) => {
        console.error("Failed to fetch build config:", error);
      });
    }
  }, [app.collaborators, app.id, currentUser?.id]);

  return (
    <div className="screen-content" key={app.id}>
      {/* breadcrumb */}
      <div className="breadcrumb">
        <Link to="/">Apps</Link>

        {app.isPublic && !isDemoApp && (
          <span>
            {" "}
            / <span className="public-app-badge">Public</span>
          </span>
        )}

        {isDemoApp && (
          <span>
            {" "}
            / <DemoAppBadge />
          </span>
        )}
      </div>

      <div className="page-header">
        <h1>{app?.name}</h1>

        {/* Search Bar  */}

        <div className="page-actions-container">
          {!!app.screens?.length && (
            <SearchBar searchType="screen" searchPlaceholder="Search..." />
          )}

          <div className="app-page-actions">
            {app?.category !== AppCategory.Demo &&
              app?.collaborators &&
              app.collaborators.size > 1 &&
              displayCollaborators && (
                <div className="collaborators-share-action">
                  <Link to={`/app/${app.id}/collaborators`}>
                    {displayCollaborators
                      .slice(0, VisibleAvatarsCount)
                      .map((key) => (
                        <CollaboratorAvatar key={key} collaboratorId={key} />
                      ))}
                    {displayCollaborators.length > 5 && (
                      <div className="avatar-placeholder">
                        <p className="avatar-letter">
                          {"+" + (displayCollaborators.length - 5)}
                        </p>
                      </div>
                    )}
                    <Icon
                      icon="tabler:user-plus"
                      width={30}
                      height={30}
                      className="collaborator-avatar more-collaborators"
                    />
                  </Link>
                  <p
                    className="collaborators-share-button"
                    onClick={() => setDevicePreviewModal(true)}
                  >
                    Share
                  </p>
                </div>
              )}

            {isAppReadOnly ? (
              <button className="button__primary" onClick={handleClone}>
                Clone this app
              </button>
            ) : (
              <button className="button__primary" onClick={onClickCreateScreen}>
                Create screen
              </button>
            )}

            {/* app actions */}
            <AppActionsMenu app={app} />
          </div>
        </div>
      </div>

      <p className="app-description">{app?.description}</p>

      <AppBodyContainer
        appId={app.id}
        cardName="screen"
        itemList={app.screens}
        CardComponent={ScreenCard}
        isReadOnly={isAppReadOnly}
        dataNotFound="This app doesn't have screens."
        cardProps={{
          isTemplate: app.category === "Template",
          categories: categories.data?.category,
        }}
      />

      {/* App clone */}
      {app && (
        <AppClone
          app={app}
          displayCloneModal={cloneTrigger}
          setCloneTrigger={setCloneTrigger}
        />
      )}

      {app && !isAppReadOnly && (
        <Modal
          isModalDisplayed={isModalDisplayed}
          onHide={toggleModal}
          headerText="Create screen"
          modalContent={<ScreenCreateModal onCancel={onCancel} />}
        />
      )}
      <Modal
        isModalDisplayed={displayPreviewModal}
        onHide={onClosePreview}
        headerText="Preview on your device"
        modalContent={<DevicePreviewModal />}
      />
    </div>
  );
};

export default AppScreens;
