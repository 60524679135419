import React, { useEffect, useMemo, useState, useCallback } from "react";
import { ReactComponent as IconExpand } from "../../assets/expand.svg";
import { ReactComponent as IconError } from "../../assets/error-warning-fill.svg";
import { ReactComponent as IconWarn } from "../../assets/warning-fill.svg";
import { ReactComponent as IconInfo } from "../../assets/info_debug.svg";

import "./DebugPanel.sass";

export interface IErrorMessage {
  message: string;
  lineno?: number;
  source?: string;
  colno?: number;
  type: LogType;
}

export enum LogType {
  log = "log",
  error = "error",
  warn = "warn",
  info = "info",
  debug = "debug",
}

const iconMap = {
  [LogType.error]: <IconError />,
  [LogType.warn]: <IconWarn />,
  [LogType.log]: <IconInfo />,
};

const getIcon = (error: IErrorMessage) => {
  return iconMap[error.type as keyof typeof iconMap] ?? <IconInfo />;
};

const filteredMessages = [
  "Connect SDK Version",
  "Loading app from service worker",
  "Error From INSIDE FRAME_WORK",
  "----------------------",
  "StackTrace :  null",
  "Flutter Web Bootstrap: Auto.",
  "TrustedTypes available. Creating policy: gis-dart",
  "TrustedTypes available. Creating policy: flutterfire-firebase_core",
  "Initializing Firebase firebase_core",
  "TrustedTypes available. Creating policy: flutterfire-firebase_firestore",
  "Initializing Firebase firebase_firestore",
  "TrustedTypes available. Creating policy: flutterfire-firebase_auth",
  "Initializing Firebase firebase_auth",
  "TrustedTypes available. Creating policy: flutterfire-firebase_messaging",
  "Initializing Firebase firebase_messaging",
];

const DebugPanel: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<IErrorMessage[]>([]);

  const handleErrorMessages = useCallback((e: MessageEvent) => {
    if (
      (e.source as WindowProxy).location.pathname === "/preview/" ||
      (e.source as WindowProxy).location.pathname.startsWith("/app")
    ) {
      try {
        const errorObj =
          typeof e.data === "string" ? JSON.parse(e.data) : e.data;

        if (
          errorObj.message &&
          typeof errorObj.message === "string" &&
          !filteredMessages.some((msg) => errorObj.message.includes(msg))
        ) {
          if (errorObj.message.startsWith("Error")) {
            errorObj.type = LogType.error;
          }
          setErrorMessage((prev) => [...prev, errorObj]);
        }
      } catch (err) {
        console.error("Failed to parse message:", err);
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", handleErrorMessages);

    return () => {
      window.removeEventListener("message", handleErrorMessages);
    };
  }, [handleErrorMessages]);

  const onClickClear = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setErrorMessage([]);
    },
    [],
  );

  const toggleDebug = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  const errorCounts = useMemo(() => {
    const counts = {
      error: 0,
      warn: 0,
      log: 0,
    };

    for (const error of errorMessage) {
      if (error.type === LogType.error || error.type === LogType.warn) {
        counts[error.type]++;
      } else {
        counts.log++;
      }
    }

    return counts;
  }, [errorMessage]);

  console.log(errorMessage);

  return (
    <div className={`debug-panel ${isExpanded ? "" : "close"}`}>
      <div className="debug-header" onClick={toggleDebug}>
        <div>
          <IconExpand className={`${isExpanded ? "hide-icon" : "show-icon"}`} />
          Console
          {isExpanded && (
            <button className="clear_btn" onClick={onClickClear}>
              Clear
            </button>
          )}
          <div className="icon-container">
            {errorCounts.error > 0 && (
              <div>
                <IconError /> {errorCounts.error}
              </div>
            )}
            {errorCounts.warn > 0 && (
              <div>
                <IconWarn /> {errorCounts.warn}
              </div>
            )}
            {errorCounts.log > 0 && (
              <div>
                <IconInfo className="log-icon" /> {errorCounts.log}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="debug-content">
        {errorMessage.map((error: IErrorMessage, index: number) => {
          const icon = getIcon(error);
          return (
            <div
              key={index}
              className={`debug-content__content ${
                error.type === LogType.error
                  ? "debug-error"
                  : error.type === LogType.warn
                  ? "debug-warn"
                  : "debug-info"
              }`}
            >
              <div className="debug-content__content__with-icon">
                {icon ? <div className="icon">{icon}</div> : null}
                <pre>{error.message}</pre>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(DebugPanel);
