import { type FC } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { timePassed } from "../../utils/functions";
import { DocumentReference } from "firebase/firestore";
import { EnsembleWidgetData } from "../../config/interfaces";
import ScriptActionsMenu from "../ActionMenus/ScriptActionsMenu";

interface ScriptCardProps {
  script: EnsembleWidgetData;
  appId: string;
  isReadOnly: boolean;
}

const ScriptCard: FC<ScriptCardProps> = ({ script, appId, isReadOnly }) => {
  return (
    <Link title={script.description} to={`/app/${appId}/script/${script.id}`}>
      <div className="screen-card">
        <div className="screen-card__content">
          {!(script.labelGroup instanceof DocumentReference) && (
            <div className="screen-card__content-label">
              {script.labelGroup?.name}
            </div>
          )}
          <h4 style={{ marginBottom: 0 }}>{script.name}</h4>
          <div className="screen-card__content-info">
            {script.updatedAt && script.updatedBy && (
              <span>
                {timePassed(script.updatedAt)}, <>{script.updatedBy.name}</>
              </span>
            )}
            <span>{script.description && <>{script?.description}</>}</span>
          </div>
          <div>
            {!isReadOnly && (
              <ScriptActionsMenu script={script} isReadOnly={isReadOnly} />
            )}
          </div>
        </div>
      </div>
      <ReactTooltip effect="solid" place="top" />
    </Link>
  );
};

export default ScriptCard;
