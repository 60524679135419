import React from "react";

export interface ExpressionInputProps {
  value: string | number;
  placeholder?: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  onEnterKey: () => void;
}

// widget that enables text or mixing text with expressions
export const ExpressionInput: React.FC<ExpressionInputProps> = ({
  value,
  placeholder,
  onChange,
  onBlur,
  onEnterKey,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    onChange(newValue);
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onEnterKey();
      event.preventDefault();
    }
  };

  return (
    <input
      type="text"
      className="expression-input"
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
      onKeyUp={handleKeyUp}
      onBlur={onBlur}
    />
  );
};
