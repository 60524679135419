import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { get, set } from "js-cookie";

export const TranspileToast: React.FC = () => {
  const [isChecked, setChecked] = useState<boolean>(
    get("showTranspileToast") === "false",
  );
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setChecked(isChecked);
    set("showTranspileToast", (!isChecked).toString());
  };

  return (
    <div>
      <p>
        Javscript ES6 code was detected and has been automatically converted
        into ES5
      </p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "row",
        }}
      >
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <label style={{ marginLeft: "5px" }}>Do not show again</label>
      </div>
    </div>
  );
};

export const showTranspileToast = (artifactId: string) => {
  toast.warning(<TranspileToast />, {
    toastId: `transpile_${artifactId}`,
    position: "bottom-right",
    type: toast.TYPE.WARNING,
    theme: "dark",
    closeOnClick: false,
  });
};
