import {
  isMap,
  isNode,
  isPair,
  isScalar,
  Node,
  Pair,
  Scalar,
  YAMLMap,
} from "yaml";
import {
  ChildPath,
  ChildrenOverride,
  NodeCategory,
  TreeItemWidgetPayload,
  TreeNode,
} from "../containers/NavigatorPanel/TreeNode";
import React from "react";
import { TreeBuilder } from "../containers/NavigatorPanel/TreeBuilder";
import { ArtifactType } from "../pages/EditorPage";
import { NodeAction } from "../hooks/useYamlDoc";
import { WidgetIcon } from "./widgetUtils";
import { find } from "./docUtils";

export const getTreeIcon = (name: string, size?: number) => {
  return (
    <div className={"ant-tree-node-icon-container"}>
      <WidgetIcon iconName={name} size={size} />
    </div>
  );
};

// top level tree icons
const treeRootIcons: { [key in NodeCategory]: React.ReactNode } = {
  // for screen tree
  [NodeCategory.ViewRoot]: getTreeIcon("view"),
  [NodeCategory.ViewGroupRoot]: getTreeIcon("viewGroup"),
  [NodeCategory.CustomWidget]: getTreeIcon("custom"),
  [NodeCategory.API]: getTreeIcon("api"),
  [NodeCategory.CodeBlock]: getTreeIcon("condition"),

  // for widget tree
  [NodeCategory.WidgetRoot]: getTreeIcon("custom"),

  // for header and footer
  [NodeCategory.Header]: getTreeIcon(NodeCategory.Header),
  [NodeCategory.Footer]: getTreeIcon(NodeCategory.Footer),
};

export const hasViewGroup = (
  artifactType: ArtifactType,
  root: YAMLMap,
): boolean => {
  if (artifactType === ArtifactType.screen) {
    return root.has("ViewGroup");
  }
  return false;
};

/**
 * Build the root node for the Screen or the Widget tree
 */
export const buildScreenRoot = (
  artifactType: ArtifactType,
  root: YAMLMap,
): TreeNode[] | null => {
  const rootName = artifactType === ArtifactType.screen ? "View" : "Widget";
  let node = null;
  const parent = root;
  root.items.forEach((item) => {
    if ((item.key as Scalar)?.value === rootName) {
      node = item;
      return;
    }
  });
  if (node) {
    return [
      {
        key: "root",
        ref: {
          node: node,
          parent: parent,
          category:
            artifactType === ArtifactType.screen
              ? NodeCategory.ViewRoot
              : NodeCategory.WidgetRoot,
        },
        title:
          artifactType === ArtifactType.screen
            ? "Screen Settings"
            : "Widget Settings",
        icon: () =>
          artifactType === ArtifactType.screen
            ? treeRootIcons[NodeCategory.ViewRoot]
            : treeRootIcons[NodeCategory.WidgetRoot],
        isLeaf: true,
      },
    ];
  }
  return null;
};

/**
 * Build the header and footer nodes for the Screen tree
 */
export const buildNodesInView = (
  doc: YAMLMap,
  nodeKey: string,
): TreeNode[] | null => {
  const node = doc.items.find(
    (item) => isPair(item) && isMap(item.value) && isScalar(item.key),
  ) as Pair;

  if (!isScalar(node.key) || node.key.value !== "View") {
    return null;
  }

  const viewNode = node.value as YAMLMap;

  const targetPair = viewNode.items.find(
    (item) => isPair(item) && isScalar(item.key) && item.key.value === nodeKey,
  ) as Pair;

  if (targetPair) {
    return [
      {
        key: nodeKey,
        ref: {
          node: targetPair,
          parent: viewNode,
          category: nodeKey as NodeCategory,
        },
        title: nodeKey.charAt(0).toUpperCase() + nodeKey.slice(1),
        icon: () => getTreeIcon(nodeKey),
        isLeaf: true,
      },
    ];
  }

  return null;
};

/**
 * Build the body node for the Screen or Widget tree
 */
export const buildScreenBody = (
  artifactType: ArtifactType,
  root: YAMLMap,
  whitelistedWidgets: Set<string>,
  containerList: ReadonlySet<string>,
  childrenOverrides: Map<string, ChildrenOverride>,
  treeItemWidgetMap: Map<string, TreeItemWidgetPayload[]>,
): TreeNode[] | null => {
  const rootName = artifactType === ArtifactType.screen ? "View" : "Widget";
  const rootNode = root.get(rootName);
  if (isMap(rootNode)) {
    const body = rootNode.get("body");
    if (isMap(body)) {
      return new TreeBuilder(
        whitelistedWidgets,
        containerList,
        childrenOverrides,
        treeItemWidgetMap,
      ).build(body, "body");
    }
  }
  return null;
};

export const buildScreenCustomWidgets = (
  root: YAMLMap,
  screenCustomWidgets: Scalar[],
  whitelistedWidgets: Set<string>,
  containerList: ReadonlySet<string>,
  childrenOverrides: Map<string, ChildrenOverride>,
  treeItemWidgetMap: Map<string, TreeItemWidgetPayload[]>,
): TreeNode[] | null => {
  const widgets: TreeNode[] = [];
  // build each custom widget's body, then re-construct them
  // into a tree map with multiple roots
  screenCustomWidgets.forEach((widget) => {
    const widgetName = widget.value as string;
    const widgetValue = root.get(widget);
    if (!isNode(widgetValue)) return;

    const widgetPair = root.items.find((pair) => pair.key === widget);
    if (!widgetPair || !isMap(widgetValue)) return;

    // add the body to each custom widget
    let bodyTree: TreeNode[] | null = null;
    const bodyNode = widgetValue.get("body");
    if (isMap(bodyNode)) {
      bodyTree = new TreeBuilder(
        whitelistedWidgets,
        containerList,
        childrenOverrides,
        treeItemWidgetMap,
      ).build(bodyNode, widgetName);
    }
    widgets.push({
      key: `widget${widgetName}`,
      ref: {
        node: widgetPair,
        parent: root,
        category: NodeCategory.CustomWidget,
      },
      title: widget.value as string,
      icon: () => treeRootIcons[NodeCategory.CustomWidget],
      children: bodyTree ?? undefined,
    });

    // if widget's body is not there, add the "Set Body" placeholder as the next node
    if (!bodyTree || bodyTree.length === 0) {
      widgets.push({
        key: `${widgetName}-setBody`,
        selectable: false,
        ref: {
          node: new Scalar(""),
          parent: widgetValue,
          action: NodeAction.SetWidgetBody,
        },
        title: "Set Body",
        isLeaf: true,
      });
    }
  });
  return widgets.length > 0 ? widgets : null;
};

export const buildScreenAPIs = (root: YAMLMap): TreeNode[] | null => {
  const result: TreeNode[] = [];
  const apis = root.get("API");
  if (isMap(apis)) {
    apis.items.forEach((apiPair) => {
      if (!isNode(apiPair.key)) return;
      const name = (apiPair.key as Scalar).value as string;
      result.push({
        key: `api${name}`,
        ref: {
          node: apiPair,
          parent: apis,
          category: NodeCategory.API,
        },
        title: name,
        icon: () => treeRootIcons[NodeCategory.API],
        isLeaf: true,
      });
    });
  }
  return result.length > 0 ? result : null;
};

export const buildScreenCodeBlock = (root: YAMLMap): TreeNode[] | null => {
  const globalBlock = root.items.find(
    (item) => isScalar(item.key) && item.key.value === "Global",
  );
  if (isPair(globalBlock)) {
    return [
      {
        key: "codeBlock",
        ref: {
          node: globalBlock.key as Scalar,
          parent: root,
          category: NodeCategory.CodeBlock,
        },
        title: "Script Block",
        icon: () => treeRootIcons[NodeCategory.CodeBlock],
        isLeaf: true,
      },
    ];
  }
  return null;
};

// build a standalone widget tree
export const buildWidgetTree = (
  widgetRoot: YAMLMap | null,
  whitelistedWidgets: ReadonlySet<string>,
  containerList: ReadonlySet<string>,
  childrenOverrides: Map<string, ChildrenOverride>,
  treeItemWidgetMap: Map<string, TreeItemWidgetPayload[]>,
): TreeNode[] | null => {
  if (widgetRoot) {
    return new TreeBuilder(
      whitelistedWidgets,
      containerList,
      childrenOverrides,
      treeItemWidgetMap,
    ).build(widgetRoot, "widget");
  }
  return null;
};

// get the subtitle for the tree node given the childrenOverride's path
export const getTreeNodeSubTitle = (
  node: Node,
  childPath?: ChildPath,
): string | undefined => {
  if (childPath?.label) {
    const labelPaths = childPath.label.split("|");
    for (const path of labelPaths) {
      const found = find(path.split("_"), node);
      // undefined means not found, null means keys are there with no value
      if (found !== undefined) {
        const labelParts = childPath.showLabel === true ? [path] : [];
        if (found && !isNull(found)) {
          labelParts.push(found.toString());
        }
        if (labelParts.length > 0) {
          return labelParts.join(" ");
        }
      }
    }
  }
};

export const isNull = (node: Node): boolean => {
  return node == null || (isScalar(node) && node.value === null);
};
