import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../App";
import { AppCreationCategory } from "../config/interfaces";

// APIs
import { getApps, getGeneratingApps } from "../hooks/useAPIs";
import { useGeneratingModal, useModal } from "../hooks/useModal";
import { useFeatureFlag } from "../hooks/useFeatureFlag";

// Components
import AppCard from "../components/AppCard";
import Modal from "../components/Modal";
import AppCreateModal from "../components/AppCreateModal";
import { useQuery } from "react-query";
import DownloadStore from "../components/DownloadStore";
import AppGeneratingModal from "../components/AppGeneratingModal";
import { AppCategory, AppData } from "../models/model";
import AppInvites from "../components/AppInvites/AppInvites";

const appsCategories = [
  { key: "all", value: "All apps" },
  { key: "created", value: "Created by me" },
  { key: "generated", value: "Generated with AI" },
  { key: "shared", value: "Shared with me" },
];

const Home: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [selectedCategory, setSelectedCategory] = useState<AppCreationCategory>(
    appsCategories[0],
  );
  const { aiEnabled } = useFeatureFlag();

  const appsQuery = useQuery("apps", () => getApps(currentUser!.id), {
    refetchOnWindowFocus: false,
  });

  const generatingAppsQuery = useQuery(
    "generatingApps",
    () => getGeneratingApps(currentUser!.id),
    {
      refetchOnWindowFocus: false,
    },
  );

  const filteredApps = useMemo(() => {
    let apps: AppData[] = [];
    if (appsQuery.isSuccess && aiEnabled) {
      switch (selectedCategory.key) {
        case "all":
          apps = appsQuery.data;
          break;
        case "created":
          apps = appsQuery.data.filter((app) => {
            let isOwner = false;
            app.collaborators?.forEach((value: string, key: string) => {
              if (key === currentUser!.id && value === "owner") isOwner = true;
            });
            return app.isAutoGenerated ? false : isOwner;
          });
          break;
        case "generated":
          apps = appsQuery.data.filter(
            (app) => app.isAutoGenerated && app.status === "Success",
          );
          break;
        case "shared":
          apps = appsQuery.data.filter((app) => {
            let isShared = false;
            app.collaborators?.forEach((value: string, key: string) => {
              if (key === currentUser!.id && value !== "owner") isShared = true;
            });
            return isShared;
          });
          break;
        default:
          apps = appsQuery.data;
          break;
      }
    } else if (appsQuery.isSuccess) {
      apps = appsQuery.data.sort((a, b): number => {
        if (a.name && b.name) {
          if (!a.category && b.category) {
            return -1;
          } else if (a.category && !b.category) {
            return 1;
          } else {
            return a.name.localeCompare(b.name);
          }
        }
        return 0;
      });
    }

    // sort Demo apps by demoOrder property
    apps = apps.sort((a, b) => {
      if (a.category === AppCategory.Demo && b.category === AppCategory.Demo) {
        return (a.demoOrder || 0) - (b.demoOrder || 0);
      }
      return 0;
    });

    // remove duplicate apps
    return apps.filter((f, i, a) => a.findIndex((t) => t.id === f.id) === i);
  }, [appsQuery, selectedCategory.key, aiEnabled, currentUser]);

  // App create modal
  const { isModalDisplayed, toggleModal } = useModal();
  const onCancel = () => toggleModal();

  // App generating modal
  const { isGeneratingModalDisplayed, toggleGeneratingModal } =
    useGeneratingModal();
  const onGeneratingCancel = () => toggleGeneratingModal();

  // navigate to resources
  const navigateToResources = () => {
    navigate("resources");
  };

  useEffect(() => {
    document.title = "Ensemble Apps";
  }, []);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.metaKey && event.key === "k") {
        toggleGeneratingModal();
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page-ct">
      <div className="apps-content">
        <div className="page-header">
          <h1>Apps</h1>
          <div>
            <button className="button__secondary" onClick={navigateToResources}>
              Help and Resources
            </button>
            <button className="button__primary" onClick={toggleModal}>
              Create new app
            </button>
          </div>
        </div>

        {appsQuery.isError && (
          <p className="small">
            {"Oops! we couldn't get your apps. Please try again."}
          </p>
        )}

        {aiEnabled && !appsQuery.isFetching && (
          <div className="app-categories">
            {appsCategories.map((category) => (
              <span
                key={category.key}
                onClick={() => setSelectedCategory(category)}
                className={`${
                  selectedCategory.key === category.key && "selected"
                }`}
              >
                {category.value}
              </span>
            ))}
          </div>
        )}

        <div>
          {!generatingAppsQuery.isFetching &&
            generatingAppsQuery.data &&
            generatingAppsQuery.data.filter((app) => app.status === "Started")
              .length > 0 && (
              <>
                <div>Generating Apps:</div>
                {generatingAppsQuery.data
                  ?.filter((app) => app.status === "Started")
                  .map((app, index) => {
                    return (
                      <div key={index}>
                        {app.name} - {app.id} ({app.status})
                      </div>
                    );
                  })}
                <br />
                <br />
              </>
            )}
        </div>

        <AppInvites />

        <div className="app-cards-ct">
          {!appsQuery.isFetching && (
            <>
              {filteredApps
                ?.filter((app) => app.category !== AppCategory.Demo)
                ?.map((app) => {
                  return <AppCard key={app.id} app={app} />;
                })}
            </>
          )}
        </div>

        {!appsQuery.isFetching &&
          filteredApps?.filter((app) => app.category !== AppCategory.Demo)
            ?.length === 0 && (
            <div className="empty-state-ct">
              <p>Hi there, we can&apos;t wait to see what you build!</p>
              <p>
                Got any questions?{" "}
                <a
                  href="https://discord.gg/R8Csg5ajz2"
                  target="_blank"
                  rel="noreferrer"
                >
                  Join us on Discord
                </a>
                .
              </p>
            </div>
          )}

        <h2>Sample apps</h2>
        <div className="app-cards-ct">
          {!appsQuery.isFetching && (
            <>
              {filteredApps
                ?.filter((app) => app.category === AppCategory.Demo)
                ?.map((app) => {
                  return <AppCard key={app.id} app={app} />;
                })}
            </>
          )}
        </div>

        <div className="page-footer">
          <DownloadStore />
        </div>
        {/* App create modal */}
        {currentUser && (
          <Modal
            isModalDisplayed={isModalDisplayed}
            onHide={toggleModal}
            headerText="Create new app"
            modalContent={<AppCreateModal onCancel={onCancel} />}
          />
        )}
        {/* App generating modal */}
        {currentUser && (
          <Modal
            isModalDisplayed={isGeneratingModalDisplayed}
            onHide={toggleGeneratingModal}
            headerText="What would you like to build?"
            modalContent={<AppGeneratingModal onCancel={onGeneratingCancel} />}
          />
        )}
      </div>
    </div>
  );
};
export default Home;
