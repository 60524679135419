import { Form, Input } from "antd";
import type { FormInstance } from "antd/lib";
import { Button, ButtonVariant } from "../../components/Button/Button";

interface LabelManagerFormProps {
  form: FormInstance<any>;
  labels: Map<string, any>;
  onRemoveLabel: (id: string) => void;
  onInputChange: (labelId: string, index: number, value: string) => void;
  initialLabels?: Map<string, string>;
}

const LabelManagerForm = ({
  form,
  labels,
  onInputChange,
  onRemoveLabel,
  initialLabels,
}: LabelManagerFormProps) => {
  return (
    <div className="label-manager__form-wrapper">
      <Form
        form={form}
        className="label-manager__form"
        initialValues={Object.fromEntries(initialLabels || [])}
      >
        {[...labels.entries()].map(([labelId, labelData], index) => (
          <div key={labelId}>
            <div className="label-manager__form-content">
              <Form.Item
                name={labelId}
                rules={[
                  {
                    required: index !== labels.size - 1,
                    message: "Field is required!",
                  },
                ]}
              >
                <Input
                  className="label-manager__input"
                  value={labelData.name}
                  onChange={(e) =>
                    onInputChange(labelId, index, e.target.value)
                  }
                />
              </Form.Item>
              {index !== labels.size - 1 && (
                <div className="label-manager__details">
                  <div className="label-manager__info">
                    {[
                      labelData.screens && `${labelData.screens} screens`,
                      labelData.widgets && `${labelData.widgets} widgets`,
                      labelData.scripts && `${labelData.scripts} scripts`,
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  </div>
                  <Button
                    className="label-manager__delete"
                    variant={ButtonVariant.LINK}
                    onClick={() => onRemoveLabel(labelId)}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </div>
            <hr className="label-manager__divider" />
          </div>
        ))}
      </Form>
    </div>
  );
};

export default LabelManagerForm;
