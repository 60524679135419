import { FieldProps } from "@rjsf/utils";
import React, { useMemo, useState } from "react";
import "./WidgetBuilderField.sass";
import { WidgetIcon } from "../../../utils/widgetUtils";
import { WidgetBuilderModal } from "./WidgetBuilderModal";
import { FormRefProvider } from "../../../models/FormRefContext";
import { useSchemas } from "../../../hooks/useSchemas";
import { camelCaseToWords } from "../utils/propertyPanelUtils";
import { useYamlDoc } from "../../../hooks/useYamlDoc";
import { RootWidgetContext } from "./RootWidgetContext";
import { YAMLMap } from "yaml";
import { WidgetItemPicker } from "../../../components/VisualEditor/ItemPicker";
import { useCustomWidgets } from "../../../hooks/useCustomWidgets";

export const WidgetBuilderField: React.FC<FieldProps> = (props) => {
  const { formData, formContext, idSchema } = props;
  const { findWidgetSchema } = useSchemas();
  const { dispatchVisualEditorChanges } = useYamlDoc();
  const [isOpened, setIsOpened] = useState(false);
  const { doc } = useYamlDoc();
  const { customWidgets } = useCustomWidgets();

  // the display for the selected widget. If this is undefined, it is safe to assume that there is no widget selected
  const anchorPreview = useMemo(() => {
    if (
      formData &&
      typeof formData === "object" &&
      Object.keys(formData).length > 0
    ) {
      const widgetName = Object.keys(formData)[0];
      if (
        findWidgetSchema(widgetName) != null ||
        customWidgets.has(widgetName)
      ) {
        return (
          <div className={"widget-picker-trigger-label"}>
            <WidgetIcon iconName={widgetName} />
            {camelCaseToWords(widgetName)}
          </div>
        );
      }
    }
  }, [customWidgets, findWidgetSchema, formData]);

  const handleWidgetSelect = (widgetName: string) => {
    if (!doc) return;
    const nodeContext = new RootWidgetContext(formContext, idSchema.$id);
    nodeContext.addLeafNode(doc, widgetName, new YAMLMap());
    // const leafPair = nodeContext.getOrCreateLeafNodePair();
    // leafPair.value?.set(new Scalar(widgetName), new YAMLMap());
    dispatchVisualEditorChanges();
    setIsOpened(true);
  };

  // if the parent widget:
  // 1. has children override
  // 2. the id matches the path of this widget
  // then we show the anchor instead of popup

  return (
    <div className={"widget-builder-field"}>
      {anchorPreview ? (
        <div
          className={"widget-picker-trigger"}
          onClick={() => setIsOpened(true)}
        >
          {anchorPreview}
        </div>
      ) : (
        <WidgetItemPicker onItemSelect={handleWidgetSelect} />
      )}
      <FormRefProvider>
        {isOpened && (
          <WidgetBuilderModal
            {...props}
            isOpened={isOpened}
            setIsOpened={setIsOpened}
          />
        )}
      </FormRefProvider>
    </div>
  );
};
