import { useLayoutEffect, useRef } from "react";
import { LogType } from "../containers/DebugPanel";

export const useIframeConsole = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const consoleLogObjRef = useRef<Console | null>(null);

  const customLogger = useRef(
    (
      type: LogType,
      message: any,
      source?: string,
      lineno?: number,
      colno?: number,
      ...args: any[]
    ) => {
      window.postMessage(
        JSON.stringify({
          type,
          message,
          source,
          lineno,
          colno,
          args,
        }),
        "*",
      );
    },
  ).current;

  useLayoutEffect(() => {
    const iframeWindow = iframeRef.current?.contentWindow;

    if (!iframeWindow) {
      console.warn("Iframe window is not available.");
      return;
    }

    if (!consoleLogObjRef.current) {
      consoleLogObjRef.current = iframeWindow.console;
    }

    const originalConsole = iframeWindow.console;

    iframeWindow.console = Object.keys(LogType).reduce<Console>(
      (customConsole, key) => {
        const type = LogType[key as keyof typeof LogType];
        customConsole[type] = (message, ...args) =>
          customLogger(type, message, ...args);
        return customConsole;
      },
      { ...originalConsole },
    );

    iframeWindow.onerror = (_event, source, lineno, colno, error) => {
      const errorMessage = `${error?.message || "Unknown error"}`;
      customLogger(LogType.error, errorMessage, source, lineno, colno);
      return false;
    };

    iframeWindow.onunhandledrejection = (event) => {
      customLogger(LogType.error, event.reason);
    };

    return () => {
      if (iframeWindow && consoleLogObjRef.current) {
        iframeWindow.console = consoleLogObjRef.current;
      }
    };
  }, [customLogger]);

  return iframeRef;
};
