import type { FC } from "react";
import { Link } from "react-router-dom";
import { timePassed } from "../../utils";
import ReactTooltip from "react-tooltip";
import ScreenActionsMenu from "../ScreenActionsMenu";
import { DocumentReference } from "firebase/firestore";
import { ReactComponent as AppHomeIcon } from "../../assets/app-home.svg";
import {
  EnsembleCategoriesData,
  EnsembleScreenData,
} from "../../config/interfaces";

interface ScreenCardProps {
  screen: EnsembleScreenData;
  categories: EnsembleCategoriesData[] | undefined;
  appId: string;
  isReadOnly: boolean;
  isTemplate: boolean;
}

const ScreenCard: FC<ScreenCardProps> = ({
  screen,
  categories,
  appId,
  isReadOnly,
  isTemplate,
}) => {
  return (
    <Link to={`/app/${appId}/screen/${screen.id}`} title={screen.description}>
      <div className="screen-card">
        <div className="screen-card__content">
          <div className="screen-card__content-header">
            {screen.isRoot && (
              <>
                <span className="screen-card__content-home">
                  <AppHomeIcon className="screen-card__content-icon" />
                  <>App Home</>
                </span>
                {!!screen.labelGroup && (
                  <span className="screen-card__content-dot">•</span>
                )}
              </>
            )}
            {!(screen.labelGroup instanceof DocumentReference) && (
              <span className="screen-card__content-label">
                {screen.labelGroup?.name}
              </span>
            )}
          </div>
          <h4 className="screen-card__name">{screen.name}</h4>
          <div className="screen-card__content-info">
            {screen.updatedAt && screen.updatedBy && (
              <span>
                {timePassed(screen.updatedAt)}, <>{screen.updatedBy.name}</>
              </span>
            )}

            {screen.description && <span>{screen.description}</span>}
            {isTemplate && (
              <span>
                {categories?.find((c) => c.id === screen.category)?.name}
              </span>
            )}
          </div>

          {/* screen actions */}
          {!isReadOnly && (
            <ScreenActionsMenu
              screen={screen}
              categories={categories}
              isTemplate={isTemplate}
              isReadOnly={isReadOnly}
            />
          )}
        </div>
      </div>
      <ReactTooltip effect="solid" place="top" />
    </Link>
  );
};

export default ScreenCard;
