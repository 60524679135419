import { useMemo } from "react";
import { getUserInvites } from "../../hooks/useAPIs";
import "./AppInvite.sass";
import { useQuery } from "react-query";
import InviteItem, { Invite } from "./InviteItem";

const AppInvites = () => {
  const invitesQuery = useQuery("invites", () => getUserInvites(), {
    refetchOnWindowFocus: false,
  });

  const appsInvites: Invite[] = useMemo(() => {
    if (invitesQuery.isSuccess) {
      return (invitesQuery.data?.data as { invites: Invite[] })?.invites;
    }
    return [];
  }, [invitesQuery]);

  const refreshInvites = () => {
    invitesQuery.refetch();
  };

  return (
    <div
      className="container"
      style={{
        display: appsInvites.length > 0 ? "block" : "none",
      }}
    >
      <p className="pending-invites">Your pending invites</p>
      {appsInvites.map((invite, index) => (
        <>
          <InviteItem
            invite={invite}
            key={invite.ref}
            refreshInvites={refreshInvites}
          />
          {index < appsInvites.length - 1 && <hr className="separator" />}
        </>
      ))}
    </div>
  );
};

export default AppInvites;
