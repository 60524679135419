import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { JSONSchema7 } from "json-schema";
import { Document, isPair, isScalar, YAMLMap } from "yaml";

import { YAMLDocNode, YamlDocState, useYamlDoc } from "../../hooks/useYamlDoc";
import { useSchemas } from "../../hooks/useSchemas";

import "./WidgetPropertyPanel.sass";
import "./CustomFieldTemplate.sass";
import { WidgetSelectionItem } from "../../components/VisualEditor/WidgetSelectionPanel";
import { parseWidgetNames } from "../../utils";
import {
  getFormDataValues,
  getSubschema,
  updateNodeWithHex,
  getWidgetId,
  getSettingsSchema,
} from "./utils/propertyPanelSchemaUtils";
import { PropertySetForm } from "./PropertySetForm";
import { IChangeEvent } from "@rjsf/core";
import {
  ActionMenu,
  ActionMenuItem,
} from "../../components/VisualEditor/ActionMenu";
import { RemixIcon } from "../../components/Widgets";
import { EditableText } from "../../components/VisualEditor/EditableText";
import { NodeType, usePropertyPanel } from "../../hooks/usePropertyPanel";
import { useCustomWidgets } from "../../hooks/useCustomWidgets";
import { removeNodeFromDirectParent, updateNodeId } from "../../utils/docUtils";
import { getKeyValue, getNodeRef } from "../../utils/schemaUtils";
import { useAppContext } from "../../pages/AppPagesWrapper";
import { containerWidgets } from "./utils/propertyPanelUtils";

export type WidgetPropertyPanelProps = {
  node: YAMLDocNode;
  // we use parent if we need to replace the node e.g. convert a Scalar into YamlMap
  parentNode: YAMLMap | null;
  isRoot?: boolean;
  onNodeUpdate?: () => void;
  onNodeRemove?: () => void;
};

export type NodeFormContext = {
  node: YAMLDocNode;
  doc: Document;
  platformWidgets: WidgetSelectionItem[];
  customWidgets: WidgetSelectionItem[];
} & Pick<
  YamlDocState,
  "addNode" | "setSelectedNode" | "removeNode" | "updateNode"
>;

const CONVERT_PREFIX = "convert-to-";

export const WidgetPropertyPanel: React.FC<WidgetPropertyPanelProps> = ({
  node,
  parentNode,
  isRoot = false,
  onNodeRemove,
}) => {
  const {
    doc,
    updateNode,
    isPropertyPanelOpen,
    setIsPropertyPanelOpen,
    removeNode,
    addNode,
    setSelectedNode,
    dispatchVisualEditorChanges,
    setCopiedNode,
    copiedNode,
    pasteNode,
    selectedNode,
  } = useYamlDoc();
  const { app } = useAppContext();
  const nodeKey = getKeyValue(node);
  const { nodeSchema, uiSchema, nodeType } = usePropertyPanel(node);
  const { customWidgets } = useCustomWidgets();
  const schemas = useSchemas();
  // const [isUsage, setIsUsage] = useState(false);
  const [activeCategory, setActiveCategory] = useState<
    "Settings" | "Styles" | "Actions"
  >("Settings");
  const nameInputRef = useRef<HTMLInputElement>(null);
  // reset the form when the selected node has change
  const [formKey, setFormKey] = useState(0);
  useEffect(() => {
    setFormKey((prev) => prev + 1);
  }, [node]);

  // We move the ID for widgets to the header if applicable
  const [isWidgetSchemaWithId, setIsWidgetSchemaWithId] = useState(false);
  const [widgetId, setWidgetId] = useState<string | null | undefined>(
    undefined,
  );
  useEffect(() => {
    if (
      (nodeType === NodeType.Widget ||
        nodeType === NodeType.CustomWidgetUsage) &&
      nodeSchema?.properties?.id
    ) {
      setIsWidgetSchemaWithId(true);
      setWidgetId(getWidgetId(node));
    } else {
      setIsWidgetSchemaWithId(false);
      setWidgetId(undefined);
    }
  }, [isWidgetSchemaWithId, nodeType, nodeSchema, node]);

  const [schemaProps, setSchemaProps] = useState<{
    widgets: WidgetSelectionItem[];
    systemProps: string[];
  } | null>(null);

  const isObjectNodeSchema = useCallback(() => {
    return nodeSchema?.type === "object" || nodeSchema?.$ref === "#/$defs/View";
  }, [nodeSchema]);

  useEffect(() => {
    const maybeSchema = schemas?.editorScreenSchema?.schema;
    if (maybeSchema) {
      try {
        setSchemaProps(parseWidgetNames(maybeSchema));
      } catch (error) {
        setSchemaProps(null);
      }
    }
  }, [schemas]);

  useEffect(() => {
    if (nameInputRef.current && nodeKey) {
      nameInputRef.current.value = nodeKey;
    }
  }, [nameInputRef, nodeKey]);

  const subSchemas = useMemo<Record<string, JSONSchema7 | undefined>>(() => {
    if (!nodeSchema) {
      return {};
    }
    if (!isObjectNodeSchema()) {
      return {
        Settings: nodeSchema,
      };
    }
    return {
      Settings: getSettingsSchema(nodeSchema, isWidgetSchemaWithId),
      Styles: getSubschema(nodeSchema, "Styles"),
      Actions: getSubschema(nodeSchema, "Actions"),
    };
  }, [isObjectNodeSchema, nodeSchema, isWidgetSchemaWithId]);

  const onSubmit = useCallback<(data: IChangeEvent, event: FormEvent) => void>(
    ({ formData }) => {
      if (!isPair(node) || !nodeKey) {
        return;
      }

      if (!isObjectNodeSchema()) {
        node.value = formData;
        updateNode(node);
        return;
      }
      const initialData = getFormDataValues(node, nodeKey);
      const mergedData =
        activeCategory === "Styles"
          ? { ...initialData, styles: formData }
          : { ...initialData, ...formData };
      const updatedNodeValue = doc?.createNode(mergedData);
      const updatedNodeWithHex = updateNodeWithHex(updatedNodeValue);
      const updatedName = nameInputRef.current?.value;
      if (updatedNodeWithHex) {
        node.value = updatedNodeWithHex;
        if (updatedName && isScalar(node.key)) {
          node.key.value = updatedName;
        }
        updateNode(node);
      }
    },
    [node, nodeKey, isObjectNodeSchema, activeCategory, doc, updateNode],
  );

  // remove itself from the tree
  const onRemove = useCallback(() => {
    if (node) {
      if (isRoot) {
        removeNode(node);
        setIsPropertyPanelOpen(false);
      } else if (parentNode) {
        removeNodeFromDirectParent(parentNode, node);
        dispatchVisualEditorChanges();
        onNodeRemove?.();
      }
    }
  }, [
    dispatchVisualEditorChanges,
    isRoot,
    node,
    onNodeRemove,
    parentNode,
    removeNode,
    setIsPropertyPanelOpen,
  ]);

  useEffect(() => {
    setActiveCategory((prevCategory) => {
      if (!isObjectNodeSchema()) {
        return "Settings";
      }

      // FIXME: updating property values are triggering this hook
      if (subSchemas[prevCategory]) {
        return prevCategory;
      }

      if (nodeSchema) {
        // Prefer Settings > Styles > Actions
        if (subSchemas["Settings"]) {
          return "Settings";
        } else if (subSchemas["Styles"]) {
          return "Styles";
        } else if (subSchemas["Actions"]) {
          return "Actions";
        }
      }
      return prevCategory;
    });
  }, [isObjectNodeSchema, nodeSchema, subSchemas]);

  useEffect(() => {
    if (nodeKey === "Widget") {
      // setIsUsage(true);
    }
  }, [nodeKey]);

  const updateWidgetId = (newWidgetId: string) => {
    if (newWidgetId !== widgetId) {
      if (newWidgetId.trim() === "") {
        setWidgetId(null);
      } else {
        setWidgetId(newWidgetId);
      }
      const changed = updateNodeId(node, newWidgetId);
      if (changed) {
        updateNode(node);
      }
    }
  };

  // TODO: switching tabs will change the form and cause hasFormChanges=true (PropertySetForm.tsx)
  //  when we later switch to another tree node. Not a big deal since but it'll run
  //  the form validation even when we did not change anything, and cause the Discard Changes dialog to appear
  // const formData = useMemo(() => {
  //   const formData = getFormDataValues(node, nodeKey, activeCategory);
  //   if (formData) {
  //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //     const { id, ...baseFormData } = getFormDataValues(
  //       node,
  //       nodeKey,
  //       activeCategory,
  //     );
  //     // careful here. Undefined widgetId just mean we haven't done anything.
  //     // only null means we want to remove the id
  //     if (widgetId === null) {
  //       return baseFormData;
  //     } else {
  //       return {
  //         ...baseFormData,
  //         ...(widgetId ? { id: widgetId } : {}),
  //       };
  //     }
  //   }
  // }, [activeCategory, node, nodeKey]);

  const extractedApiNames = useMemo(() => {
    const apiNames = new Set<string>();

    if (doc?.contents instanceof YAMLMap) {
      doc.contents.items.forEach((item) => {
        if (isPair(item) && isScalar(item.key) && item.key.value === "API") {
          const value = item.value as YAMLMap;
          value.items.forEach((api) => {
            if (isPair(api) && isScalar(api.key)) {
              apiNames.add(api.key.value as string);
            }
          });
        }
      });
    }

    return apiNames;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doc?.contents, nodeKey]);

  const schema = subSchemas[activeCategory];
  if (!isPropertyPanelOpen || !schema || !nodeKey) {
    return null;
  }

  const canBeRenamed =
    (customWidgets.has(nodeKey) && nodeKey !== "Widget") ||
    extractedApiNames.has(nodeKey);

  const actionMenuItems: ActionMenuItem[] = [
    ...(canBeRenamed
      ? []
      : [
          {
            iconName: "file-copy-2-line",
            label: "Duplicate",
            value: "duplicate",
          },
          {
            iconName: "file-copy-line",
            label: "Copy",
            value: "copy",
          },
          ...(copiedNode
            ? [
                {
                  iconName: "clipboard-line",
                  label: "Paste",
                  value: "paste",
                },
              ]
            : []),
        ]),
    {
      iconName: "delete-bin-2-line",
      label: "Delete",
      value: "delete",
    },
    ...(containerWidgets.includes(nodeKey)
      ? containerWidgets
          .filter((widget) => widget !== nodeKey)
          .map((widget) => ({
            iconName: "arrow-go-forward-line",
            label: `Convert to ${widget}`,
            value: `${CONVERT_PREFIX}${widget}`,
          }))
      : []),
  ];

  const changeWidget = (widget: string) => {
    if (widget !== nodeKey && widget !== "") {
      if (isPair(node) && isScalar(node.key)) {
        node.key.value = widget;
        updateNode(node);
      }
    }
  };

  return (
    <div className="widget-property-panel">
      <div className="widget-property-panel-content">
        <div className="widget-property-panel-header">
          <div className={"widget-property-panel-header-title"}>
            {/*<input*/}
            {/*  type="text"*/}
            {/*  ref={nameInputRef}*/}
            {/*  // disabled={isUsage}*/}
            {/*  disabled={true}*/}
            {/*  defaultValue={nodeKey}*/}
            {/*/>*/}
            {canBeRenamed ? (
              <EditableText
                initialValue={nodeKey}
                validInputPattern={/^[A-Z_][A-Z0-9_]*$/i}
                readonlyClassName={"widget-name-text"}
                placeholderClassName={"widget-name-placeholder"}
                placeholder={
                  <span className={"widget-name-placeholder"}>
                    <RemixIcon name={"pencil-line"} />
                  </span>
                }
                showPlaceholder={true}
                onSubmit={(value) => {
                  if (value !== nodeKey && value !== "") {
                    changeWidget(value);
                  }
                }}
              />
            ) : (
              <span>{nodeKey}</span>
            )}

            {isWidgetSchemaWithId && (
              <EditableText
                initialValue={widgetId ?? undefined}
                validInputPattern={/^[A-Z_][A-Z0-9_]*$/i}
                readonlyClassName={"widget-set-id-text"}
                placeholder={
                  <span className={"widget-set-id-placeholder"}>
                    <RemixIcon name={"pencil-line"} /> Set widget ID
                  </span>
                }
                onSubmit={(value) => {
                  updateWidgetId(value);
                }}
              />
            )}
          </div>

          {nodeKey !== "View" && nodeKey !== "Widget" && (
            <ActionMenu
              anchor={
                <div className={"action-icon"}>
                  <RemixIcon name={"more-2-fill"} />
                </div>
              }
              items={actionMenuItems}
              onSelect={(value) => {
                if (value === "delete") {
                  onRemove();
                } else if (value.startsWith(CONVERT_PREFIX)) {
                  const widget = value.replace(CONVERT_PREFIX, "");
                  changeWidget(widget);
                } else if (value === "duplicate") {
                  if (parentNode) {
                    const parent =
                      parentNode.get("children") ??
                      getNodeRef(parentNode, selectedNode).parent;

                    setCopiedNode(node);
                    pasteNode(parent as YAMLDocNode, node);
                  }
                } else if (value === "copy") {
                  setCopiedNode(node);
                } else if (value === "paste") {
                  if (parentNode) {
                    const ref = getNodeRef(parentNode, selectedNode);
                    pasteNode(ref.parent, ref.sibling as YAMLDocNode);
                  }
                }
              }}
            />
          )}
        </div>
        <div className="category-selector">
          {subSchemas["Settings"] && (
            <button
              className={`category ${
                activeCategory === "Settings" ? "active-category" : ""
              }`}
              onClick={() => setActiveCategory("Settings")}
            >
              Settings
            </button>
          )}
          {subSchemas["Styles"] && (
            <button
              className={`category ${
                activeCategory === "Styles" ? "active-category" : ""
              }`}
              onClick={() => setActiveCategory("Styles")}
            >
              Styles
            </button>
          )}
          {subSchemas["Actions"] && (
            <button
              className={`category ${
                activeCategory === "Actions" ? "active-category" : ""
              }`}
              onClick={() => setActiveCategory("Actions")}
            >
              Actions
            </button>
          )}
        </div>
        <PropertySetForm
          key={formKey}
          schema={schema}
          uiSchema={uiSchema}
          // formData={formData}
          formData={getFormDataValues(node, nodeKey, activeCategory)}
          formContext={{
            doc,
            node,
            activeCategory,
            platformWidgets: schemaProps?.widgets,
            customWidgets: Array.from(customWidgets).map((widget) => ({
              key: widget,
            })),
            appData: app,
            addNode,
            setSelectedNode,
            removeNode,
            updateNode,
          }}
          onFormSubmit={onSubmit}
        />
      </div>
    </div>
  );
};
