import { useEffect, useState } from "react";
import { Pair, Scalar, YAMLMap } from "yaml";
import { TreeNode } from "../containers/NavigatorPanel/TreeNode";
import { TreeStore } from "./useYamlDoc";

const getNodeValue = (node: TreeNode): string | undefined => {
  const nodeValue: YAMLMap = node.ref.node.value as YAMLMap;
  const items = nodeValue.items as Pair[];

  if (!items || items.length === 0) return undefined;

  return items
    .map((item) => {
      const key = item.key as Scalar;
      if (key.value !== "styles" && key.value !== "onTap") {
        return (item.value as Scalar).value;
      }
      return undefined;
    })
    .filter((value) => value !== undefined)
    .join(", ");
};

export const useTreeSearch = (
  treeData: TreeStore | null,
  searchTerm: string,
): TreeStore | null => {
  const [filteredTreeData, setFilteredTreeData] = useState<TreeStore | null>(
    null,
  );

  useEffect(() => {
    if (!searchTerm) {
      setFilteredTreeData(treeData);
      return;
    }

    const filterTreeData = (data: TreeNode[] | null): TreeNode[] | null => {
      if (!data) return null;

      const filteredNodes = data.reduce((acc: TreeNode[], node) => {
        const nodeValue = getNodeValue(node);
        const titleIncludesSearchTerm =
          typeof node.title === "string" &&
          node.title.toLowerCase().includes(searchTerm.toLowerCase());
        const valueIncludesSearchTerm = nodeValue
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase());

        if (titleIncludesSearchTerm || valueIncludesSearchTerm) {
          acc.push(node);
        }

        if (node.children) {
          const filteredChildren = filterTreeData(node.children as TreeNode[]);
          if (filteredChildren && filteredChildren.length > 0) {
            acc.push({ ...node, children: filteredChildren });
          }
        }

        return acc;
      }, []);

      return filteredNodes.length > 0 ? filteredNodes : null;
    };

    const filteredData: TreeStore = {
      root: filterTreeData(treeData?.root ?? []),
      body: filterTreeData(treeData?.body ?? []),
      customWidgets: filterTreeData(treeData?.customWidgets ?? []),
      api: filterTreeData(treeData?.api ?? []),
      codeBlock: filterTreeData(treeData?.codeBlock ?? []),
      widgetList: treeData?.widgetList || [],
      customWidgetList: treeData?.customWidgetList || [],
      viewGroup: treeData?.viewGroup || [],
      header: treeData?.header ?? [],
      footer: treeData?.footer ?? [],
    };

    setFilteredTreeData(filteredData);
  }, [searchTerm, treeData]);

  return filteredTreeData;
};
