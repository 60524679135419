import React from "react";
import CustomIcon from "../assets/widget/custom.svg";

// Return a icon that maps to the widget name
export const WidgetIcon: React.FC<{ iconName: string; size?: number }> = ({
  iconName,
  size,
}) => {
  const sanitizeString = (string: string) => {
    return string
      .replace(/[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "") // eslint-disable-line
      .trim();
  };

  const iconSize = size || 16;
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const source = require(`../assets/widget/${sanitizeString(
      iconName,
    ).toLowerCase()}.svg`);
    return <img alt={"icon"} src={source} width={iconSize} height={iconSize} />;
  } catch (e) {
    return (
      <img alt={"icon"} src={CustomIcon} width={iconSize} height={iconSize} />
    );
  }
};
