import React from "react";
import { FieldProps } from "@rjsf/utils";
import { StringField } from "./StringField";
import "./AssetPickerField.sass";
import { RemixIcon } from "../../../components/Widgets";
import { Modal } from "antd";
import { useAppContext } from "../../../pages/AppPagesWrapper";
import { AssetDropZone, useAssetUpload } from "../../AssetDropZone";
import { uploadAssets } from "../../../utils/uploadUtils";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import assets_empty_state from "../../../assets/assets_empty_state.png";
import { SelectableAssetCard } from "../../../components/AppAssets/SelectableAssetCard";
import { EnsembleAssetData } from "../../../config/interfaces";

export const AssetPickerField: React.FC<FieldProps> = (props) => {
  const { formData, formContext, onChange } = props;
  const [isAssetDialogOpen, setIsAssetDialogOpen] =
    React.useState<boolean>(false);
  const [useAsset, setUseAsset] = React.useState<boolean>(
    hasAssetSyntax(formData),
  );
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [uploadingText, setUploadingText] =
    React.useState<string>("Uploading...");
  const { app } = useAppContext();
  const queryClient = useQueryClient();

  // remove asset
  const handleRemoveAsset = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    onChange(undefined);
    setUseAsset(false);
    formContext.submitFormLater();
  };

  // pick assets to upload
  const handlePickAssets = async (files: File[]) => {
    setIsUploading(true);
    const totalCount = files.length;
    let uploadedCount = 0;

    setUploadingText(`0 out of ${totalCount} files uploaded...`);
    try {
      const uploadPromises = await uploadAssets(app.id, files);
      await Promise.all(
        uploadPromises.map((promise) =>
          promise.then(() => {
            uploadedCount += 1;
            setUploadingText(
              `${uploadedCount} out of ${totalCount} files uploaded...`,
            );
          }),
        ),
      );

      toast.success(`Assets uploaded successfully.`, {
        position: "top-right",
        type: toast.TYPE.SUCCESS,
        theme: "dark",
      });
      await queryClient.invalidateQueries(["app", app.id]);
    } catch (error) {
      toast.error(`Failed to upload assets.`, {
        position: "top-right",
        type: toast.TYPE.ERROR,
        theme: "dark",
      });
    } finally {
      setIsUploading(false);
      setUploadingText("Uploading...");
    }
  };

  // select a asset
  const handleAssetSelect = (selectedAsset: EnsembleAssetData) => {
    onChange(selectedAsset.copyText);
    setUseAsset(true);
    setIsAssetDialogOpen(false);
    formContext.submitFormLater();
  };

  return (
    <div>
      {!useAsset ? (
        <StringField
          {...props}
          icon={<RemixIcon name={"image-2-line"} />}
          iconLabel={"Select asset"}
          onIconClick={() => setIsAssetDialogOpen(true)}
        />
      ) : (
        <div
          className={"asset-picker-selected"}
          onClick={() => setIsAssetDialogOpen(true)}
        >
          <span className={"asset-picker-selected-tag"}>
            <span>{getSelectedAssetName(formData)}</span>
            <span onClick={handleRemoveAsset}>
              <RemixIcon name={"close-line"} />
            </span>
          </span>
        </div>
      )}

      <Modal
        className={"modal-container asset-picker-dialog"}
        title={"Select an asset"}
        open={isAssetDialogOpen}
        onCancel={() => setIsAssetDialogOpen(false)}
        footer={null}
      >
        <AssetDropZone
          onPick={handlePickAssets}
          isUploading={isUploading}
          uploadingText={uploadingText}
        >
          <div className="screen-content">
            <div className="screen-cards-ct">
              <AddAssetCard />
              {app.assets?.map((asset) => (
                <SelectableAssetCard
                  asset={asset as EnsembleAssetData}
                  key={asset.id}
                  selected={asset.copyText === formData}
                  onSelect={handleAssetSelect}
                />
              ))}
            </div>
          </div>
        </AssetDropZone>
      </Modal>
    </div>
  );
};

const AddAssetCard = () => {
  const { pickFiles } = useAssetUpload();
  return (
    <div className="screen-card assets add-asset" onClick={() => pickFiles()}>
      <img src={assets_empty_state} alt="empty assets" />
      <span>Click to upload</span>
    </div>
  );
};

const assetRegex = /^\${env\.assets}\${env\.(.*)}$/;
const hasAssetSyntax = (value: string | undefined) => {
  if (value) {
    return assetRegex.test(value);
  }
  return false;
};

const getSelectedAssetName = (value: string) => {
  const match = value?.match(assetRegex);
  if (match) {
    return match[1];
  }
  return "";
};
