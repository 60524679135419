import { capitalize } from "lodash-es";
import { Link, useMatch } from "react-router-dom";
import closeIcon from "../../assets/icon_close.svg";
import { AppRoutePattern } from "../../utils/constants";
import { ReactComponent as IconHome } from "../../assets/icon_home.svg";
import { ReactComponent as IconNext } from "../../assets/icon_next.svg";

interface LabelHeaderProps {
  appId: string;
  appName: string;
  onClick: () => void;
}

const LabelHeader = ({ appId, appName, onClick }: LabelHeaderProps) => {
  const pageName = useMatch(AppRoutePattern)?.params.page ?? "screens";

  const renderBreadcrumb = (label: string, isLink: boolean, to: string) =>
    isLink ? (
      <Link to={to}>{label}</Link>
    ) : (
      <span className="breadcrumbs__link-label" onClick={onClick}>
        {label}
      </span>
    );

  return (
    <div className="editor-header">
      <div className="breadcrumbs">
        <button
          style={{ background: "transparent", cursor: "pointer" }}
          onClick={onClick}
          aria-label="Home"
          title="Home"
        >
          <IconHome className="nav-icon" />
        </button>
        <IconNext className="arrow-icon" />
        {renderBreadcrumb(
          appName,
          pageName !== "screens",
          `/app/${appId}/screens`,
        )}
        <IconNext className="arrow-icon" />
        <span className="breadcrumbs__link-label" onClick={onClick}>
          {capitalize(pageName)}
        </span>
        <IconNext className="arrow-icon" />
        <span className="breadcrumbs__label">Labels</span>
      </div>
      <button
        className="button__icon"
        onClick={onClick}
        aria-label="Close"
        title="Close"
      >
        <img src={closeIcon} alt="Close" />
      </button>
    </div>
  );
};

export default LabelHeader;
