import { getLabelRef } from "../../hooks/useAPIs";

export const handleLabelingCard = (
  labelId: string,
  mutate: (data: { labelGroup: any }) => void,
  artifactId?: string,
) => {
  // Pass null instead of reference if you want to remove the label
  const labelGroup = labelId ? getLabelRef(labelId) : null;

  if (labelGroup?.id !== artifactId) mutate({ labelGroup: labelGroup });
};
