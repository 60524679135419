import React, { useState } from "react";
import { Menu, MenuItem, ClickEvent } from "@szhsin/react-menu";

import "@szhsin/react-menu/dist/index.css";

// Components
import { ReactComponent as IconMenu } from "../../assets/icon_menu.svg";
import AssetArchive from "./AssetArchive";

// Interfaces
import { EnsembleAssetData, EnsembleFontData } from "../../config/interfaces";

interface AssetsActionsMenuProps {
  asset: EnsembleAssetData | EnsembleFontData;
  isReadOnly: boolean;
  isTemplate: boolean;
  assetType: string;
}

const AssetsActionsMenu: React.FC<AssetsActionsMenuProps> = ({
  asset,
  isReadOnly,
  assetType,
}) => {
  const [archiveTrigger, setArchiveTrigger] = useState<boolean>(false);

  const handleCopyPath = () => {
    navigator.clipboard.writeText((asset as EnsembleAssetData).copyText);
  };
  const handleArchive = (event: ClickEvent) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();
    setArchiveTrigger(true);
  };

  const handleCopyFontFamily = () => {
    const fontName = asset.name.split(".")[0];
    navigator.clipboard.writeText(
      (asset as EnsembleFontData).fontFamily ?? fontName,
    );
  };

  return (
    <>
      <Menu
        menuButton={
          <button
            className="actions-menu-button"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <IconMenu />
          </button>
        }
        align="end"
        position="auto"
        direction="bottom"
        offsetY={8}
        menuClassName="actions-menu"
      >
        {!isReadOnly && (
          <>
            {assetType === "font" ? (
              <MenuItem onClick={handleCopyFontFamily}>
                Copy font family name
              </MenuItem>
            ) : (
              <MenuItem onClick={handleCopyPath}>Copy path</MenuItem>
            )}
            <MenuItem
              className="actions-menu__delete-item"
              onClick={handleArchive}
            >
              Archive
            </MenuItem>
          </>
        )}
      </Menu>

      <AssetArchive
        asset={asset}
        displayArchiveModal={archiveTrigger}
        setArchiveTrigger={setArchiveTrigger}
      />
    </>
  );
};

export default AssetsActionsMenu;
