import { createArrayOfNumbers } from "../../utils";
import { Collapse, type CollapseProps } from "antd";
import { useEffect, useState, type FC } from "react";
import type {
  GroupedListItem,
  EnsembleScreenData,
  EnsembleWidgetData,
} from "../../config/interfaces";

interface CardCollapseGroupProps {
  appId: string;
  cardName: string; // Card data prop name i.e. screen, widget and script
  isReadOnly: boolean;
  isExpanded: boolean;
  isCollapsed: boolean;
  CardComponent: FC<any>; // Pass the card component dynamically
  groupedList: GroupedListItem<EnsembleScreenData | EnsembleWidgetData>[];
  cardProps?: Record<string, any>; // Optional additional props to pass to the card
}

const CardCollapseGroup = ({
  appId,
  cardName,
  isReadOnly,
  isExpanded,
  isCollapsed,
  groupedList,
  CardComponent,
  cardProps = {},
}: CardCollapseGroupProps) => {
  const [activeKeys, setActiveKeys] = useState<number[]>([0]);

  // Non-labeled cards (key is null or empty)
  const NonLabeledCards = () =>
    groupedList
      .filter(({ key }) => !key)
      .map(({ value }) =>
        value.map((item) => (
          <CardComponent
            key={item.id}
            {...cardProps}
            appId={appId}
            isReadOnly={isReadOnly}
            {...{ [cardName]: item }} // Pass the item as a dynamic prop
          />
        )),
      );

  const items: CollapseProps["items"] = groupedList
    .filter(({ key }) => key)
    .map(({ key, value }, index) => ({
      key: index,
      label: `${key} (${value.length})`,
      children: (
        <div className="screen-cards-ct grouped">
          {value.map((item) => (
            <CardComponent
              key={(item as any).id}
              {...cardProps}
              appId={appId}
              isReadOnly={isReadOnly}
              {...{ [cardName]: item }}
            />
          ))}
        </div>
      ),
    }));

  // Handle expand and collapse logic
  useEffect(() => {
    if (isCollapsed) setActiveKeys([]);
    else if (isExpanded)
      setActiveKeys(createArrayOfNumbers(groupedList.length - 1));
    else setActiveKeys([0]);
  }, [groupedList.length, isCollapsed, isExpanded]);

  return (
    <>
      <div className="screen-cards-ct non-labeled">
        <NonLabeledCards />
      </div>
      <Collapse
        ghost
        items={items}
        activeKey={activeKeys}
        rootClassName="collapse-group"
        onChange={(keys) => setActiveKeys(keys.map(Number))}
      />
    </>
  );
};

export default CardCollapseGroup;
