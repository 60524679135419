import React, { useEffect, useContext, ReactNode } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { auth } from "../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { AuthContext } from "../App";
import {
  getUserId,
  getQuestionnaireStatus,
  getUserSubscriptionPlan,
  useGetSubscriptionPlans,
} from "../hooks/useAPIs";
import { User } from "firebase/auth";
import { createSession } from "../hooks/useFirebase";
import { IUserSubscriptionPlan } from "../config/interfaces";
import { useEnsembleUser } from "@ensembleui/react-framework";

interface IAuthRouteProps {
  children: ReactNode;
}

const AuthRoute: React.FunctionComponent<IAuthRouteProps> = ({ children }) => {
  const user = useEnsembleUser();
  const navigate = useNavigate();
  const prevLocation = useLocation();
  const subscriptionPlansData = useGetSubscriptionPlans();
  const { currentUser, setCurrentUser } = useContext(AuthContext);

  const [authedUser, loading] = useAuthState(auth);

  useEffect(() => {
    const setUserPlan = async () => {
      if (
        currentUser?.id &&
        subscriptionPlansData.data &&
        !currentUser?.subscriptionPlan
      ) {
        getUserSubscriptionPlan(
          currentUser.id,
          (userPlan: IUserSubscriptionPlan) => {
            const planDetails = subscriptionPlansData.data.find(
              (plan) => plan.stripeProductId === userPlan?.subscriptionPlanId,
            );
            if (planDetails)
              setCurrentUser({
                ...currentUser,
                subscriptionPlan: {
                  ...userPlan,
                  planDetails,
                },
              });
          },
        );
      }
    };
    setUserPlan();
  }, [subscriptionPlansData.data, currentUser, setCurrentUser]);

  function authenticateExistingUser(authedUser: User) {
    // console.log("Authenticating existing users. User is " + currentUser);
    // get the userId via custom claim
    getUserId().then((userId) => {
      // this user doesn't have claims propagate to the client yet.
      // We'll kick him out. Upon re-login the claim will be there.
      if (!userId) {
        navigateToSignIn();
      } else {
        getQuestionnaireStatus(userId).then((questionnaireCompleted) => {
          updateCurrentUser(authedUser, userId, questionnaireCompleted);
          authedUser.getIdToken().then((token) => {
            user.set({
              accessToken: token,
              userId,
              doLogout: false,
            });
          });
        });
      }
    });
    return <></>;
  }

  // update the current user once we successfully authenticated and validated
  function updateCurrentUser(
    authedUser: User,
    userId: string,
    questionnaireCompleted?: boolean,
  ) {
    setCurrentUser({
      id: userId,
      name: authedUser.displayName,
      email: authedUser.email,
      avatar: authedUser.photoURL,
      questionnaireAttempted: questionnaireCompleted ?? false,
    });
  }

  const createUserSession = async (uid: string) => {
    const userAgent = window.navigator.userAgent;
    const sessionId = await createSession(uid, userAgent);
    localStorage.setItem("sessionId", sessionId);
  };

  useEffect(() => {
    const sessionId = localStorage.getItem("sessionId");
    if (!sessionId && currentUser?.id) {
      createUserSession(currentUser?.id ? currentUser?.id : "");
    }
  }, [currentUser?.id]);

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(async (loggedInUser) => {
      if (loggedInUser) {
        const token = await loggedInUser.getIdToken();
        user.set({
          accessToken: token,
          userId: loggedInUser.uid,
          doLogout: false,
        });
      }
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function navigateToSignIn() {
    navigate(`/sign-in?redirectTo=${prevLocation.pathname}`);
  }

  if (loading) return <></>;

  if (!authedUser) {
    navigateToSignIn();
  } else {
    if (!currentUser) {
      return authenticateExistingUser(authedUser);
    }
    return <div>{children}</div>;
  }
  return <></>;
};

export default AuthRoute;
