import React, {
  FormEvent,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from "react";
import Dropdown from "../Dropdown";
import { IDropdownItem } from "../../config/interfaces";
import { Upload } from "../Upload/Upload";
import { toast } from "react-toastify";

enum FontStyles {
  NORMAL = "Normal",
  ITALIC = "Italic",
}
enum FontWeights {
  THIN = "100 (thin)",
  EXTRA_LIGHT = "200 (extra-light)",
  LIGHT = "300 (light)",
  NORMAL = "400 (normal)",
  MEDIUM = "500 (medium)",
  SEMI_BOLD = "600 (semi-bold)",
  BOLD = "700 (bold)",
  EXTRA_BOLD = "800 (extra-bold)",
  BLACK = "900 (black)",
}

enum FontTypes {
  Text = "Text font",
  ICON = "Icon font",
}

export const ALLOWED_FONT_TYPES = [".ttf", ".otf"];

enum FontData {
  fontFamily = "fontFamily",
  fontStyle = "fontStyle",
  fontWeight = "fontWeight",
  fontType = "fontType",
}

interface FontModalContentProps {
  appId: string;
  isUploading: boolean;
  onUploadFont: (
    fileData: File[],
    fontFamily: string,
    fontWeight: number,
    fontStyle: string,
    fontType: string,
  ) => void;
  file: File;
  fontFamilyList: string[];
}
const FontModalContent: React.FC<FontModalContentProps> = ({
  appId,
  isUploading,
  onUploadFont,
  file,
  fontFamilyList,
}) => {
  const [fontData, setFontData] = useState({
    fontFamily: "",
    fontStyle: FontStyles.NORMAL,
    fontWeight: FontWeights.NORMAL,
    fontType: FontTypes.Text,
  });
  const [fileData, setFileData] = useState<File[] | null>(null);
  const { fontFamily, fontStyle, fontWeight, fontType } = fontData;

  const handleFontDataChange = useCallback((key: string, value: string) => {
    setFontData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  }, []);

  const createDropdownItems = useCallback(
    (items: Record<string, string>, key: string) =>
      Object.values(items).map((value) => ({
        id: value,
        text: value,
        clickHandler: () => handleFontDataChange(key, value),
      })),
    [handleFontDataChange],
  );

  const fontStylesList: IDropdownItem[] = useMemo(
    () => createDropdownItems(FontStyles, FontData.fontStyle),
    [createDropdownItems],
  );
  const fontWeightsList: IDropdownItem[] = useMemo(
    () => createDropdownItems(FontWeights, FontData.fontWeight),
    [createDropdownItems],
  );
  const fontTypesList: IDropdownItem[] = useMemo(
    () => createDropdownItems(FontTypes, FontData.fontType),
    [createDropdownItems],
  );

  const onSelectFile = useCallback(
    (item: File[]) => {
      if (item.length === 0) return;
      const fileExtension = item[0].name.split(".");
      if (fileExtension[1] !== "ttf" && fileExtension[1] !== "otf") {
        toast.error("Only .ttf & .otf files are supported", {
          position: "top-right",
          type: toast.TYPE.ERROR,
          theme: "dark",
        });
        return;
      }
      setFileData(item);
      handleFontDataChange(FontData.fontFamily, fileExtension[0]);
    },
    [handleFontDataChange],
  );

  const clearFileData = () => {
    setFileData(null);
  };

  const onAddFont = (event: FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const weight = parseInt(fontWeight.split(" ")[0], 10);
    onUploadFont(fileData!, fontFamily, weight, fontStyle, fontType);
  };

  useEffect(() => {
    if (file) {
      onSelectFile([file]);
    }
  }, [file, onSelectFile]);

  return (
    <form className="upload-font" onSubmit={onAddFont}>
      <div className="upload-font__wrapper">
        <label htmlFor="font-upload">Font family</label>
        <Dropdown
          items={fontFamilyList.map((font) => ({
            id: font,
            text: font,
            clickHandler: () => handleFontDataChange(FontData.fontFamily, font),
          }))}
          activatorText={fontFamily}
          wrapperClass="font-dropdown"
          className="font-input font-dropdown__button"
          searchable
          onSearch={(searchQuery) => {
            handleFontDataChange(FontData.fontFamily, searchQuery);
          }}
        />
      </div>
      <div className="upload-font__wrapper">
        <label htmlFor="font-style">Style</label>
        <Dropdown
          items={fontStylesList}
          activatorText={fontStyle}
          wrapperClass="font-dropdown"
          className="font-input font-dropdown__button"
        />
      </div>
      <div className="upload-font__wrapper">
        <label htmlFor="font-weight">Weight</label>
        <Dropdown
          items={fontWeightsList}
          activatorText={fontWeight}
          wrapperClass="font-dropdown"
          className="font-input font-dropdown__button"
        />
      </div>
      <div className="upload-font__wrapper">
        <label htmlFor="font-type">Type</label>
        <Dropdown
          items={fontTypesList}
          activatorText={fontType}
          wrapperClass="font-dropdown"
          className="font-input font-dropdown__button"
        />
      </div>
      {fileData ? (
        <div className="upload-font__file">
          <label>File</label>
          <div>{fileData[0].name}</div>
          <span onClick={clearFileData} role="button">
            Replace file
          </span>
        </div>
      ) : (
        <Upload
          key={`upload-${appId}`}
          displayText="Drop font file (.TTF or .OTF) here or click to select from file system"
          onDrop={onSelectFile}
          isUploading={isUploading}
          fileTypes={ALLOWED_FONT_TYPES}
          uploadType="font"
        />
      )}
      <button
        className="button__primary"
        disabled={!(fontFamily && fileData) || isUploading}
        type="submit"
      >
        {isUploading ? "Uploading..." : "Add font"}
      </button>
    </form>
  );
};

export default FontModalContent;
