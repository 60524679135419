import React, { ChangeEvent, ReactNode, useEffect, useState } from "react";
import "./EditableText.sass";

interface EditableTextProps {
  initialValue?: string;
  placeholder?: ReactNode;
  placeholderClassName?: string;
  readonlyClassName?: string;
  validInputPattern?: RegExp;
  onSubmit?: (value: string) => void;
  showPlaceholder?: boolean;
}

export const EditableText: React.FC<EditableTextProps> = ({
  initialValue = "",
  placeholder,
  readonlyClassName,
  placeholderClassName,
  validInputPattern,
  onSubmit,
  showPlaceholder = false,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState<string>(initialValue);

  useEffect(() => {
    if (!isEditing) setValue(initialValue);
  }, [initialValue, isEditing]);

  const handleFocus = () => setIsEditing(true);

  const handleBlur = () => {
    setIsEditing(false);
    if (onSubmit) {
      onSubmit(value);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      (e.target as HTMLInputElement).blur();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (
      newValue === "" ||
      !validInputPattern ||
      validInputPattern.test(newValue)
    ) {
      setValue(newValue);
    } else {
      // revert value
      setValue(value);
    }
  };

  return (
    <div
      className={`editable-text ${isEditing ? "editable-text-editing" : ""}`}
      onClick={handleFocus}
    >
      {isEditing ? (
        <input
          type="text"
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          autoFocus
        />
      ) : value ? (
        <div className={readonlyClassName}>{value}</div>
      ) : (
        <div className={placeholderClassName}>
          {placeholder || "Click to edit"}
        </div>
      )}
      {!isEditing && showPlaceholder && (
        <div className={placeholderClassName}>
          {placeholder || "Click to edit"}
        </div>
      )}
    </div>
  );
};
