import Modal from "../Modal";
import { useModal } from "../../hooks/useModal";
import { Dispatch, SetStateAction } from "react";
import LabelManager from "../../containers/label/LabelManager";

interface LabelManagerModalProps {
  isModalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  headerText?: string; // Optional prop for dynamic header text
}

const LabelManagerModal = ({
  isModalOpen,
  setModalOpen,
  headerText = "Modal",
}: LabelManagerModalProps) => {
  const { toggleModal } = useModal();

  const onClose = () => {
    setModalOpen(false);
    toggleModal();
  };

  return (
    <Modal
      onHide={onClose}
      cancellable={false}
      headerText={headerText}
      isModalDisplayed={isModalOpen}
      modalContent={<LabelManager onClose={onClose} onSubmit={onClose} />}
    />
  );
};

export default LabelManagerModal;
