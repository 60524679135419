import { uploadAsset } from "../hooks/useAPIs";

export const uploadAssets = async (
  appId: string,
  items: File[],
): Promise<Promise<void>[]> => {
  if (items.length === 0) return [];

  const uploadPromises = items.map(async (file) => {
    const base64 = await convertToBase64(file);
    await uploadAsset(appId, file.name, base64);
  });

  return uploadPromises;
};

export const convertToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () =>
      resolve(reader.result?.toString().split(",")[1] as string);
    reader.onerror = (error) => reject(error);
  });
};
