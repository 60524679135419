import { useYamlDoc, YAMLDocNode } from "./useYamlDoc";
import { generateUiSchema } from "../containers/WidgetPropertyPanel/utils/propertyPanelSchemaUtils";
import { isMap, Pair, parse } from "yaml";
import { useSchemas } from "./useSchemas";
import { getKeyValue } from "../utils/schemaUtils";
import { useCustomWidgets } from "./useCustomWidgets";
import { UiSchema } from "@rjsf/utils";
import { JSONSchema7 } from "json-schema";
import { useEffect, useMemo, useState } from "react";
import { path } from "ramda";
import { useAppContext } from "../pages/AppPagesWrapper";

export enum NodeType {
  Widget = "Widget",
  CustomWidgetDeclaration = "CustomWidgetDeclaration",
  CustomWidgetUsage = "CustomWidgetUsage",
}

interface UsePropertyPanelData {
  nodeKey?: string;
  nodeSchema?: JSONSchema7;
  uiSchema?: UiSchema;
  nodeType?: NodeType;
}

export const usePropertyPanel = (node: YAMLDocNode): UsePropertyPanelData => {
  const [nodeData, setNodeData] = useState<UsePropertyPanelData>({});
  const schemas = useSchemas();
  const { screenCustomWidgetNodes, customWidgets } = useCustomWidgets();
  const { doc } = useYamlDoc();
  const appContext = useAppContext();

  // build a mapping of custom widget names to their inputs
  const customWidgetInputs = useMemo<
    Record<string, string[] | undefined>
  >(() => {
    return Object.fromEntries(
      screenCustomWidgetNodes
        .map((node: YAMLDocNode) => {
          const name = getKeyValue(node);
          const inputs = path<Array<string>>([name!, "inputs"], node.toJSON());
          return [name, inputs];
        })
        .concat(
          appContext.app.internalWidgets
            ?.filter((value) => value.content)
            ?.map((value) => {
              try {
                const inputs = path<Array<string>>(
                  ["Widget", "inputs"],
                  parse(value.content!),
                );
                return [value.name, inputs];
              } catch (e) {
                console.error(
                  `Error parsing widget content (${value.name ?? "unknown"})`,
                  e,
                );
                return [value.name, []];
              }
            }) ?? [],
        ),
    );
  }, [appContext.app.internalWidgets, screenCustomWidgetNodes]);

  // generate the property panel data from the selected node
  useEffect(() => {
    const nodeKey = getKeyValue(node);
    if (!nodeKey) return;

    // handle regular widgets
    const widgetSchema = schemas.findWidgetSchema(nodeKey);
    if (widgetSchema) {
      const uiSchema = generateUiSchema(widgetSchema, true);
      if (widgetSchema.type !== "object") {
        uiSchema["ui:widget"] = "textarea";
      }
      setNodeData({
        nodeKey,
        nodeSchema: widgetSchema,
        uiSchema,
        nodeType: NodeType.Widget,
      });
    }
    // custom widgets
    else if (customWidgets.has(nodeKey)) {
      const isDeclaration =
        isMap(doc?.contents) && doc?.contents.items.includes(node as Pair);
      if (isDeclaration) {
        const customWidgetDeclarationSchema =
          schemas.customWidgetDeclarationSchema;
        setNodeData({
          nodeKey,
          nodeSchema: customWidgetDeclarationSchema,
          uiSchema: customWidgetDeclarationSchema
            ? generateUiSchema(customWidgetDeclarationSchema, true)
            : undefined,
          nodeType: NodeType.CustomWidgetDeclaration,
        });
      } else {
        // Custom widget usage
        setNodeData({
          nodeKey,
          nodeSchema: schemas.customWidgetUsageSchema,
          uiSchema: generateUiSchema(schemas.customWidgetUsageSchema!, true),
          nodeType: NodeType.CustomWidgetUsage,
        });
      }
    } else if (doc?.hasIn(["API", nodeKey])) {
      const apiSchema = schemas.apiSchema;
      if (apiSchema) {
        setNodeData({
          nodeKey,
          nodeSchema: apiSchema,
          uiSchema: generateUiSchema(apiSchema, true),
        });
      }
    }
  }, [node, customWidgetInputs, customWidgets, doc, schemas]);

  return nodeData;
};
