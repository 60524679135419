import React, { useState, useEffect, ChangeEvent, KeyboardEvent } from "react";
import "./PaddingMarginField.sass";
import { FieldProps } from "@rjsf/utils";
import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { parseInputFormData } from "../utils/propertyPanelUtils";

interface PaddingValues {
  top: number;
  right: number;
  bottom: number;
  left: number;
  lock: boolean;
}

const PaddingMarginField: React.FC<FieldProps<string>> = ({
  formData,
  onChange,
  formContext,
}) => {
  const initialValues = parseInputFormData(String(formData));
  const [values, setValues] = useState<PaddingValues>(initialValues);
  const [hasPendingChanges, setHasPendingChanges] = useState(false);

  useEffect(() => {
    if (values.lock) {
      setValues({
        top: values.top,
        right: values.top,
        bottom: values.top,
        left: values.top,
        lock: true,
      });
    } else {
      setValues(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const handleChange = (
    field: keyof Omit<PaddingValues, "lock">,
    value: string | number,
  ) => {
    const newValue =
      typeof value === "string" && value.trim() === ""
        ? 0
        : parseFloat(value as string);
    const newValues: PaddingValues = { ...values, [field]: newValue };

    if (values.lock) {
      setValues({
        top: newValue,
        right: newValue,
        bottom: newValue,
        left: newValue,
        lock: true,
      });
    } else {
      setValues(newValues);
    }
  };

  const handleLockToggle = () => {
    if (!values.lock) {
      setValues({
        top: values.top,
        right: values.top,
        bottom: values.top,
        left: values.top,
        lock: true,
      });
      submitChange({
        top: values.top,
        right: values.top,
        bottom: values.top,
        left: values.top,
        lock: true,
      });
    } else {
      const newValues = { ...values, lock: false };
      setValues(newValues);
      submitChange(newValues);
    }
  };

  const submitChange = (newValues: PaddingValues) => {
    const { top, right, bottom, left } = newValues;
    onChange(
      top === right && top === bottom && top === left
        ? `${top}`
        : top === bottom && right === left
        ? `${top} ${right}`
        : `${top} ${right} ${bottom} ${left}`,
    );
    setHasPendingChanges(true);
  };

  const handleKeyDown = (
    event: KeyboardEvent<HTMLInputElement>,
    field: keyof Omit<PaddingValues, "lock">,
  ) => {
    if (event.key === "ArrowUp") {
      handleChange(field, values[field] + 1);
    } else if (event.key === "ArrowDown") {
      handleChange(field, values[field] - 1);
    } else if (event.key === "Enter") {
      if (values[field] !== initialValues[field]) {
        submitChange(values);
      }
    }
  };

  useEffect(() => {
    if (hasPendingChanges) {
      formContext.submitForm();
      setHasPendingChanges(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPendingChanges]);

  const handleBlur = (field: keyof Omit<PaddingValues, "lock">) => {
    if (values[field] !== initialValues[field]) {
      submitChange(values);
    }
  };

  const renderInputField = (
    title: string,
    field: keyof Omit<PaddingValues, "lock">,
    className: string,
  ) => (
    <Tooltip title={title}>
      <input
        type="text"
        value={values[field]}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleChange(field, e.target.value)
        }
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
          handleKeyDown(e, field)
        }
        className={`padding-input ${className}`}
        onBlur={() => handleBlur(field)}
      />
    </Tooltip>
  );

  return (
    <div className="padding-field">
      <div className="padding-box">
        {renderInputField("Top", "top", "top")}
        <div className="side-inputs">
          {renderInputField("Left", "left", "left")}
          <div className="controls">
            <label>
              <Tooltip title={values.lock ? "Unlock" : "Lock"}>
                {values.lock ? (
                  <LockOutlined
                    className="lockIcon"
                    onClick={handleLockToggle}
                  />
                ) : (
                  <UnlockOutlined
                    className="lockIcon"
                    onClick={handleLockToggle}
                  />
                )}
              </Tooltip>
            </label>
          </div>
          {renderInputField("Right", "right", "right")}
        </div>
        {renderInputField("Bottom", "bottom", "bottom")}
      </div>
    </div>
  );
};

export default PaddingMarginField;
