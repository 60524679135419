import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useGetInvite, verifyAppInvite } from "../hooks/useAPIs";
import { Id, toast } from "react-toastify";

const Invite: React.FunctionComponent = () => {
  const { ref } = useParams();
  const toastId = React.useRef<Id | undefined>();
  const navigate = useNavigate();
  const getInvite = useGetInvite(ref);

  const notify = () =>
    (toastId.current = toast.loading("Please wait..", {
      position: "top-right",
      type: toast.TYPE.INFO,
      theme: "dark",
      autoClose: false,
    }));

  const acceptInvite = async () => {
    if (ref) {
      try {
        notify();
        await verifyAppInvite(ref);
        toastId.current &&
          toast.update(toastId.current, {
            render: "Successfully added to collaborators.",
            type: toast.TYPE.SUCCESS,
            isLoading: false,
            autoClose: 4000,
          });
        navigate(`/app/${getInvite.data?.template.data.appId}/screens`);
      } catch (error: any) {
        toastId.current &&
          toast.update(toastId.current, {
            render: error.message ?? `An error occured`,
            type: toast.TYPE.ERROR,
            isLoading: false,
            autoClose: 4000,
          });
      }
    }
  };

  return (
    <div className="page-ct">
      <div className="invite-content">
        <h1>
          {getInvite.data?.template.data.userName} has invited you to{" "}
          {getInvite.data?.template.data.appName}
        </h1>
        <button className="button__primary" onClick={acceptInvite}>
          Accept
        </button>
      </div>
    </div>
  );
};

export default Invite;
