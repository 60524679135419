import { transform } from "@babel/standalone";
import { isMap, isSeq } from "yaml";
import Cookies from "js-cookie";
import { parseDocument } from "yaml";
import { showTranspileToast } from "./TranspileToast";

const transpileEs6 = (code: string, artifactId: string) => {
  try {
    const transformedCode = transform(code, {
      presets: ["env"],
      sourceType: "script",
      compact: false,
    }).code;

    if (
      (transformedCode || "").replace(/\s+/g, " ").trim() !==
      code.replace(/\s+/g, " ").trim()
    ) {
      if (Cookies.get("showTranspileToast") !== "false") {
        showTranspileToast(artifactId);
      }
      return transformedCode;
    }
    return code;
  } catch (e) {
    console.error(e);
    return code;
  }
};

export const transpileCode = (code: string, artifactId: string) => {
  const editorYaml = parseDocument(code);
  recursivelyTranspileCode(editorYaml.contents, artifactId);
  return editorYaml.toString();
};

export const recursivelyTranspileCode = (o: any, artifactId: string) => {
  for (const item of o.items) {
    const key = item.key.value;
    const value = o.get(key);
    if (key === "executeCode") {
      if (typeof value === "string")
        o.set(key, transpileEs6(value, artifactId));
      else if (
        isMap(value) &&
        value.get("body") &&
        typeof value.get("body") === "string"
      )
        value.set(
          "body",
          transpileEs6(value.get("body") as string, artifactId),
        );
    } else if (key === "Global" && typeof value === "string") {
      o.set(key, transpileEs6(value, artifactId));
    } else if (key.substring(0, 2) == "on" && typeof value === "string") {
      o.set(key, transpileEs6(value, artifactId));
    } else if (isMap(item.value)) {
      recursivelyTranspileCode(item.value, artifactId);
    } else if (isSeq(item.value)) {
      for (const v of item.value.items) {
        if (isMap(v) || isSeq(v)) {
          recursivelyTranspileCode(v, artifactId);
        }
      }
    }
  }
};
