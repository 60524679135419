import React from "react";
import { FieldProps, RJSFSchema } from "@rjsf/utils";
import { Tooltip } from "antd";
import { RemixIcon } from "../../../components/Widgets";
import SchemaField from "@rjsf/core/lib/components/fields/SchemaField";
import "./CustomSchemaField.sass";
import {
  canToggleExpression,
  hasExpression,
  isCompositeType,
} from "../utils/propertyPanelUtils";
import { DocTopic, ExternalDoc } from "../../../components/ExternalDoc";

export const CustomSchemaField: React.FC<FieldProps> = (props) => {
  const { schema, uiSchema, formData } = props;
  const containExpression =
    typeof formData === "string" && hasExpression(formData);
  const [useExpression, setUseExpression] = React.useState(containExpression);

  // some fields should not have expression and should be in their native UI mode
  if (!canToggleExpression(schema)) {
    if (isCompositeType(schema)) {
      return <SchemaField {...props} />;
    }
    return (
      <div className={"schema-field-content"}>
        <SchemaField {...props} />
        {/* empty placeholder for lining up with expression fields */}
        <div className={"schema-field-content-expression"} />
      </div>
    );
  }

  // Note that we feed new schema/uiSchema to render the component only.
  // On validation we still refer to the original schema/uiSchema.
  // See transformErrors() for details.
  const newSchema: RJSFSchema = !useExpression
    ? schema
    : {
        ...schema,
        type: "string",
      };
  const newUiSchema = useExpression
    ? {
        ...uiSchema,
        "ui:field": "ExpressionField",
      }
    : containExpression
    ? {
        ...uiSchema,
        "ui:field": "ClearExpressionField",
      }
    : uiSchema;

  return (
    <div className={"schema-field-content"}>
      <SchemaField {...props} schema={newSchema} uiSchema={newUiSchema} />
      <div className={"schema-field-content-expression"}>
        <Tooltip
          overlayClassName={"property-panel-tooltip"}
          title={
            <div>
              <div>
                {containExpression && !useExpression
                  ? "This field has an expression. Toggle to view."
                  : "Toggle expression"}
              </div>
              <ExternalDoc topicName={DocTopic.dataBinding} />
            </div>
          }
          placement={"topLeft"}
        >
          <span
            className={`schema-field-content-expression-button ${
              useExpression
                ? "schema-field-content-expression-button-active"
                : containExpression
                ? "schema-field-content-expression-button-has-expression"
                : ""
            }`}
            onClick={() => setUseExpression(!useExpression)}
          >
            <RemixIcon name={"formula"} />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};
