import React, { useMemo, useState } from "react";
import { FieldProps } from "@rjsf/utils";
import { ActionBuilderModal } from "./ActionBuilderModal";
import "./ActionBuilderField.sass";
import { FormRefProvider } from "../../../models/FormRefContext";
import { v4 as uuidv4 } from "uuid";
import { camelCaseToWords, getDataPreview } from "../utils/propertyPanelUtils";
import { useSchemas } from "../../../hooks/useSchemas";
import { RootWidgetContext } from "./RootWidgetContext";
import { useYamlDoc } from "../../../hooks/useYamlDoc";
import { ActionItemPicker } from "../../../components/VisualEditor/ItemPicker";
import { Scalar } from "yaml";
import { getNodeName } from "../../../utils/docUtils";
import { RemixIcon } from "../../../components/Widgets";
import { Tooltip } from "antd";

interface SelectedAction {
  actionName: string;
  actionPreview?: ActionPreview;
}

export interface ActionPreview {
  short?: string;
  long?: string;
}

export const ActionBuilderField: React.FC<FieldProps> = (props) => {
  const { formData, idSchema, formContext, onChange } = props;
  const { findActionSchema } = useSchemas();
  const { dispatchVisualEditorChanges } = useYamlDoc();
  const [isOpened, setIsOpened] = useState(false);
  const { doc } = useYamlDoc();

  // find out if a valid Action is selected
  const selectedAction = useMemo(() => {
    if (formData) {
      const actionName =
        typeof formData === "object" && Object.keys(formData).length > 0
          ? Object.keys(formData)[0]
          : String(formData);
      const actionSchema = findActionSchema(actionName);
      if (actionSchema) {
        return {
          actionName,
          actionPreview: getDataPreview(actionSchema, formData[actionName]),
        };
      }
    }
  }, [findActionSchema, formData]);

  const renderFieldContent = (selectedAction: SelectedAction) => {
    return (
      <div className={"action-builder-field-content"}>
        <div className={"action-builder-field-content__title"}>
          <span className={"action-builder-field-content__name"}>
            {camelCaseToWords(selectedAction.actionName)}
          </span>
          {selectedAction.actionPreview?.short && (
            <div className={"action-builder-field-content__short"}>
              {selectedAction.actionPreview.short}
            </div>
          )}
        </div>
        {selectedAction.actionPreview?.long && (
          <div className={"action-builder-field-content__long"}>
            {selectedAction.actionPreview.long}
          </div>
        )}
      </div>
    );
  };

  // when our Action is inside a root Action, it will appear on the Tree instead
  // of as another nested Popup. So we have to render it differently.
  const hasActionRoot = useMemo(() => {
    const maybeRootActionName = getNodeName(formContext.node);
    return !!(
      maybeRootActionName && findActionSchema(maybeRootActionName) != null
    );
  }, [findActionSchema, formContext.node]);

  // This can be called multiple times when it is in tree-mode
  const handleActionSelect = (actionName: string) => {
    if (!doc) return;
    const nodeContext = new RootWidgetContext(formContext, idSchema.$id);
    nodeContext.addLeafNode(doc, actionName, new Scalar(null));
    dispatchVisualEditorChanges();

    onChange(actionName);
    if (selectedAction && !hasActionRoot) {
      setIsOpened(true);
    }
  };

  // unique key for each field so the Modal state sticks around for a bit
  const uniqueModalKey = useMemo(() => uuidv4(), []);

  return !selectedAction ? (
    <ActionItemPicker onItemSelect={handleActionSelect} />
  ) : hasActionRoot ? (
    <div className={"action-builder-field action-builder-field-anchor"}>
      <ActionItemPicker
        onItemSelect={handleActionSelect}
        trigger={renderFieldContent(selectedAction)}
      />
      <Tooltip title={"Edit Action"}>
        <span className={"action-builder-field-anchor-edit"}>
          <RemixIcon name={"arrow-right-line"} />
        </span>
      </Tooltip>
    </div>
  ) : (
    <div className={"action-builder-field"}>
      <div
        className={"action-builder-field-preview"}
        onClick={() => setIsOpened(true)}
      >
        {renderFieldContent(selectedAction)}
      </div>
      <FormRefProvider>
        <ActionBuilderModal
          key={uniqueModalKey}
          {...props}
          isOpened={isOpened}
          setIsOpened={setIsOpened}
        />
      </FormRefProvider>
    </div>
  );
};
