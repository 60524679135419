import React, { useState, createRef, useContext, useEffect } from "react";
import { toast } from "react-toastify";

import { AuthContext } from "../App";
import { useAppContext } from "../pages/AppPagesWrapper";

// APIs
import { useInviteCollaborator } from "../hooks/useAPIs";

// Interfaces
import { AccessType } from "../models/model";

export type InviteCollaboratorProps = {
  collaboratorAccessType?: AccessType;
};

const InviteCollaborator: React.FC<InviteCollaboratorProps> = ({
  collaboratorAccessType,
}) => {
  const { currentUser } = useContext(AuthContext);
  const { app } = useAppContext();
  const [inviteeEmail, setInviteeEmail] = useState("");
  const [inviteeAccessType, setInviteeAccessType] = useState(
    collaboratorAccessType
  );
  const inviteeEmailInput = createRef<HTMLInputElement>();
  const [isValidEmail, setIsValidEmail] = useState(false);

  const inviteCollaborator = useInviteCollaborator(
    app.id,
    app.name,
    currentUser?.id,
    currentUser?.name,
    inviteeAccessType!,
    inviteeEmail
  );

  const inviteeEmailChange = (
    event: React.FormEvent<HTMLInputElement>,
  ): void => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // regex to validate email format
    const email = event.currentTarget.value.toLowerCase();

    setInviteeEmail(email);
    setIsValidEmail(emailRegex.test(email));
  };

  const handleInvite = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (inviteeEmail !== "" && inviteeEmail !== currentUser?.email) {
      inviteCollaborator.mutate();
      setInviteeEmail("");
    } else {
      if (inviteeEmail === currentUser?.email)
        toast.error("You cannot invite yourself.", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
          progress: undefined,
        });
      else if (
        inviteeEmail === "" &&
        inviteeEmailInput.current?.checkValidity() === false
      )
        toast.error("Please enter a valid email.", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
          progress: undefined,
        });
    }
  };

  useEffect(() => {
    if (inviteCollaborator.isSuccess)
      toast("Invite has been sent.", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        progress: undefined,
      });
  }, [inviteCollaborator.isSuccess]);

  return (
    <form className="app-collaborators-form" onSubmit={handleInvite}>
      <label>Invite a collaborator</label>
      <div>
        <input
          type="email"
          placeholder="Enter email address"
          value={inviteeEmail}
          onChange={inviteeEmailChange}
          ref={inviteeEmailInput}
          required
        />
        {collaboratorAccessType === AccessType.owner && (
          <div>
            <input
              type="radio"
              name="accessType"
              value={AccessType.owner}
              onClick={() => setInviteeAccessType(AccessType.owner)}
              checked={inviteeAccessType === AccessType.owner}
            />
            <span>Owner</span>
          </div>
        )}

        {collaboratorAccessType !== AccessType.read && (
          <div>
            <input
              type="radio"
              name="accessType"
              value={AccessType.write}
              onClick={() => setInviteeAccessType(AccessType.write)}
              checked={inviteeAccessType === AccessType.write}
            />
            <span>Editor</span>
          </div>
        )}
        
        <div>
          <input
            type="radio"
            name="accessType"
            value={AccessType.read}
            onClick={() => setInviteeAccessType(AccessType.read)}
            checked={inviteeAccessType === AccessType.read}
          />
          <span>Viewer</span>
        </div>

        <button
          type="submit"
          className="button__primary"
          disabled={
            !inviteeEmailInput ||
            inviteCollaborator.isLoading ||
            !isValidEmail ||
            inviteeEmail.trim() === ""
          }
        >
          {(inviteCollaborator.isIdle || inviteCollaborator.isSuccess) && (
            <>Invite</>
          )}
          {inviteCollaborator.isLoading && <>Processing...</>}
        </button>
      </div>
    </form>
  );
};

export default InviteCollaborator;
