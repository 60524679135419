import {
  ChildPath,
  ChildrenOverride,
  ChildrenTemplate,
} from "../../../containers/NavigatorPanel/TreeNode";
import { RootWidgetContext } from "../../../containers/WidgetPropertyPanel/fields/RootWidgetContext";
import { NavigatorNode, NodeData } from "./NavigatorModel";
import {
  isMap,
  isScalar,
  isSeq,
  Pair,
  Scalar,
  YAMLMap,
  Node,
  isPair,
  isNode,
} from "yaml";
import { find, getNodeName } from "../../../utils/docUtils";
import { getTreeNodeSubTitle } from "../../../utils/treeUtils";

export type ActionNodeData = NodeData & {
  /**
   * the root node of the action itself, which can contain other nodes along
   * with the Action (e.g. event, condition if/else, or simply the action itself).
   * We use this when removing the action to cleanly remove the entire block.
   * Its type can be a Map, Pair, or Scalar
   */
  rootNode: Node | Pair;
  /**
   * Action node can either be just a name (e.g. navigateBack) or a Pair (e.g. invokeAPI: {...})
   */
  actionNode: Scalar | Pair;
  /**
   * readonly to propagate the subtitle to other components
   */
  subTitle?: string;
};

export class ActionTreeBuilder {
  constructor(
    private readonly nodeContext: RootWidgetContext,
    private readonly actionSchemaMap: ReadonlyMap<string, string>,
    private readonly childrenOverrides: ReadonlyMap<string, ChildrenOverride>,
  ) {}

  build(): NavigatorNode<ActionNodeData>[] | undefined {
    const eventMap = this._getStartingNode();
    const actionNode = eventMap?.get(this.nodeContext.getPropertyName());
    if (!eventMap || !isNode(actionNode)) return;

    const rtnNode = this.buildNode(
      actionNode,
      eventMap,
      "root",
      this.nodeContext.getPropertyName() ?? undefined,
    );
    return rtnNode ? [rtnNode] : undefined;
  }

  buildNode(
    rawActionNode: Node,
    rootNode: Node | Pair,
    keyPrefix: string,
    subTitle?: string,
  ): NavigatorNode<ActionNodeData> | undefined {
    const actionNode = this._formatActionNode(rawActionNode);
    if (!actionNode) return;

    const actionName = getNodeName(actionNode);
    if (actionName && this.actionSchemaMap.has(actionName)) {
      const id = `${keyPrefix}-${subTitle ?? ""}`;
      return {
        // display the eventName or the subtitle as the secondary text
        subtitle: subTitle,
        title: actionName,
        data: {
          id,
          rootNode,
          actionNode,
          subTitle,
        },
        children:
          isPair(actionNode) && isMap(actionNode.value)
            ? this.buildChildren(actionNode as Pair<Scalar, YAMLMap>, id)
            : undefined,
      };
    }
  }

  buildChildren(
    actionPair: Pair<Scalar, YAMLMap>,
    keyPrefix: string,
  ): NavigatorNode<ActionNodeData>[] | undefined {
    const actionName = actionPair.key.value as string;

    // Actions don't have children normally, so childrenOverride is all we look for.
    const childrenOverride = this.childrenOverrides.get(actionName);
    if (childrenOverride) {
      // see if a children template is specified
      const childrenTemplate = childrenOverride.getChildrenTemplate();
      if (childrenTemplate) {
        return this._buildChildrenFromTemplate(
          actionPair,
          childrenTemplate,
          keyPrefix,
        );
      }
      // else iterate through the children list
      else if (childrenOverride.getChildrenArray()) {
        return this._buildChildrenFromArray(
          actionPair,
          childrenOverride.getChildrenArray()!,
          keyPrefix,
        );
      }
    }
  }

  /**
   * Build a children from a list of paths pointing to the child/child label (e.g. onTap: ..).
   * The child's key is expected to be the Event name
   */
  _buildChildrenFromArray(
    actionPair: Pair<Scalar, YAMLMap>,
    childrenArray: ChildPath[],
    keyPrefix: string,
  ): NavigatorNode<ActionNodeData>[] | undefined {
    const nodes: NavigatorNode<ActionNodeData>[] = [];
    childrenArray.forEach((childPath, index) => {
      const itemEventPair = actionPair.value?.items.find(
        (pair) => (pair.key as Scalar).value === childPath.path,
      );
      if (!itemEventPair || !isNode(itemEventPair.value)) return;

      const node = this.buildNode(
        itemEventPair.value,
        itemEventPair,
        `${keyPrefix}-${index}`,
        getNodeName(itemEventPair) ?? childPath.label,
      );
      if (node) {
        nodes.push(node);
      }
    });
    return nodes;
  }

  /**
   * build children from a template (i.e. property to the Sequence, then childPath to the child/label template)
   */
  _buildChildrenFromTemplate(
    actionPair: Pair<Scalar, YAMLMap>,
    childrenTemplate: ChildrenTemplate,
    keyPrefix: string,
  ): NavigatorNode<ActionNodeData>[] | undefined {
    const childrenNode = actionPair.value?.get(childrenTemplate.property);
    if (!isSeq(childrenNode)) return undefined;

    const nodes: NavigatorNode<ActionNodeData>[] = [];
    childrenNode.items.map((childRootNode, index) => {
      let subtitle;
      if (childrenTemplate.childPath?.label) {
        subtitle = getTreeNodeSubTitle(
          childRootNode as Node,
          childrenTemplate.childPath,
        );
      }
      let actionNode = childRootNode;
      if (childrenTemplate.childPath) {
        actionNode = find(
          childrenTemplate.childPath.path.split("_"),
          childRootNode as Node,
        );
      }
      if (!isNode(actionNode)) return;

      // build this child node
      const node = this.buildNode(
        actionNode,
        childRootNode as Node | Pair,
        `${keyPrefix}-${index}`,
        subtitle,
      );
      if (node) {
        nodes.push(node);
      }
    });
    return nodes;
  }

  _getStartingNode(): YAMLMap | undefined {
    if (this.nodeContext.paths.length > 0) {
      const rootNode = this.nodeContext.findNode(
        this.nodeContext.paths.slice(0, this.nodeContext.paths.length - 1),
      );
      if (isMap(rootNode) && rootNode.has(this.nodeContext.getPropertyName())) {
        return rootNode;
      }
    }
  }

  /**
   * an Action node can either be a Scalar (navigateBack) or a Map with single pair(navigate: {...}).
   * Since everywhere else we use Pair instead of Map, convert it if necessary.
   */
  _formatActionNode(rawActionNode: unknown): Scalar | Pair | undefined {
    if (isScalar(rawActionNode) || isPair(rawActionNode)) {
      return rawActionNode;
    } else if (isMap(rawActionNode) && rawActionNode.items.length === 1) {
      return rawActionNode.items[0];
    }
  }
}
