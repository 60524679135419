import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as IconHome } from "../../assets/icon_home.svg";
import { ReactComponent as IconNext } from "../../assets/icon_next.svg";
import close_icon from "../../assets/icon_close.svg";

import { AppData } from "../../models/model";

const PublishHeader: React.FC<{
  app: AppData;
  onClick: () => void;
}> = ({ app, onClick }) => {
  return (
    <div className="editor-header">
      <div className="breadcrumbs">
        <Link to={`/`}>
          <IconHome className={"nav-icon"} />
        </Link>
        <IconNext className={"arrow-icon"} />
        <Link to={`/app/${app?.id}/screens`} onClick={onClick}>
          {app?.name}
        </Link>
        <IconNext className={"arrow-icon"} />
        <Link to={`/app/${app?.id}/screens`}>Publish</Link>
      </div>
      <button className="button__icon" onClick={onClick}>
        <img src={close_icon} alt="Close" />
      </button>
    </div>
  );
};

export default PublishHeader;
