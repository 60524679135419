import React, { useEffect, useRef, useState } from "react";
import * as monaco from "monaco-editor";

const editorConfig: monaco.editor.IStandaloneDiffEditorConstructionOptions = {
  automaticLayout: true,
  theme: "vs-dark",
  minimap: {
    enabled: false,
  },
  quickSuggestions: {
    other: true,
    strings: true,
  },
  scrollbar: {
    vertical: "hidden",
    horizontal: "hidden",
  },
  wordWrap: "off",
  fontSize: 14,
};

interface YamlEditorProps {
  editorContent?: string;
  setEditorContent: (content: string) => void;
  readOnly?: boolean;
  onSaveRequest?: (newContent: string) => void;
  selectedHistory: any;
}

export const YamlEditor: React.FC<YamlEditorProps> = ({
  editorContent,
  setEditorContent,
  readOnly,
  onSaveRequest,
  selectedHistory,
}) => {
  const editorRef = useRef(null);
  const [editorInstance, setEditorInstance] =
    useState<monaco.editor.IStandaloneDiffEditor | null>(null);

  // editor only created once so it needs access to the latest callback
  const onSaveRequestRef = useRef(onSaveRequest);
  onSaveRequestRef.current = onSaveRequest;

  // create the Editor instance once
  useEffect(() => {
    if (!selectedHistory && editorRef.current) {
      const yamlInstance = monaco.editor.create(editorRef.current, {
        ...editorConfig,
        language: "yaml",
        value: editorContent,
      });
      yamlInstance.onDidChangeModelContent(() => {
        setEditorContent(yamlInstance.getValue());
        if (!readOnly && onSaveRequest)
          yamlInstance.addCommand(
            monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS,
            () => {
              onSaveRequestRef.current!(yamlInstance.getValue());
            },
          );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHistory]);

  useEffect(() => {
    if (selectedHistory) {
      if (!editorInstance && editorRef.current) {
        const diffEditor = monaco.editor.createDiffEditor(
          editorRef.current,
          editorConfig,
        );
        setEditorInstance(diffEditor);

        const originalModel = monaco.editor.createModel(
          selectedHistory.content,
        );
        const modifiedModel = monaco.editor.createModel(editorContent ?? "");
        modifiedModel.onDidChangeContent(() => {
          setEditorContent(modifiedModel.getValue());
        });
        diffEditor.setModel({
          original: originalModel,
          modified: modifiedModel,
        });
      } else {
        const originalModel = monaco.editor.createModel(
          selectedHistory.content,
        );
        const modifiedModel = monaco.editor.createModel(editorContent ?? "");
        editorInstance?.setModel({
          original: originalModel,
          modified: modifiedModel,
        });
      }
    } else {
      if (editorInstance) {
        editorInstance.dispose();
        setEditorInstance(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHistory]);

  return (
    <div className="yaml-editor">
      <div
        ref={editorRef}
        style={{
          height: window.innerHeight - 150 + "px",
          width: window.innerWidth - 150 + "px",
        }}
      />
    </div>
  );
};
