import React, {
  cloneElement,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import "./ActionMenu.sass";
import { RemixIcon } from "../Widgets";
import { Tooltip } from "antd";

interface ActionMenuProps {
  anchor: ReactElement;
  items: ActionMenuItem[];
  onSelect: (selectedValue: string) => void;
}

export interface ActionMenuItem {
  label: string;
  value: string;
  iconName?: string;
  description?: React.ReactNode;
  closeOnSelect?: boolean;
}

// an Action Menu that can be triggered by the "anchor" and display a list of actions
export const ActionMenu: React.FC<ActionMenuProps> = ({
  anchor,
  items,
  onSelect,
}) => {
  const anchorRef = useRef<HTMLElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  // attach an onClick to the anchor to hide/show the menu
  const anchorElement = cloneElement(anchor, {
    ref: anchorRef,
    onClick: () => setIsVisible(!isVisible),
  });

  const popupRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node) &&
        !anchorRef?.current?.contains(event.target as Node)
      ) {
        setIsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleItemClick = (item: ActionMenuItem) => {
    onSelect(item.value);
    if (item.closeOnSelect !== false) {
      setIsVisible(false);
    }
  };

  return (
    <div className={"action-menu-container"}>
      {anchorElement}
      {isVisible && (
        <div ref={popupRef} className={"action-menu-popup"}>
          {items.map((item, index) => (
            <Tooltip
              key={index}
              title={item.description || ""}
              placement={"right"}
            >
              <div
                key={index}
                className="action-menu-item"
                onClick={() => handleItemClick(item)}
              >
                {item.iconName && <RemixIcon name={item.iconName} />}
                {item.label}
              </div>
            </Tooltip>
          ))}
        </div>
      )}
    </div>
  );
};
