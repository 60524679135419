import { JSONSchema7 } from "json-schema";
import { isMap, isPair, isScalar, isSeq, Scalar, YAMLMap, YAMLSeq } from "yaml";
import { YAMLDocNode } from "../hooks/useYamlDoc";
import { get, isString, tail } from "lodash-es";
import { CustomJSONSchema7 } from "../containers/WidgetPropertyPanel/utils/propertyPanelSchemaUtils";
import { ChildrenOverride } from "../containers/NavigatorPanel/TreeNode";

// all the root keys that are not custom widgets
const NON_CUSTOM_WIDGETS_NAMES = [
  "View",
  "API",
  "Global",
  "Socket",
  "Action",
  "App",
  "Function",
  "Model",
  "Variable",
];

export const getCustomWidgetNodes = (root: YAMLMap): Scalar[] => {
  const result = root.items.filter(
    (item) =>
      !NON_CUSTOM_WIDGETS_NAMES.includes((item.key as Scalar).value as string),
  );
  return result.map((item) => item.key as Scalar);
};

export const getKey = (node: YAMLDocNode): Scalar | undefined => {
  if (isPair(node)) {
    return node.key as Scalar;
  }

  if (isScalar(node)) {
    return node;
  }

  return;
};

export const getKeyValue = (node: YAMLDocNode) => {
  if (isPair(node)) {
    return String((node.key as Scalar).value);
  }

  if (isScalar(node)) {
    return String(node.value);
  }

  return;
};

/**
 * We want the actions' schemas map, but we want the nested action names
 * to appear in the Tree instead of the Property Panel. So we build and return:
 * 1. A map of action names to their schema (without child action names)
 * 2. A map of action names to a list of child action names
 *
 * Note that this will remove the direct child actions from the main Action schema
 */
export const buildActionSchemaMapAndMutate = (
  root: JSONSchema7,
): {
  actionGroups: Map<string, { name: string; description?: string }[]>;
  actionSchemaMap: ReadonlyMap<string, string>;
  actionChildrenOverrides: Map<string, ChildrenOverride>;
} => {
  // initialize groups with the orders we want to show. Navigation group is first - the rest we don't care.
  const actionGroups: Map<string, { name: string; description?: string }[]> =
    new Map([["Navigation", []]]);
  const othersGroup: { name: string; description?: string }[] = [];
  const actionChildrenOverrides = new Map();

  const actionSchemaMap = new Map<string, string>();
  (root.$defs?.Action as JSONSchema7)?.oneOf?.map((entry) => {
    const properties = (entry as JSONSchema7)?.properties;
    if (!properties) return;

    // we expect a mapping of ActionName -> $ref
    Object.entries(properties).map(([actionName, actionValue]) => {
      if ((actionValue as JSONSchema7).$ref) {
        const refName = (actionValue as JSONSchema7).$ref!.split("/").pop();
        if (refName) {
          // we go further by fetching the definition and remove all the root child Action
          // This is because nested Actions will be shown in the tree.
          const action = getDefinitionByName(root, refName);
          if (action && action.properties) {
            // found our action schema
            actionSchemaMap.set(actionName, refName);

            if ((action as CustomJSONSchema7).childrenOverride) {
              // Special case for this Action since we want to show the label (in addition to the typical value)
              const showLabel = actionName === "executeConditionalAction";
              actionChildrenOverrides.set(
                actionName,
                new ChildrenOverride(
                  (action as CustomJSONSchema7).childrenOverride!,
                  (action as CustomJSONSchema7).childrenOverrideSubtitle,
                  showLabel,
                ),
              );
            } else {
              // collect all the children Action manually from the schema
              const actionChildren: Array<string> = [];

              Object.keys(action.properties).map((propName) => {
                const prop = action.properties![propName] as JSONSchema7;
                if (prop.$ref === "#/$defs/Action") {
                  actionChildren.push(propName);
                }
              });

              if (actionChildren.length) {
                actionChildrenOverrides.set(
                  actionName,
                  new ChildrenOverride(`[${actionChildren.join(", ")}]`),
                );
              }
            }

            // // remove the child actions from the action schema and add to the actionChildrenMap
            // const actionChildren = new Set<string>();
            // Object.keys(action.properties).map((propName) => {
            //   const prop = action.properties![propName] as JSONSchema7;
            //   if (prop.$ref === "#/$defs/Action") {
            //     actionChildren.add(propName);
            //
            //     // mutate the original schema
            //     delete action.properties![propName];
            //   }
            // });
          }

          if (action) {
            if (!(actionValue as CustomJSONSchema7).deprecated) {
              // add the action to the category groups
              const category = (action as CustomJSONSchema7).category;
              if (!category || category === "Others") {
                othersGroup.push({
                  name: actionName,
                  description: action.description,
                });
              } else {
                // init if needed
                if (!actionGroups.has(category)) {
                  actionGroups.set(category, []);
                }
                actionGroups.get(category)?.push({
                  name: actionName,
                  description: action.description,
                });
              }
            }
          }
        }
      }
    });
  });
  // group left-over into Others category
  if (othersGroup.length > 0) {
    actionGroups.set("Others", othersGroup);
  }

  return {
    actionGroups,
    actionSchemaMap,
    actionChildrenOverrides,
  };
};

/**
 * Compile a list of container names and widget names (including containers)
 * This will also mutate the original schema by removing 'children' property.
 */
export const buildWidgetSchemaMapAndMutate = (
  root: JSONSchema7 | undefined,
): {
  widgetNames: Set<string>;
  containerNames: Set<string>;
  widgetSchemaMap: Map<string, JSONSchema7>;
  widgetGroups: Map<string, { name: string; description?: string }[]>;
  childrenOverrides: Map<string, ChildrenOverride>;
} => {
  const widgetSchemaMap = new Map<string, JSONSchema7>();
  const containerSchemaMap = new Map<string, JSONSchema7>();

  // initialize groups with the orders we want to show. This is optional
  const widgetGroups: Map<string, { name: string; description?: string }[]> =
    new Map([
      ["Text", []],
      ["Form Input", []],
    ]);
  const othersGroup: { name: string; description?: string }[] = [];

  const childrenOverrides = new Map();

  (root && (root.$defs?.Widget as JSONSchema7))?.oneOf?.map((entry) => {
    const properties = (entry as JSONSchema7)?.properties as JSONSchema7;
    if (!properties) return;

    Object.entries(properties).map(([widgetName, ref]) => {
      const value = getDefinitionByName(root, widgetName, ref.$ref);
      if (!value) return;

      if (value.properties?.children) {
        containerSchemaMap.set(widgetName, value);
      }
      widgetSchemaMap.set(widgetName, value);

      // add the widget to the category groups
      if (!(value as CustomJSONSchema7).deprecated) {
        const category = (value as CustomJSONSchema7).category;
        if (!category || category === "Others") {
          othersGroup.push({
            name: widgetName,
            description: value.description,
          });
        } else {
          // init if needed
          if (!widgetGroups.has(category)) {
            widgetGroups.set(category, []);
          }
          widgetGroups.get(category)?.push({
            name: widgetName,
            description: value.description,
          });
        }
      }

      // look for children overrides
      if ((value as CustomJSONSchema7).childrenOverride) {
        // Conditional widget wants to show the label (if/elseif/else) so we special case it.
        // If we have more use cases, we can expose it in the schema.
        const showLabel = widgetName === "Conditional";
        childrenOverrides.set(
          widgetName,
          new ChildrenOverride(
            (value as CustomJSONSchema7).childrenOverride!,
            (value as CustomJSONSchema7).childrenOverrideSubtitle,
            showLabel,
          ),
        );
      }
    });
  });

  // group left-over into Others category
  if (othersGroup.length > 0) {
    widgetGroups.set("Others", othersGroup);
  }

  // strip the children out as children are handled as the tree's child nodes
  containerSchemaMap.forEach((value) => delete value.properties?.children);

  return {
    widgetNames: new Set(widgetSchemaMap.keys()),
    containerNames: new Set(containerSchemaMap.keys()),
    widgetGroups,
    widgetSchemaMap,
    childrenOverrides,
  };
};

export const getDefinitionByName = (
  root: JSONSchema7,
  name: string,
  ref?: string,
): JSONSchema7 | undefined => {
  let schema = root.$defs?.[name] as JSONSchema7;
  if (isString(ref)) {
    schema = get(root, tail(ref.split("/")));
  }
  while (schema?.$ref) {
    const refName = schema.$ref.split("/").pop();
    if (!refName) break;

    schema = root.$defs?.[refName] as JSONSchema7;
    if (!schema) break;
  }
  return schema;
};

// generate a new widget name starting with (NEW_WIDGET_PREFIX + number)++
const NEW_WIDGET_PREFIX = "MyWidget";
export const generateWidgetName = (root: YAMLMap) => {
  const filteredPairs = root.items.filter((pair) => {
    const key = (pair.key as Scalar).value as string;
    return (
      key.startsWith(NEW_WIDGET_PREFIX) &&
      /^\d+$/.test(key.substring(NEW_WIDGET_PREFIX.length))
    );
  });

  const maxNumber = filteredPairs.reduce((max, pair) => {
    const key = (pair.key as Scalar).value as string;
    const numPart = parseInt(key.substring(NEW_WIDGET_PREFIX.length), 10);
    return Math.max(max, numPart);
  }, 0);

  return `${NEW_WIDGET_PREFIX}${maxNumber + 1}`;
};

// generate new API name
const NEW_API_PREFIX = "MyApi";
export const generateAPIName = (root: YAMLMap) => {
  const apiNode = root.get("API");

  if (!apiNode || !isMap(apiNode)) {
    return `${NEW_API_PREFIX}1`;
  }

  const filteredPairs = apiNode.items.filter((pair) => {
    const key = (pair.key as Scalar).value as string;
    return (
      key.startsWith(NEW_API_PREFIX) &&
      /^\d+$/.test(key.substring(NEW_API_PREFIX.length))
    );
  });

  const maxNumber = filteredPairs.reduce((max, pair) => {
    const key = (pair.key as Scalar).value as string;
    const numPart = parseInt(key.substring(NEW_API_PREFIX.length), 10);
    return Math.max(max, numPart);
  }, 0);

  return `${NEW_API_PREFIX}${maxNumber + 1}`;
};

// add a empty child widget to the parent's children property
export const addChildWidget = (parent: YAMLMap, child: YAMLDocNode) => {
  let childrenNode = parent.get("children");
  if (childrenNode instanceof YAMLSeq) {
    childrenNode.add(child);
  } else {
    childrenNode = new YAMLSeq();
    (childrenNode as YAMLSeq).add(child);
    parent.set("children", childrenNode);
  }
};

export const getNodeRef = (parentNode: YAMLMap, selectedNode?: YAMLDocNode) => {
  let targetNodeItems = parentNode.get("children") as YAMLSeq;
  let siblingNode = selectedNode;

  if (isPair(selectedNode)) {
    if (isMap(selectedNode?.value)) {
      const selectedNodeChildren = selectedNode?.value.get("children");
      if (isSeq(selectedNodeChildren)) {
        targetNodeItems = selectedNodeChildren;
      }
    }
  }

  const lastChild = targetNodeItems?.items?.[targetNodeItems.items.length - 1];
  if (isMap(lastChild)) {
    siblingNode = lastChild.items[0];
  }

  return {
    parent: targetNodeItems,
    sibling: siblingNode,
  };
};
