import React, { useEffect, useState } from "react";
import { FieldProps } from "@rjsf/utils";
import { ColorPicker } from "antd";
import { Color } from "antd/es/color-picker";
import "./ColorPickerField.sass";
import {
  defaultPresets,
  fromColorToHex,
  fromHexToColor,
  getColorName,
  isTransparent,
} from "../utils/propertyPanelUtils";

export const ColorPickerField: React.FC<FieldProps> = ({
  formData,
  onChange,
  formContext,
}) => {
  const [hasPendingChanges, setHasPendingChanges] = useState(false);

  const isHtml = formContext.appData.isReact;
  const [color, setColor] = useState<string | undefined>();
  const [colorName, setColorName] = useState<string | undefined>();
  useEffect(() => {
    // our Color schema has multiple types, so:
    // 1. default value might come in as an object
    // 2. sometimes RJSF returning a number even though we save it as a string. Convert it if so
    let data = undefined;
    if (typeof formData === "number" && !isHtml) {
      data = `0x${formData.toString(16).toUpperCase()}`;
    } else if (typeof formData === "string") {
      data = formData;
    }
    const color = fromColorToHex(data, isHtml);
    // console.log("Incoming color: ", color);
    setColor(color);

    // get the color name from the original form data
    const colorName = getColorName(data);
    setColorName(colorName);
  }, [formData, isHtml]);

  const handleChangeComplete = (color: Color) => {
    let newColor: string | undefined = undefined;
    // there is no real "Clear color" concept for the lib we use,
    // so we have to assume fully transparent is undefined
    let hex = color.toHex();

    if (!isTransparent(hex)) {
      if (hex !== "00000000" && hex.endsWith("00") && hex !== "000000") {
        hex = hex.substring(0, hex.length - 2);
      }
      newColor = fromHexToColor(hex, isHtml);
    } else {
      newColor = "transparent";
    }
    // console.log("New color: ", newColor);
    onChange(newColor);
    setHasPendingChanges(true);
  };

  useEffect(() => {
    if (hasPendingChanges) {
      formContext.submitForm();
      setHasPendingChanges(false);
    }
  }, [hasPendingChanges]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={"color-picker"}>
      <ColorPicker
        value={color}
        allowClear={true}
        onClear={() => {
          onChange(undefined);
          setHasPendingChanges(true);
        }}
        presets={[
          {
            label: <div>Colors</div>,
            colors: Object.values(defaultPresets),
            defaultOpen: true,
          },
        ]}
        onChangeComplete={handleChangeComplete}
      >
        <div className={"color-picker-trigger"}>
          {color ? (
            <div
              className={"color-box"}
              style={{ backgroundColor: color }}
            ></div>
          ) : (
            <div className={"no-color-selected"} />
          )}
          <div className={"color-name"}>{colorName}</div>
        </div>
      </ColorPicker>
    </div>
  );
};
