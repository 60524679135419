// store for accessing the Code/Visual editor functions
import { create } from "zustand";
import { BetaFeature } from "../components/VisualEditor/BetaFeatureDialog";

export interface EditorStore {
  isSourceView: boolean;
  setIsSourceView: (isSourceView: boolean) => void;
}

export const useEditor = create<EditorStore>((set) => ({
  isSourceView: false,
  setIsSourceView: (isSourceView: boolean) => set(() => ({ isSourceView })),
}));

// managing the Visual Editor beta features
export const visualEditorBetaFeature: BetaFeature = {
  featureKey: "VisualEditor",
  version: 1, // increment this to show the dialog again
  content: (
    <div>
      <h1>Visual Editor in Beta</h1>
      <div>Use this to visually edit your app and preview in real time.</div>
      <div>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Switch back to Code Editor anytime by clicking the 'Edit Source' button.
      </div>
      <div style={{ margin: "20px 50px" }}>
        <img
          style={{
            width: "100%",
            border: "1px solid #f0f0f0",
            maxWidth: "600px",
          }}
          src="/screenshots/visual_editor_beta.png"
          alt="Visual Editor beta screenshot"
        />
      </div>
    </div>
  ),
};
