import {
  isMap,
  isScalar,
  isSeq,
  Node,
  Pair,
  Scalar,
  YAMLMap,
  YAMLSeq,
} from "yaml";
import {
  ChildPath,
  ChildrenOverride,
  TreeItemWidgetPayload,
  TreeNode,
} from "./TreeNode";
import { getTreeIcon, getTreeNodeSubTitle } from "../../utils/treeUtils";
import { NodeAction } from "../../hooks/useYamlDoc";
import { ReactNode } from "react";
import { find } from "../../utils/docUtils";

export class TreeBuilder {
  private readonly UNKNOWN_WIDGET = "(Unknown widget)";
  private readonly ADD_WIDGET = "Add widget";

  constructor(
    // all platform widgets + custom widgets
    private whitelistedWidgets: ReadonlySet<string>,
    // all containers that can have children
    private containerList: ReadonlySet<string>,
    private childrenOverrides: Map<string, ChildrenOverride>,

    private treeItemWidgetMap: Map<string, TreeItemWidgetPayload[]>,
  ) {}

  private isWidget(key: string) {
    return this.whitelistedWidgets.has(key);
  }

  private isContainer(key: string) {
    return this.containerList.has(key);
  }

  // placeholder for "add a widget" node. We'll override the actual UI
  // in the TreeView component
  private buildAddWidgetNode(parent: YAMLMap, keyPrefix: string): TreeNode {
    return {
      key: `${keyPrefix}-addNode`,
      selectable: false,
      ref: {
        node: new Scalar(""),
        parent: parent,
        action: NodeAction.AddChild,
      },
      isLeaf: true,
    };
  }

  private buildSetTreeWidgetNode(
    parent: YAMLMap,
    keyPrefix: string,
    treeItemWidget: TreeItemWidgetPayload,
  ): TreeNode {
    return {
      key: `${keyPrefix}-${treeItemWidget.property}-setWidget`,
      selectable: false,
      ref: {
        node: new Scalar(""),
        parent: parent,
        action: NodeAction.SetTreeItemWidget,
      },
      payload: treeItemWidget,
      isLeaf: true,
    };
  }

  // process a scalar e.g. "Column"
  private processScalar(
    parent: YAMLMap,
    node: Scalar,
    keyPrefix: string,
    subTitle?: string,
  ): TreeNode {
    const name = node.value as string;
    return {
      key: `${keyPrefix}-${name}`,
      ref: {
        node: node,
        parent: parent,
      },
      title: this._buildTitle(
        this.isWidget(name) ? name : this.UNKNOWN_WIDGET,
        subTitle,
        false,
      ),
      icon: () => getTreeIcon(name),
      isLeaf: true,
    };
  }
  // process a key/value pair e.g. "Column:"
  private processPair(
    parent: YAMLMap,
    pair: Pair,
    keyPrefix: string,
    subTitle?: string,
  ): TreeNode {
    const keyNode = pair.key as Scalar;
    const valueNode = pair.value;

    let children = this.processChildren(pair, keyPrefix);

    // handle mappings to widgets in the schema
    if (this.treeItemWidgetMap.has(keyNode.value as string)) {
      const widgetItems = this.treeItemWidgetMap.get(keyNode.value as string);
      widgetItems?.forEach((item) => {
        // have existing entry
        if (isMap(valueNode) && valueNode.get(item.property)) {
          // only handle non-Scalar widget for now (e.g. don't handle 'Text' without colon)
          const itemNode = valueNode.get(item.property);
          if (isMap(itemNode)) {
            children = [
              ...(children ??= []),
              ...this.processMap(
                valueNode,
                itemNode,
                `${keyPrefix}-${item.property}`,
              ),
            ];
          }
        }
        // placeholder to add
        else {
          (children ??= []).push(
            this.buildSetTreeWidgetNode(valueNode as YAMLMap, keyPrefix, item),
          );
        }
      });
    }
    return {
      key: `${keyPrefix}-${keyNode.value}`,
      ref: {
        node: pair,
        parent: parent,
      },
      title: this.buildNodeTitle(pair, subTitle),
      icon: () => getTreeIcon(keyNode.value as string),
      children: children ?? undefined,
      isLeaf: !children,
    };
  }

  private processMap(
    parent: YAMLMap,
    node: YAMLMap,
    keyPrefix: string,
    subTitle?: string,
  ): TreeNode[] {
    const nodes: TreeNode[] = [];
    node.items.forEach((item) => {
      nodes.push(this.processPair(parent, item, keyPrefix, subTitle));
    });
    return nodes;
  }

  private processSeq(
    parent: YAMLMap,
    node: YAMLSeq,
    keyPrefix: string,
    childPath?: ChildPath,
  ): TreeNode[] {
    const nodes: TreeNode[] = [];
    node.items.forEach((item, index) => {
      // override children can specify a subTitle path and a child path
      // iterate through each child and see if it has a subTitle
      const subTitle = getTreeNodeSubTitle(item as Node, childPath);
      // traverse the path (if specified) to the child node instead of the direct node
      if (childPath?.path) {
        item = find(childPath.path.split("_"), item as Node);
      }

      if (isMap(item)) {
        nodes.push(
          ...this.processMap(parent, item, `${keyPrefix}-${index}`, subTitle),
        );
      } else if (isScalar(item)) {
        // as a scalar
        nodes.push(
          this.processScalar(parent, item, `${keyPrefix}-${index}`, subTitle),
        );
      }
    });
    return nodes;
  }

  private buildNodeTitle(pair: Pair, subTitle?: string): ReactNode {
    const key = (pair.key as Scalar).value as string;
    if (!this.isWidget(key)) return this.UNKNOWN_WIDGET;

    // title = node name (widget)
    // subtitle = id (primary) or subTitle (secondary)
    const id = isMap(pair.value) && (pair.value as YAMLMap).get("id");

    return this._buildTitle(key, id ? id.toString() : subTitle, id != null);
  }

  private _buildTitle(
    title: string,
    subTitle?: string,
    isPrimarySubTitle?: boolean,
  ): ReactNode {
    if (!subTitle) return title;
    return (
      <div className={"tree-title"}>
        <span className={"tree-title__title"}>{title}</span>
        <span
          className={`${
            isPrimarySubTitle
              ? "tree-title__subtitle-primary"
              : "tree-title__subtitle-secondary"
          }`}
        >
          {subTitle}
        </span>
      </div>
    );
  }

  private processChildren(
    pair: Pair,
    keyPrefix: string,
  ): TreeNode[] | undefined {
    if (!isMap(pair.value)) return undefined;

    const widgetName = (pair.key as Scalar).value as string;
    const childrenOverride = this.childrenOverrides.get(widgetName);

    // handle an array of individual children e.g. [child1_path, child2_path]
    const childrenArray = childrenOverride?.getChildrenArray();
    if (childrenArray) {
      // TODO: move treeItemWidgetMap logic here
      return undefined;
    }

    // we either process the childrenTemplate or regular children
    const childrenTemplate = childrenOverride?.getChildrenTemplate();
    let children: TreeNode[] | undefined;
    const childrenKey =
      childrenTemplate?.property ??
      (this.isContainer(widgetName) ? "children" : undefined);
    if (childrenKey) {
      const childrenNode = pair.value.get(childrenKey);
      if (isSeq(childrenNode)) {
        children = this.processSeq(
          pair.value,
          childrenNode,
          keyPrefix,
          childrenTemplate?.childPath,
        );
      }
    }
    // ADD placeholder but only for Container
    if (this.isContainer(widgetName)) {
      (children ??= []).push(this.buildAddWidgetNode(pair.value, keyPrefix));
    }
    return children;
  }

  build(node: YAMLMap, keyPrefix: string): TreeNode[] | null {
    return this.processMap(node, node, keyPrefix);
  }
}
