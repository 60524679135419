import React, { useCallback } from "react";
import dayjs from "dayjs";

export interface Artifact {
  id?: string | null;
  path: string;
  createdAt: string;
  isRoot: boolean;
  name: string;
  type: string;
  updatedAt: string;
  isChecked?: boolean; // field to track checked state
}
interface Map {
  [key: string]: string | undefined;
}

export const ChangesetLabel: Map = {
  update: "Update",
  add: "Add",
  archive: "Archive",
};

export interface Changeset {
  update: Artifact[];
  add: Artifact[];
  archive: Artifact[];
}

export interface Data {
  sourceAppId: string;
  destinationAppId: string;
  changeset: Changeset;
}
export interface InputJson {
  data: Data;
}

interface PublishingReportProps {
  inputJson: InputJson;
  isAppPublished: boolean;
  handleCheckboxChange: (
    category: keyof Changeset,
    path: string,
    checked: boolean,
  ) => void;
}

const PublishingReport: React.FC<PublishingReportProps> = ({
  inputJson,
  isAppPublished,
  handleCheckboxChange,
}) => {
  const getId = (type: string, path: string) => {
    if (type !== "resources") {
      const splittedPath = path.split("/");
      return splittedPath[splittedPath.length - 1];
    }
    return null;
  };

  const renderTable = useCallback(
    (type: string) => {
      const categoryInfo = Object.entries(inputJson?.data?.changeset || {})
        .map(([k, v]) => v.map((m: Artifact) => ({ ...m, as: k })))
        .flat()
        .filter((item) => item.type === type);
      if (categoryInfo.length === 0) {
        return isAppPublished ? (
          <span>**Nothing Published</span>
        ) : (
          <span>No changes found</span>
        );
      }
      return (
        <table>
          <thead>
            <tr>
              {!isAppPublished && <th className="checkbox">Publish</th>}
              <th>Name</th>
              <th>Status</th>
              <th>ID</th>
              <th>Date Created</th>
              <th>Date Modified</th>
            </tr>
          </thead>
          <tbody>
            {categoryInfo.map((item, index) => (
              <tr key={index}>
                {!isAppPublished && (
                  <td className="checkbox">
                    <input
                      type="checkbox"
                      defaultChecked
                      onChange={(e) =>
                        handleCheckboxChange(
                          item.as,
                          item.path,
                          e.target.checked,
                        )
                      }
                    />
                  </td>
                )}
                <td className="row-name">
                  <div className="inline">
                    {item.name || "Theme"}
                    {item.isRoot && (
                      <div className="app-badge root">App Home</div>
                    )}
                  </div>
                </td>
                <td>
                  <div className="inline">
                    <div
                      className={`
                  app-badge 
                    ${
                      item.as === "update"
                        ? "update"
                        : item.as === "add"
                        ? "add"
                        : item.as === "archive"
                        ? "archive"
                        : ""
                    }
                  `}
                    >{`
                    ${
                      item.as === "update"
                        ? "Modified"
                        : item.as === "add"
                        ? "New"
                        : item.as === "archive"
                        ? "Archived"
                        : ""
                    }
                  `}</div>
                  </div>
                </td>
                <td>{getId(item.type as string, item.path as string)}</td>
                <td>
                  <div>
                    {item.createdAt
                      ? dayjs(item.createdAt).format("YYYY-MM-DD hh:mm a")
                      : null}
                  </div>
                </td>
                <td>
                  <div>
                    {item.updatedAt
                      ? dayjs(item.updatedAt).format("YYYY-MM-DD hh:mm a")
                      : null}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    },
    [inputJson, isAppPublished, handleCheckboxChange],
  );

  if (!inputJson || !inputJson.data || !inputJson.data.changeset) {
    return <></>;
  }

  return (
    <>
      <h4>Screens</h4>
      {renderTable("screen")}
      <h4 style={{ marginTop: "20px" }}> Widgets</h4>
      {renderTable("internal_widget")}
      <h4 style={{ marginTop: "20px" }}>Assets</h4>
      {renderTable("asset")}
      <h4 style={{ marginTop: "20px" }}>Scripts</h4>
      {renderTable("internal_script")}
      <h4 style={{ marginTop: "20px" }}>Translations</h4>
      {renderTable("i18n")}
      <h4 style={{ marginTop: "20px" }}>Other</h4>
      {renderTable("theme")}
    </>
  );
};

export default PublishingReport;
