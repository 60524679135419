import React, { ReactNode } from "react";
import { FieldProps } from "@rjsf/utils";
import { ExpressionInput } from "../components/ExpressionInput";
import "./StringField.sass";
import { CustomJSONSchema7 } from "../utils/propertyPanelSchemaUtils";

interface StringFieldProps extends FieldProps {
  icon?: ReactNode;
  iconLabel?: string;
  onIconClick?: () => void;
}

// a custom field to handle string and can submit onBlur or on Enter
// If icon is specified, it will display the icon on the right side of the input.
// On hover, it will overlay the iconLabel
export const StringField: React.FC<StringFieldProps> = ({
  idSchema,
  schema,
  formData,
  onChange,
  onBlur,
  icon,
  iconLabel,
  onIconClick,
}) => {
  const handleChange = (newValue: string) => {
    onChange(newValue);
  };

  const handleBlur = () => {
    onBlur(idSchema.$id, formData);
  };

  const handleEnterKey = () => {
    onBlur(idSchema.$id, formData);
  };

  return (
    <div className="string-field">
      <ExpressionInput
        placeholder={(schema as CustomJSONSchema7).uiPlaceholder}
        value={formData}
        onChange={handleChange}
        onEnterKey={handleEnterKey}
        onBlur={handleBlur}
      />
      {icon && (
        <div className="string-field-icon-container" onClick={onIconClick}>
          {iconLabel && (
            <span className="string-field-icon-container-label">
              {iconLabel}
            </span>
          )}
          <span className="string-field-icon-container-icon">{icon}</span>
        </div>
      )}
    </div>
  );
};
