import React, { useCallback, useState } from "react";

import { toast } from "react-toastify";
import { deleteAsset } from "../../hooks/useAPIs";
import { useAppContext } from "../../pages/AppPagesWrapper";

// Interfaces
import { EnsembleAssetData, EnsembleFontData } from "../../config/interfaces";
import { useQueryClient } from "react-query";

interface AssetArchiveModalProps {
  asset: EnsembleAssetData | EnsembleFontData;
  onCancel: () => void;
  onSubmit: () => void;
}

const AssetArchiveModal: React.FC<AssetArchiveModalProps> = ({
  asset,
  onCancel,
  onSubmit,
}) => {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const { app } = useAppContext();
  const queryClient = useQueryClient();

  const archiveScreen = useCallback(
    async (event: React.SyntheticEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setInProgress(true);
      try {
        await deleteAsset(app.id, asset.id);
        queryClient.invalidateQueries(["app", app.id]);
        toast.success(`Asset deleted successfully.`, {
          position: "top-right",
          type: toast.TYPE.SUCCESS,
          theme: "dark",
        });
        onSubmit();
      } catch (error) {
        toast.error(`Failed to delete asset.`, {
          position: "top-right",
          type: toast.TYPE.ERROR,
          theme: "dark",
        });
      } finally {
        setInProgress(false);
      }
    },
    [app.id, asset.id, onSubmit, queryClient],
  );

  return (
    <div className="app-create-modal">
      {inProgress ? (
        <button className="button__primary">Processing...</button>
      ) : (
        <>
          <button onClick={archiveScreen} className="button__primary">
            Archive asset
          </button>
          <button
            className="button__link"
            disabled={inProgress}
            onClick={onCancel}
          >
            Cancel
          </button>
        </>
      )}
    </div>
  );
};

export default AssetArchiveModal;
