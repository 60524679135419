import * as firebase from "./useFirebase";
import {
  collection,
  doc,
  DocumentData,
  documentId,
  DocumentReference,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  query,
  Query,
  where,
  deleteField,
  setDoc,
  serverTimestamp,
  orderBy,
  and,
  or,
  onSnapshot,
  type Timestamp,
} from "firebase/firestore";
import { groupBy, uniqBy } from "lodash";
import { auth, cloudFunctions, db } from "../config/firebase";
import { User } from "firebase/auth";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import {
  AccessType,
  AppConfigData,
  AppData,
  AppSecrets,
  GeneratingAppData,
} from "../models/model";
import {
  EnsembleScreenData,
  EnsembleWidgetData,
  EnsembleThemeData,
  ISubscriptionData,
  IUserSubscriptionPlan,
  ICheckoutSession,
  ICancelSubscription,
  EnsembleAssetData,
  EnsembleTranslationData,
  EnsembleFontData,
} from "../config/interfaces";
import { ArtifactType } from "../pages/EditorPage";
import { CollectionName } from "../utils/constants";
import { Changeset } from "../components/PublishingReport";

export const useGetUserDetails = firebase.useGetUserDetails;
export const useGetScreenDetails = firebase.useGetScreenDetails;
export const useGetTemplateScreens = firebase.useGetTemplateScreens;
export const useGetCategories = firebase.useGetCategories;
export const useGetArtifactHistory = firebase.useGetArtifactHistory;
export const useUpdateScreen = firebase.useUpdateScreen;
export const useUpdateWidget = firebase.useUpdateWidget;
export const useCreateApp = firebase.useCreateApp;
export const useCreateScreens = firebase.useCreateScreens;
export const useCreateTheme = firebase.useCreateTheme;
export const useUpdateTheme = firebase.useUpdateTheme;
export const useUpdateApp = firebase.useUpdateApp;
export const useResetRootScreen = firebase.useResetRootScreen;
export const useGetAppFullContent = firebase.useGetAppFullContent;

/// the document ID of the app configuration (only one per application)
const CONFIG_ID = "appConfig";
const SECRETS_ARTIFACT_NAME = "secrets";

// get the userId from authId via Firebase custom claim
export async function getUserId(): Promise<string | undefined> {
  return auth.currentUser?.getIdTokenResult().then((idTokenResult) => {
    // console.log("ID TOKEN : ", idTokenResult);
    // if userId claim is not there, ask the server to set it
    if (!idTokenResult.claims.userId) {
      // console.log("UserId claim not found. Refreshing it");
      // hit cloud function
      return updateUserClaim().then(() => {
        // force token refresh
        return auth
          .currentUser!.getIdTokenResult(true)
          .then((refreshedToken) => refreshedToken.claims.userId);
      });
    }
    return idTokenResult.claims.userId;
  });
}

function updateUserClaim() {
  const setClaim = httpsCallableFromURL(
    getFunctions(),
    process.env.REACT_APP_FIREBASE_FUNCTIONS_URL_SET_USERID_AUTH_CLAIM!,
  );

  return setClaim()
    .then(() => {
      // console.log("Success" + result.data?.toString());
    })
    .catch((error) => {
      console.log("Error " + error.code + error.toString());
    });
}

/*calls cloud function to verify invite and adds collaborator*/
export async function verifyAppInvite(inviteRef: string) {
  return cloudFunctions.studio_verifyAppInvite({
    ref: inviteRef,
  });
}

export async function rejectAppInvite(inviteRef: string) {
  return cloudFunctions.studio_rejectAppInvite({
    ref: inviteRef,
  });
}

// get all user's  apps invites from the database only the pending invites
export async function getUserInvites() {
  return cloudFunctions.studio_getAppsInvites();
}

export function generateApp(query: string) {
  console.log(
    `URL: ${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL_GENERATE_APP!}`,
  );
  const generate = httpsCallableFromURL(
    getFunctions(),
    process.env.REACT_APP_FIREBASE_FUNCTIONS_URL_GENERATE_APP!,
  );
  return generate({
    query: query,
  });
}

export async function uploadAsset(
  appId: string,
  fileName: string,
  fileData: string,
) {
  return cloudFunctions.studio_upload_asset({
    appId,
    fileName,
    fileData,
  });
}

export async function deleteAsset(appId: string, documentId: string) {
  return cloudFunctions.studio_delete_asset({
    appId,
    documentId,
  });
}

export async function uploadFont(
  appId: string,
  fileName: string,
  fileData: string,
  fontFamily: string,
  weight: number,
  style: string,
  fontType: string,
) {
  return cloudFunctions.studio_upload_font({
    appId,
    fileName,
    fileData,
    weight,
    fontFamily,
    type: style,
    fontType,
  });
}

export async function getBuildConfig(appId: string) {
  try {
    const response = await cloudFunctions.studio_build_config({ appId });
    sessionStorage.setItem("buildConfig", JSON.stringify(response.data));
  } catch (error) {
    sessionStorage.removeItem("buildConfig");
    console.error("Failed to fetch build config:", error);
  }
}

export async function downloadApp(appId: string) {
  const token = await auth.currentUser?.getIdToken();
  const response = await axios.post(
    process.env.REACT_APP_FIREBASE_FUNCTIONS_URL_STUDIO_APP_DOWNLOAD!,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        ContentType: "application/zip",
      },
      params: { appId },
      responseType: "blob",
    },
  );
  return response;
}

export async function cloneApp(appId: string, cloneCollaborators: boolean) {
  return cloudFunctions.studio_clone_app({
    appId,
    cloneCollaborators,
  });
}

export function useCreateCheckoutSession(
  productId: string,
  email: string,
  redirectUrl: string,
) {
  return useMutation(async () => {
    const generate = httpsCallableFromURL(
      getFunctions(),
      process.env.REACT_APP_FIREBASE_FUNCTIONS_CREATE_CHECKOUT_SESSION!,
    );
    const result = (await generate({
      productId,
      email,
      redirectUrl,
    })) as ICheckoutSession;
    return {
      sessionUrl: result.data?.sessionUrl,
    };
  });
}

export function useCancelSubscription(subscriptionId: string | undefined) {
  return useMutation(async () => {
    const generate = httpsCallableFromURL(
      getFunctions(),
      process.env.REACT_APP_FIREBASE_FUNCTIONS_CANCEL_SUBSCRIPTION!,
    );
    const result = (await generate({
      subscriptionId,
    })) as ICancelSubscription;
    return result.data;
  });
}

// whether the user has completed the intro questionnaire
export function getQuestionnaireStatus(
  userId: string,
): Promise<boolean | undefined> {
  return getDoc(doc(db, "users", userId)).then(
    (snapshot) => snapshot.data()?.questionnaireAttempted,
  );
}

export async function createUser(authedUser: User): Promise<string> {
  const timestamp = serverTimestamp();

  await addDoc(collection(db, "users"), {
    authId: authedUser.uid,
    email: authedUser.email,
    name: authedUser.displayName,
    questionnaireAttempted: false,
    createdAt: timestamp,
    updatedAt: timestamp,
  });

  // TODO: what if the cloud function has not completed by the time this is executed ???
  return authedUser.getIdTokenResult(true).then((token) => {
    // console.log("Claim userId: " + token.claims.userId);
    return token.claims.userId;
  });
}

// return all Subscription plans
export function useGetSubscriptionPlans() {
  return useQuery(["subscriptionPlans"], async () => {
    const q = query(
      collection(db, "subscriptionPlans"),
      orderBy("name", "asc"),
    );

    const result = await getDocs(q).then((snapshot) => {
      return snapshot.docs.map((doc) => {
        return doc.data() as ISubscriptionData;
      });
    });
    return result;
  });
}

// return all Subscription plans
export function getUserSubscriptionPlan(
  userId: string,
  callback: (data: IUserSubscriptionPlan) => void,
) {
  const q = query(
    collection(db, "users", userId, "subscriptions"),
    where("status", "in", ["Active", "Trial"]),
  );

  onSnapshot(q, (snapshot) => {
    if (!snapshot.empty) {
      const data = snapshot.docs[0].data();
      callback({
        id: snapshot.docs[0].id,
        status: data.status,
        subscriptionPlanId: data.subscriptionPlanId,
        nextPayment: data.nextPayment,
      });
    } else {
      callback({ status: "Active" });
    }
  });
}

// return all Demo Apps + Apps belong to this user
export async function getApps(userId: string): Promise<AppData[]> {
  return Promise.all([getUserApps(userId), getDemoApps(userId)]).then(
    (results) => results.flat(1),
  );
}

export function useGetApps(userId: string) {
  const queryClient = useQueryClient();
  return useQuery(
    ["apps", userId],
    async () => {
      return getApps(userId);
    },
    {
      staleTime: 60000,
      onSuccess: (data) => {
        queryClient.setQueryData(["apps", userId], data);
      },
    },
  );
}

async function getDemoApps(userId: string): Promise<AppData[]> {
  const q = query(
    AppData.collection(),
    where("category", "==", "Demo"),
    where("isArchived", "==", false),
    where("isPublic", "==", true),
  );
  return getAppsFromQuery(q, userId);
}

export async function getUserApps(userId: string): Promise<AppData[]> {
  const q = query(
    AppData.collection(),
    where("isArchived", "==", false),
    where("collaborators.users_" + userId, "in", ["read", "write", "owner"]),
  );
  return getAppsFromQuery(q, userId);
}

export async function getGeneratingApps(
  userId: string,
): Promise<GeneratingAppData[]> {
  const q = query(
    collection(db, "apps"),
    where("isAutoGenerated", "==", true),
    where("collaborators.users_" + userId, "in", ["read", "write", "owner"]),

    // TODO: we want to exclude Success from this list, but Firestore then requires index to use it.
    //  For now exclude Success manually on client
    //where("status", "!=", "Success"),
  );
  return getDocs(q).then((snapshot) =>
    snapshot.docs.map((doc) => {
      const data: DocumentData = doc.data();
      return new GeneratingAppData(doc.id, data["name"], data["status"]);
    }),
  );
}

// get the user's App by ID
export async function getApp(
  appId: string,
  userId: string,
): Promise<AppData | null> {
  // first check if this app belongs to the user
  const q = query(
    AppData.collection(),
    and(
      where(documentId(), "==", appId),
      or(
        where("collaborators.users_" + userId, "in", [
          "read",
          "write",
          "owner",
        ]),
        where("isPublic", "==", true),
        where("category", "==", "Demo"),
      ),
    ),
  );

  const apps: AppData[] = await getAppsFromQuery(q, userId);

  // if app is valid, get its artifacts
  if (apps.length !== 0) {
    const app = apps[0];
    const artifacts = await getArtifacts(doc(db, "apps", appId));
    app.theme = artifacts.theme;
    app.screens = artifacts.screens;
    app.internalWidgets = artifacts.internalWidgets;
    app.internalScripts = artifacts.internalScripts;
    app.translations = artifacts.translations;
    app.assets = artifacts.assets;
    app.fonts = artifacts.fonts;
    app.groupLabels = artifacts.groupLabels;

    return app;
  }
  return null;
}

// fetch Apps from query
async function getAppsFromQuery(q: Query<AppData>, userId: string) {
  return getDocs(q).then((snapshot) =>
    snapshot.docs.map((doc) => {
      const app: AppData = doc.data();

      // figure out my access level to this App
      app.accessType = app.collaborators?.get(userId) || AccessType.read;

      return app;
    }),
  );
}

// return all artifacts of an App.
// Note that there is no permission checks here.
// Make sure appRef belongs to the user
export async function getArtifacts(appRef: DocumentReference<DocumentData>) {
  const [artifactsSnapshot, internalArtifactsSnapshot, labelsSnapshot] =
    await Promise.all([
      getDocs(
        query(
          collection(appRef, CollectionName.artifacts),
          where("isArchived", "!=", true),
        ),
      ),
      getDocs(
        query(
          collection(appRef, CollectionName.internal_artifacts),
          where("isArchived", "!=", true),
        ),
      ),
      getDocs(
        query(
          collection(appRef, CollectionName.labels),
          where("isArchived", "!=", true),
        ),
      ),
    ]);

  const groupLabels = new Map<string, string>(
    labelsSnapshot.docs
      .sort((a, b) => a.data()?.name.localeCompare(b.data()?.name))
      .map((label) => [label.id, label.data()?.name]),
  );

  const allArtifacts = [
    ...artifactsSnapshot.docs,
    ...internalArtifactsSnapshot.docs,
  ];
  const allUserIds = allArtifacts.flatMap(
    (artifact) => (artifact.data().updatedBy as DocumentReference)?.id || [],
  );
  const uniqueUserIds = uniqBy(allUserIds, (userId) => userId);
  const userIdToDetailsMap = await getUserDetails(uniqueUserIds);

  // Initialize each artifact with `updatedBy` directly
  const initializeArtifact = (artifact: DocumentData) => {
    const artifactData = artifact.data();

    return {
      ...artifactData,
      id: artifact.id,
      updatedAt: (artifactData.updatedAt as Timestamp)?.toDate(),
      updatedBy: userIdToDetailsMap.get(artifactData.updatedBy?.id)
        ? { name: userIdToDetailsMap.get(artifactData.updatedBy?.id) }
        : undefined,
      // For Font and Asset artifacts name field is filename.
      ...(artifactData.fileName && { name: artifactData.fileName }),
      labelGroup: groupLabels.has(artifactData.labelGroup?.id)
        ? {
            id: artifactData.labelGroup.id,
            name: groupLabels.get(artifactData.labelGroup?.id) ?? "",
          }
        : undefined,
    };
  };

  const {
    fonts,
    assets,
    screens,
    theme,
    translations,
    internalWidgets,
    internalScripts,
  } = groupArtifacts(allArtifacts.map(initializeArtifact));

  return {
    screens,
    internalWidgets,
    internalScripts,
    theme,
    translations,
    assets,
    fonts,
    groupLabels,
  };
}

// Helper function to group artifacts based on their type
const groupArtifacts = (artifacts: any[]) => {
  const grouped = groupBy(artifacts, "type");

  return {
    fonts: (grouped[ArtifactType.font] || []) as EnsembleFontData[],
    assets: (grouped[ArtifactType.asset] || []) as EnsembleAssetData[],
    screens: (grouped[ArtifactType.screen] || []) as EnsembleScreenData[],
    theme: (grouped[ArtifactType.theme]?.[0] || null) as EnsembleThemeData,
    translations: (grouped[ArtifactType.i18n] ||
      []) as EnsembleTranslationData[],
    internalWidgets: (grouped[ArtifactType.internal_widget] ||
      []) as EnsembleWidgetData[],
    internalScripts: (grouped[ArtifactType.internal_script] ||
      []) as EnsembleWidgetData[],
  };
};

const getUserDetails = async (uniqueUserIDs: string[]) => {
  const userSnapshots = await Promise.all(
    uniqueUserIDs.map((userID) => getDoc(doc(db, "users", userID))),
  );

  // Create a UserId -> UserName lookup
  return new Map(
    uniqueUserIDs.map((key, index) => [key, userSnapshots[index].data()?.name]),
  );
};

export function useRemoveCollaborator(
  app_id: string,
  user_id: string | undefined,
) {
  const queryClient = useQueryClient();
  const leaveApp = httpsCallableFromURL(
    getFunctions(),
    process.env.REACT_APP_FIREBASE_FUNCTIONS_URL_LEAVE_APP!,
  );

  return useMutation(
    async () => {
      await leaveApp({ appId: app_id, userId: user_id });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("apps");
        queryClient.invalidateQueries(["app", app_id]);
        queryClient.invalidateQueries(["user", user_id]);
        queryClient.invalidateQueries(["userApps", user_id]);
      },
      onError: (error) => {
        console.log("error" + error);
      },
    },
  );
}

export function useMergeApps(
  sourceAppId: string,
  destinationAppId: string,
  userId: string,
  changeset: Changeset, // additional parameter to include the changeset as a JSON object
) {
  // Define the mergeApps function
  const mergeApps = httpsCallableFromURL(
    getFunctions(),
    process.env.REACT_APP_FIREBASE_FUNCTIONS_URL_STUDIO_MERGE_APPS!,
  );

  return useMutation(
    async () => {
      const response = await mergeApps({
        sourceAppId: sourceAppId,
        destinationAppId: destinationAppId,
        userId: userId,
        changeset: changeset, // passing the changeset to the function call
      });
      return response;
    },
    {
      onSuccess: (data) => {
        // Print the JSON response to the console
        console.log("Success:", data);
      },
      onError: (error) => {
        console.log("Error: " + error);
      },
    },
  );
}

export function useGetPrepublishReport(
  sourceAppId: string,
  destinationAppId: string,
  userId: string,
) {
  // Define the getPrepublishReport function
  const getPrepublishReport = httpsCallableFromURL(
    getFunctions(),
    process.env.REACT_APP_FIREBASE_FUNCTIONS_URL_GET_PREPUBLISH_REPORT!,
  );

  return useMutation(
    async () => {
      console.log(
        "about to call getPrepublishReport with source_appid: " +
          sourceAppId +
          " destination_appid: " +
          destinationAppId +
          " user_id: " +
          userId,
      );
      const response = await getPrepublishReport({
        sourceAppId: sourceAppId,
        destinationAppId: destinationAppId,
        userId: userId,
      });
      return response;
    },
    {
      onSuccess: (data) => {
        // Print the JSON response to the console
        console.log("Success:", data);
      },
      onError: (error) => {
        console.log("Error: " + error);
      },
    },
  );
}

export function useUpdateCollaboratorRole(
  app_id: string,
  user_id: string | undefined,
  accessType: AccessType,
) {
  const queryClient = useQueryClient();
  const userKey = "collaborators.users_" + user_id;
  return useMutation(
    async () => {
      const appRef = doc(db, "apps", app_id);

      await updateDoc(appRef, {
        [userKey]: accessType,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["app", app_id]);
      },
      onError: (error) => {
        console.log("error" + error);
      },
    },
  );
}

export function useInviteCollaborator(
  appId: string,
  appName: string | undefined,
  userId: string | undefined,
  userName: string | undefined | null,
  inviteeAccessType: AccessType,
  inviteeEmail: string,
) {
  return useMutation(
    async () => {
      const inviteRef = collection(db, "invites");
      const ref = crypto.randomUUID();
      await addDoc(inviteRef, {
        to: inviteeEmail,
        template: {
          name: "appInvite",
          data: {
            ref: ref,
            appId: appId,
            appName: appName,
            userId: userId,
            userName: userName,
            inviteeEmail: inviteeEmail,
            inviteeAccessType: inviteeAccessType,
          },
        },
        ref: ref,
        status: "pending",
      });
    },
    {
      onError: (error) => {
        console.log("error" + error);
      },
    },
  );
}

export function useSaveQuestionnaireFeedback(
  data: QuestionnaireData,
  userId: string | undefined,
) {
  const feedbackRef = collection(db, "questionnaireFeedback");
  const userRef = doc(db, "users", userId!);

  const timestamp = serverTimestamp();

  return useMutation(async () => {
    await addDoc(feedbackRef, {
      ...data,
      createdAt: timestamp,
      user: userRef,
    });
    await updateDoc(userRef, {
      questionnaireAttempted: true,
    });
  });
}

export function useGetInvite(ref: string | undefined) {
  return useQuery(
    ["invite", ref],
    async () => {
      const q = query(collection(db, "invites"), where("ref", "==", ref));

      const result = await getDocs(q).then((snapshot) => {
        const invite = snapshot.docs[0];
        return {
          data: invite.data(),
        };
      });
      return result.data;
    },
    {
      enabled: !!ref,
    },
  );
}

export function useGetInvitedCollaborators(appId: string) {
  return useQuery(
    ["invitedCollaborators", appId],
    async () => {
      const q = query(
        collection(db, "invites"),
        where("template.data.appId", "==", appId),
      );

      const result = await getDocs(q).then((snapshot) => {
        return snapshot.docs.map((doc) => {
          return {
            data: doc.data(),
          };
        });
      });
      return result;
    },
    {
      enabled: !!appId,
    },
  );
}

export function useAddCollaborator(
  appId: string,
  userId: string | undefined,
  accessType: AccessType,
) {
  const queryClient = useQueryClient();
  const userKey = "collaborators.users_" + userId;
  return useMutation(
    async () => {
      const appRef = doc(db, "apps", appId);

      await updateDoc(appRef, {
        [userKey]: accessType,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["app", appId]);
      },
      onError: (error) => {
        console.log("error" + error);
      },
    },
  );
}

/// get the App's BaseURL configuration
export async function getAppConfig(
  appId: string,
): Promise<AppConfigData | null> {
  const snapshot = await getDocs(
    query(
      collection(doc(db, "apps", appId), CollectionName.artifacts),
      where("isRoot", "==", true),
      where("type", "==", "config"),
    ).withConverter(AppConfigData.converter),
  );
  if (!snapshot.empty) {
    return snapshot.docs[0].data() as AppConfigData;
  }
  return null;
}

export async function updateAppConfig(
  appId: string,
  userId: string,
  baseUrl: string,
  useBrowserUrl?: boolean,
) {
  const timestamp = serverTimestamp();
  const userRef = doc(db, "users", userId);
  const appConfigRef = doc(
    db,
    "apps",
    appId,
    CollectionName.artifacts,
    CONFIG_ID,
  );
  const appConfig = await getDoc(appConfigRef);

  // add or overwrite existing config
  await setDoc(
    appConfigRef,
    {
      isRoot: true,
      isArchived: false,
      type: "config",
      baseUrl: baseUrl,
      useBrowserUrl: useBrowserUrl ?? null, // firebase only understand null, not undefined
      updatedBy: userRef,
      updatedAt: timestamp,
      ...(!appConfig.exists()
        ? { createdBy: userRef, createdAt: timestamp }
        : {}),
    },
    {
      merge: true,
    },
  );
}

export async function addEnvironmentVariable(
  appId: string,
  userId: string,
  envKey: string,
  envValue: unknown,
) {
  const timestamp = serverTimestamp();
  const userRef = doc(db, "users", userId);
  const appConfigRef = doc(
    db,
    "apps",
    appId,
    CollectionName.artifacts,
    CONFIG_ID,
  );
  const appConfig = await getDoc(appConfigRef);

  await setDoc(
    appConfigRef,
    {
      isRoot: true,
      isArchived: false,
      type: "config",
      envVariables: {
        [envKey]: envValue, // set key using the variable
      },
      updatedBy: userRef,
      updatedAt: timestamp,
      ...(!appConfig.exists()
        ? { createdBy: userRef, createdAt: timestamp }
        : {}),
    },
    {
      merge: true,
    },
  );
}

export async function deleteEnvironmentVariable(
  appId: string,
  userId: string,
  envKey: string,
) {
  const userRef = doc(db, "users", userId);
  await updateDoc(doc(db, "apps", appId, CollectionName.artifacts, CONFIG_ID), {
    [`envVariables.${envKey}`]: deleteField(), // deleting nested field
    updatedBy: userRef,
    updatedAt: serverTimestamp(),
  });
}

export async function getSecrets(appId: string): Promise<AppSecrets | null> {
  const snapshot = await getDocs(
    query(
      collection(doc(db, "apps", appId), CollectionName.artifacts),
      where("type", "==", "secrets"),
    ).withConverter(AppSecrets.converter),
  );
  if (!snapshot.empty) {
    return snapshot.docs[0].data() as AppSecrets;
  }
  return null;
}

export async function addSecret(
  appId: string,
  userId: string,
  key: string,
  value: string,
) {
  const timestamp = serverTimestamp();
  const userRef = doc(db, "users", userId);
  const secretsRef = doc(
    db,
    "apps",
    appId,
    CollectionName.artifacts,
    SECRETS_ARTIFACT_NAME,
  );
  const secrets = await getDoc(secretsRef);

  await setDoc(
    secretsRef,
    {
      isRoot: true,
      isArchived: false,
      type: "secrets",
      secrets: {
        [key]: value, // set key using the variable
      },
      updatedBy: userRef,
      updatedAt: timestamp,
      ...(!secrets.exists()
        ? { createdBy: userRef, createdAt: timestamp }
        : {}),
    },
    {
      merge: true,
    },
  );
}

export async function removeSecret(appId: string, userId: string, key: string) {
  const userRef = doc(db, "users", userId);
  await updateDoc(
    doc(db, "apps", appId, CollectionName.artifacts, SECRETS_ARTIFACT_NAME),
    {
      [`secrets.${key}`]: deleteField(), // deleting nested field
      updatedBy: userRef,
      updatedAt: serverTimestamp(),
    },
  );
}

export const getLabelRef = (labelId: string) => {
  return doc(db, "labels", labelId);
};
