import { isMap, isPair, Pair, Scalar, YAMLMap, Document, Node } from "yaml";
import { assocPath, Path } from "ramda";
import { find } from "../../../utils/docUtils";
/**
 * When working with nested Tree/PropertyPanel for widgets/actions, we only
 * have the formData which knows nothing about its ancestors, which might not
 * have exist yet (we only update the model when something is created). For this
 * reason we need the entire paths to the root selected widget to build the
 * document tree to our widget/action.
 */
export class RootWidgetContext {
  constructor(formContext: any, idPath: string) {
    // root can be "Button" or "Button: ..."
    this.rootNode = formContext.node;
    // the ID looks like "root_backgroundImage_fallback" or "root_actions_2"
    this.paths = this._parsePath(idPath);
    this.rootPrefix =
      formContext.activeCategory === "Styles"
        ? formContext.activeCategory.toLowerCase()
        : null;
  }
  readonly rootNode: Scalar | Pair;
  // the entire paths from the root selected widget to the current RJSF Field/widget
  readonly paths: Path;
  // prefix to use (e.g. "styles" or "actions") if we are in a nested category
  readonly rootPrefix: string | null;

  /**
   * Parse the idSchema's id to get the entire paths from the root to the field itself.
   * Skip the first item as it is always root.
   */
  _parsePath(path: string): Path {
    return path
      .split("_")
      .slice(1)
      .map((p) => {
        const match = p.match(/^(\d+)$/);
        if (match) {
          return parseInt(match[1], 10);
        }
        return p;
      });
  }

  // traverse the paths from the root and return leaf node
  getLeafNode(): Node | null | undefined {
    const node = this._getStartingMap();
    if (node) {
      return find(this.paths, node);
    }
  }

  // return the node given a path
  findNode(path: Path): Node | null | undefined {
    const node = this._getStartingMap();
    if (node) {
      return find(path, node);
    }
  }

  /**
   * Add a new Node's key/value to the leaf node our rootNode.
   * We will walk the entire paths and create the nodes as needed.
   * This will also handle override existing value (if specified)
   */
  addLeafNode(doc: Document, key: string, value: Scalar | YAMLMap | null) {
    // the starting map might not event exist, so we have to adjust the paths
    const updatedPaths = this.rootPrefix
      ? [this.rootPrefix, ...this.paths]
      : [...this.paths];
    const rootNodeJSON = this.rootNode.value
      ? (this.rootNode.value as Node).toJSON()
      : null;
    this.rootNode.value = doc.createNode(
      assocPath(updatedPaths, doc.createNode({ [key]: value }), rootNodeJSON),
    );
  }

  /**
   * Returns the starting Map that can be used to traverse the paths.
   * This will account for the prefix.
   */
  private _getStartingMap(): YAMLMap | undefined {
    if (isPair(this.rootNode) && isMap(this.rootNode.value)) {
      if (this.rootPrefix) {
        const node = this.rootNode.value.get(this.rootPrefix);
        if (isMap(node)) {
          return node;
        }
      } else {
        return this.rootNode.value;
      }
    }
  }

  // get the property name, which should be the last item in the path
  getPropertyName(): string | null {
    return this.paths.length > 0
      ? String(this.paths[this.paths.length - 1])
      : null;
  }
}
