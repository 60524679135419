import { groupBy, sortBy } from "lodash-es";
import { GroupedListItem } from "../../config/interfaces";
import { CardGroupOptions, CardSortOptions } from "../../utils/constants";

export const sortCardListBy = <S>(list: any[], value: CardSortOptions): S[] => {
  if (!list.length) return [];

  switch (value) {
    case CardSortOptions.Name:
      return sortBy(list, [(item) => item.name]);
    case CardSortOptions.Time:
      return sortBy(list, [
        // First sort by whether `updatedAt` exists or not (undefined values will be placed last)
        (item) => (item.updatedAt ? -1 : 1),
        // Sort by `updatedAt` in descending order (most recent first)
        (item) => -new Date(item.updatedAt).getTime(),
      ]);
    case CardSortOptions.User:
      return sortBy(list, [
        // First sort by whether `updatedBy` exists or not (undefined values will be placed last)
        (item) => (item.updatedBy ? 0 : 1),
        (item) => item.updatedBy?.name,
      ]);
    default:
      return list;
  }
};

export const groupCardListBy = <S>(
  list: S[],
  groupOption: CardGroupOptions,
  sortOption: CardSortOptions,
): GroupedListItem<S>[] => {
  if (!list.length) return [];

  let groupedList;

  switch (groupOption) {
    case CardGroupOptions.Labels:
      groupedList = groupBy(list, "labelGroup.name");
      break;
    case CardGroupOptions.None:
    default:
      groupedList = groupBy(list, "undefined");
  }

  // Convert the Dictionary to Array type
  groupedList = Object.entries(groupedList)
    .map(([key, items]) => ({
      key: key === "undefined" ? null : key,
      value: sortCardListBy<S>(items, sortOption),
    }))
    .sort((a, b) => (a.key ?? "").localeCompare(b.key ?? "")); // Sort the label groups by name.

  return groupedList;
};
