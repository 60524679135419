import React, { FormEvent, useCallback, useMemo } from "react";
import { isMap, isPair, isScalar, visit, Node, isSeq } from "yaml";
import { getNodeName } from "../../utils/docUtils";
import "./ActionPropertyPanel.sass";
import { PropertySetForm } from "../WidgetPropertyPanel/PropertySetForm";
import { useSchemas } from "../../hooks/useSchemas";
import { useYamlDoc } from "../../hooks/useYamlDoc";
import {
  generateUiSchema,
  updateNodeWithHex,
} from "../WidgetPropertyPanel/utils/propertyPanelSchemaUtils";
import { IChangeEvent } from "@rjsf/core";
import { RemixIcon } from "../../components/Widgets";
import { ActionMenu } from "../../components/VisualEditor/ActionMenu";
import {
  camelCaseToWords,
  camelCaseToWordsExceptFirstLetter,
} from "../WidgetPropertyPanel/utils/propertyPanelUtils";
import { useAppContext } from "../../pages/AppPagesWrapper";
import { ActionNodeData } from "../../components/VisualEditor/navigator/ActionTreeBuilder";

interface ActionPropertyPanelProps {
  // note this is the Event pair (essentially the parent of the Action pair) e.g. onTap -> invokeAPI
  // we need this to modify the Action even when the Action is just a Scalar
  actionPayload: ActionNodeData;
  onItemUpdate: (actionData: ActionNodeData) => void;
  onItemRemove: (actionData: ActionNodeData) => void;
  rootNode: Node;
}

export const ActionPropertyPanel: React.FC<ActionPropertyPanelProps> = ({
  actionPayload,
  onItemUpdate,
  onItemRemove,
  rootNode,
}) => {
  const { app } = useAppContext();
  const { findActionSchema } = useSchemas();
  const {
    doc,
    updateNode,
    removeNode,
    addNode,
    setSelectedNode,
    dispatchVisualEditorChanges,
    nonEditorChanges,
  } = useYamlDoc();

  const { actionName, actionPair, actionValueMap, schema, uiSchema } =
    useMemo(() => {
      const actionName = getNodeName(actionPayload.actionNode) ?? "";

      // ok to be undefined. We still handle action as Scalar on submit
      const actionPair = isPair(actionPayload.actionNode)
        ? actionPayload.actionNode
        : undefined;

      const actionValueMap = isMap(actionPair?.value)
        ? actionPair!.value
        : null;

      const schema = findActionSchema(actionName) ?? {};
      const uiSchema = generateUiSchema(schema, false);

      return { actionName, actionPair, actionValueMap, schema, uiSchema };
      // when formData is changed outside of the panel (e.g. pick a widget from ActionPropertyPanle),
      // we need to recalculate the data, hence listening to nonEditorChanges below
    }, [actionPayload, findActionSchema, nonEditorChanges]); // eslint-disable-line

  // when the user selects an Action from the ActionPicker, connecting the Action to the Event
  // We need to notify the parent since both the Tree and this component needs to reload
  // const handleActionSelect = (actionName: string) => {
  //   const actionNode = doc?.createNode({
  //     [actionName]: new Scalar(null),
  //   }) as YAMLMap;
  //   let newEventPair: Pair<Scalar, Scalar | YAMLMap> | null;
  //   // if the eventPair is just a placeholder, ignore the eventPair and construct the paths from the root
  //   if (eventNode.placeholderContext) {
  //     newEventPair = addChildActionToContext(
  //       eventNode.placeholderContext,
  //       actionNode,
  //     );
  //   } else {
  //     eventPair.value = actionNode;
  //     newEventPair = eventPair;
  //   }
  //   if (newEventPair) {
  //     dispatchVisualEditorChanges();
  //
  //     onItemUpdate({
  //       eventPair: newEventPair,
  //       parent: eventNode.parent,
  //     });
  //   }
  // };

  const onSubmit = useCallback<(data: IChangeEvent, event: FormEvent) => void>(
    ({ formData }) => {
      if (!doc) return;

      const mergedData = {
        ...(actionValueMap?.toJSON() ?? {}),
        ...formData,
      };
      const updatedNode = doc.createNode(mergedData);
      const updatedNodeWithHex = updateNodeWithHex(updatedNode);
      if (updatedNodeWithHex) {
        // if the action node is already a Pair, simply replaces its value
        if (actionPair) {
          actionPair.value = updatedNodeWithHex;
        }
        // else if it's a Scalar (e.g. navigateBack), replace it with a new Map
        else if (isScalar(actionPayload.actionNode)) {
          const actionMap = doc.createNode({
            [actionName]: updatedNodeWithHex,
          });
          visit(rootNode, {
            Scalar: (key, node, path) => {
              // replace this Scalar with [actionName]: updatedNodeWithHex
              if (node === actionPayload.actionNode && path.length > 0) {
                // found our Scalar node, now figure out how to replace it
                const parent = path[path.length - 1] as Node;
                if (isMap(parent)) {
                  parent.set(key, actionMap);
                } else if (isPair(parent)) {
                  parent.value = actionMap;
                } else if (isSeq(parent)) {
                  const foundIndex = parent.items.findIndex((item, index) => {
                    if (item === actionPayload.actionNode) {
                      return index;
                    }
                  });
                  if (foundIndex !== -1) {
                    parent.items[foundIndex] = actionMap;
                  }
                }
              }
            },
          });
        }
        dispatchVisualEditorChanges();
        onItemUpdate(actionPayload);
      }
    },
    [
      actionName,
      actionPair,
      actionPayload,
      actionValueMap,
      dispatchVisualEditorChanges,
      doc,
      onItemUpdate,
      rootNode,
    ],
  );

  return (
    <div className={"action-property-panel"}>
      <div className={"action-property-panel-header"}>
        <div className={"action-property-panel-header-title"}>
          <div>
            {(actionPayload.subTitle &&
              camelCaseToWordsExceptFirstLetter(actionPayload.subTitle)) ??
              ""}
          </div>
          <div>{camelCaseToWords(actionName)}</div>
        </div>

        <ActionMenu
          anchor={
            <div className={"action-icon"}>
              <RemixIcon name={"more-2-fill"} />
            </div>
          }
          items={[
            {
              iconName: "delete-bin-2-line",
              label: "Remove this Action",
              value: "delete",
            },
          ]}
          onSelect={(value) => {
            if (value === "delete") {
              onItemRemove(actionPayload);
            }
          }}
        />
      </div>

      <div>
        <PropertySetForm
          schema={schema}
          uiSchema={uiSchema}
          formData={actionValueMap?.toJSON() ?? {}}
          formContext={{
            doc,
            node: actionPair,
            // activeCategory,
            // platformWidgets: schemaProps?.widgets,
            // customWidgets: Array.from(customWidgets).map((widget) => ({
            //   key: widget,
            // })),
            appData: app,
            addNode,
            setSelectedNode,
            removeNode,
            updateNode,
          }}
          onFormSubmit={onSubmit}
        />
      </div>
    </div>
  );
};
