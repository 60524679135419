export const AppRoutePattern = "/app/:appId/:page";

export enum CardSortOptions {
  User = "User",
  Time = "Time",
  Name = "Name",
}

export enum CardGroupOptions {
  None = "None",
  Labels = "Labels",
}

export enum CollectionName {
  labels = "labels",
  artifacts = "artifacts",
  internal_artifacts = "internal_artifacts",
}
