import React, { Key, useEffect, useMemo, useState } from "react";
import { RootWidgetContext } from "../../../containers/WidgetPropertyPanel/fields/RootWidgetContext";
import { useSchemas } from "../../../hooks/useSchemas";
import { Tree } from "antd";
import { buildTreeData, findNodeById } from "./NavigatorModel";
import { ActionNodeData, ActionTreeBuilder } from "./ActionTreeBuilder";
import "./Navigator.sass";

interface ActionNavigatorProps {
  nodeContext: RootWidgetContext;
  selectedItem?: ActionNodeData;
  setSelectedItem: (item: ActionNodeData) => void;
}

export const ActionNavigator: React.FC<ActionNavigatorProps> = ({
  nodeContext,
  setSelectedItem,
}) => {
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
  const { actionSchemaMap, actionChildrenOverrides } = useSchemas();

  const navigatorData = useMemo(() => {
    return new ActionTreeBuilder(
      nodeContext,
      actionSchemaMap,
      actionChildrenOverrides,
    ).build();
  }, [actionChildrenOverrides, actionSchemaMap, nodeContext]);

  const treeData = useMemo(() => {
    if (!navigatorData) return [];
    return buildTreeData(navigatorData, (node) => {
      return (
        <div className={"action-tree-item"}>
          {node.subtitle && (
            <div className={"action-tree-item-subtitle"}>{node.subtitle}</div>
          )}
          <div className={"action-tree-item-title-group"}>{node.title}</div>
        </div>
      );
    });
  }, [navigatorData]);

  // handle selection when data changes
  useEffect(() => {
    if (!treeData.length) return;

    // select the first item if none is selected
    if (selectedKeys.length === 0) {
      setSelectedKeys([treeData[0].key]);
      setSelectedItem(treeData[0].data);
    }
    // re-select the item if was selected
    else if (navigatorData) {
      const found = findNodeById(navigatorData, selectedKeys[0] as string);
      if (found) {
        setSelectedItem(found.data);
      }
    }
  }, [navigatorData, selectedKeys, setSelectedItem, treeData]);

  const handleSelect = (
    keys: React.Key[],
    event: { node: { key: Key; data: ActionNodeData } },
  ) => {
    // disallow un-select
    if (selectedKeys.includes(event.node.key)) {
      return;
    }
    setSelectedKeys(keys);
    setSelectedItem(event.node.data);
  };

  // return useTree ? (
  return (
    <div className={"base-tree action-tree"}>
      <Tree
        treeData={treeData}
        onSelect={handleSelect}
        showLine={false}
        defaultExpandAll={true}
        autoExpandParent={true}
        selectedKeys={selectedKeys}
      />
    </div>
  );
  // ) : (
  //   <div>TODO: FLow Chart</div>
  // );
};
