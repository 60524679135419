/* eslint-disable @typescript-eslint/no-explicit-any */
import { type ReactNode } from "react";
import { AppData } from "../models/model";
import type { DocumentReference, Timestamp } from "firebase/firestore";

interface EnsembleBaseData {
  readonly id: string;
  readonly name: string;
  readonly isArchived: boolean;
  readonly updatedAt?: Date;
  readonly updatedBy?: {
    name: string;
  };
  readonly labelGroup?: DocumentReference | labelGroupData | null;
}

export interface EnsembleTranslationData extends EnsembleBaseData {
  readonly type: string;
  readonly isRoot: boolean;
  readonly content?: string;
  readonly defaultLocale?: boolean;
}

interface EnsembleData extends EnsembleBaseData {
  readonly isRoot: boolean;
  readonly description?: string;
  readonly content?: string;
  readonly isDraft?: boolean;
  readonly category?: string;
}

export interface EnsembleScreenData extends EnsembleData {
  readonly label?: string;
  readonly comment?: string;
}

export interface EnsembleWidgetData extends EnsembleBaseData {
  readonly isRoot: boolean;
  description?: string;
  content?: string;
}

export interface EnsembleAssetData extends EnsembleBaseData {
  readonly content: string;
  readonly copyText: string;
  readonly publicUrl: string;
}

export interface EnsembleFontData extends EnsembleBaseData {
  readonly fontType: string;
  readonly fontStyle: string;
  readonly fontFamily: string;
  readonly fontWeight: string;
}

export class EnsembleThemeData {
  constructor(
    readonly content: string,
    readonly id: string,
    readonly isArchived: boolean,
  ) {}
}

export class EnsembleNewThemeData {
  constructor(
    readonly content: string,
    readonly isArchived: boolean,
  ) {}
}

export interface EnsembleNewScreenData
  extends Omit<EnsembleScreenData, "id" | "labelGroup"> {
  readonly type?: string;
  readonly labelGroup?: string;
}

export interface EnsembleNewWidgetData
  extends Omit<EnsembleWidgetData, "id" | "labelGroup"> {
  readonly type: string;
  readonly labelGroup?: string;
}

export class EnsembleArtifactHistoryData {
  constructor(
    readonly id: string,
    readonly content: string,
    readonly label: string,
    readonly comment: string,
    readonly updatedAt: Timestamp,
    readonly updatedBy: {
      name?: string;
    },
  ) {}
}

export class EnsembleCategoriesData {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly type: string,
    readonly isActive: boolean,
  ) {}
}

export interface labelGroupData {
  id: string;
  name: string;
}

export interface IEnsembleUserData {
  auth_id: string;
  id: string;
  name: string | null;
  email: string | null;
  questionnaireAttempted: boolean | null;
  avatar: string | null;
  subscriptionPlan: {
    id: string;
    status: string;
    subscriptionPlanId: string;
    nextPayment: Timestamp;
    planDetails: ISubscriptionData;
  };

  user_apps?: [
    {
      id: string;
      app_role: string | null;
    },
  ];
}

export interface ICheckoutSession {
  data: {
    sessionUrl: string;
  };
}

export interface ICancelSubscription {
  data: {
    message: string;
    subscriptionPlan: any;
  };
}

export interface IUserSubscriptionPlan {
  id?: string;
  status: string;
  subscriptionPlanId?: string;
  nextPayment?: Timestamp;
}

export interface ISubscriptionData {
  name: string;
  price: string;
  currency: string;
  duration: string;
  features: Array<string>;
  stripeProductId: string;
}

export enum AppCategory {
  DEMO = "Demo",
}

export interface IFirebaseUser {
  uid: string;
  email: string | null;
  displayName: string | null;
}

export interface IDropdownItem {
  id: string;
  text: string | ReactNode;
  label?: string;
  comment?: string;
  clickHandler: () => boolean | void;
}

export interface KeyValuePair {
  key: string;
  value: string;
}

export interface IPreviewSize {
  width: number;
  height: number;
}

export interface AppCreationCategory {
  key: string;
  value: string;
}

export interface IAuthContext {
  currentUser: IEnsembleUserData | null;
  setCurrentUser?: any;
}

export interface IsUpdateScreenContext {
  isUpdateScreenLoading: boolean;
  setIsUpdateScreenLoading?: any;
}

export interface IAppDataContext {
  app: AppData | null;
  setAppData?: any;
  isAppReadOnly: boolean;
  setIsAppReadOnly?: any;
  isDemoApp: boolean;
  setIsDemoApp?: any;
  isPermissionChecked: boolean;
  setIsPermissionChecked?: any;
  resetAppData?: any;
}

export interface INavDataContext {
  appData: AppData | null;
  setAppData?: any;
}

export interface IAppInviteData {
  ref: string;
  appId: string;
  appName: string;
  userId: string;
  userName: string;
  inviteeEmail: string;
  inviteeAccessType: string;
}

export interface QuestionProps {
  title: string;
  options?: string[];
  allowOther?: boolean;
  type: "input" | "radio";
  onSubmit: (arg: string) => void;
}

export interface PublishingHistory {
  publishedTo: string;
  createdAt: Timestamp;
  publishedBy: string;
  publishingLog: string;
  role: string;
}

export interface GroupedListItem<S> {
  value: S[];
  key: string | null;
}
