import { useEffect, useState } from "react";
import $RefParser from "@apidevtools/json-schema-ref-parser";
import { JSONSchema7 } from "json-schema";
import { EnsembleSchema } from "./useSchemas";

export const useGetThemeSchema = () => {
  const [schema, setSchema] = useState<EnsembleSchema | undefined>();

  useEffect(() => {
    const loadSchema = async () => {
      try {
        const schemaUrl =
          "https://raw.githubusercontent.com/EnsembleUI/ensemble/main/modules/ensemble/assets/schema/theme_schema.json";
        const loadedSchema = (await $RefParser.dereference(
          schemaUrl,
        )) as JSONSchema7;

        // If the schema is successfully loaded and parsed, update the state
        setSchema({
          uri: schemaUrl,
          fileMatch: ["*"],
          schema: loadedSchema,
        });
      } catch (err) {
        console.error("Failed to load and parse schema", err);
      }
    };

    // Call the loadSchema function
    loadSchema();
  }, []); // Ensure the effect runs only once on component mount

  return schema;
};
