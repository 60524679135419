import { type FC, useState } from "react";
import { CardGroupOptions } from "../utils/constants";
import LabelActionBar from "../components/LabelActionBar";
import useCardListOperations from "../hooks/useCardListOperations";
import CardCollapseGroup from "../components/cards/CardCollapseGroup";
import type {
  EnsembleScreenData,
  EnsembleWidgetData,
} from "../config/interfaces";

interface AppBodyContainerProps {
  appId: string;
  cardName: string; // Card data prop name i.e. screen, widget and script
  isReadOnly: boolean;
  CardComponent: FC<any>; // Pass the card component dynamically
  dataNotFound: string;
  cardProps?: Record<string, any>; // Optional additional props to pass to the card
  itemList?: (EnsembleScreenData | EnsembleWidgetData)[];
}

const AppBodyContainer = ({
  appId,
  cardName,
  itemList,
  cardProps,
  isReadOnly,
  CardComponent,
  dataNotFound,
}: AppBodyContainerProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { sortedList, groupedList, groupTrigger, sortTrigger } =
    useCardListOperations(itemList ?? []);

  return (
    <>
      {itemList?.length ? (
        <>
          <LabelActionBar
            className="app-labels"
            sortTrigger={sortTrigger}
            groupTrigger={groupTrigger}
            setIsExpanded={setIsExpanded}
            setIsCollapsed={setIsCollapsed}
          />

          {groupTrigger.value === CardGroupOptions.None ? (
            <div className="screen-cards-ct">
              {sortedList.map((item) => (
                <CardComponent
                  key={item.id}
                  {...cardProps}
                  appId={appId}
                  isReadOnly={isReadOnly}
                  {...{ [cardName]: item }}
                />
              ))}
            </div>
          ) : (
            <>
              {!!groupedList.length && (
                <CardCollapseGroup
                  appId={appId}
                  cardName={cardName}
                  isExpanded={isExpanded}
                  isReadOnly={isReadOnly}
                  isCollapsed={isCollapsed}
                  groupedList={groupedList}
                  CardComponent={CardComponent}
                  cardProps={cardProps}
                />
              )}
            </>
          )}
        </>
      ) : (
        <span>{dataNotFound}</span>
      )}
    </>
  );
};

export default AppBodyContainer;
