import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAppContext } from "../pages/AppPagesWrapper";

// Components
import ScriptEditor from "./ScriptEditor";

// APIs
import { AuthContext } from "../App";
import { useUpdateWidget } from "../hooks/useFirebase";

import "../styles/breadcrumbs.sass";

const AppScript: React.FunctionComponent = () => {
  const params = useParams();
  const { app } = useAppContext();
  const { currentUser } = useContext(AuthContext);

  const scriptId = params.script_id;

  const updateScriptQuery = useUpdateWidget(
    currentUser,
    app.id,
    scriptId ?? "",
  );

  const screenDetails = useMemo(() => {
    return app.internalScripts?.find((script) => script.id === scriptId);
  }, [app.internalScripts, scriptId]);

  const onSave = useCallback(
    (content: string) => {
      updateScriptQuery.mutate({ content });
    },
    [updateScriptQuery],
  );

  useEffect(() => {
    document.title = app.name + " script";
  });

  return (
    <div className="script-screen">
      {!screenDetails?.id && <p>loading...</p>}

      {screenDetails?.id && (
        <ScriptEditor
          disableButton={updateScriptQuery.isLoading}
          onSave={onSave}
          screen={screenDetails}
        />
      )}
    </div>
  );
};
export default AppScript;
