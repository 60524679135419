import { DataNode } from "rc-tree/lib/interface";
import { Node as FlowNode, Edge as FlowEdge } from "reactflow";
import React from "react";

// generic NavigatorNode for use with tree or flowchart
export interface NavigatorNode<D extends NodeData> {
  title: string;
  subtitle?: string;
  children?: NavigatorNode<D>[];

  data: D;
  actions?: NodeAction[];
}
export interface NodeData {
  // use as key as well as for selection
  id: string;
}
export interface NodeAction {
  key: string;
  label: string;
}

// TreeNode for use with Tree
export type TreeNode<D extends NodeData> = DataNode & {
  data: D;
};

// find a node by ID
export const findNodeById = <D extends NodeData>(
  nodes: NavigatorNode<D>[],
  id: string,
): NavigatorNode<D> | undefined => {
  for (const node of nodes) {
    if (node.data.id === id) {
      return node;
    }
    if (node.children) {
      const found = findNodeById(node.children, id);
      if (found) {
        return found;
      }
    }
  }
  return undefined;
};

export const buildTreeData = <D extends NodeData>(
  data: NavigatorNode<D>[],
  renderNode: (node: NavigatorNode<D>) => React.ReactNode,
): TreeNode<D>[] => {
  return data.map((node) => ({
    key: node.data.id,
    title: renderNode(node),
    data: node.data,
    children: node.children
      ? buildTreeData(node.children, renderNode)
      : undefined,
  }));
};

export const buildFlowData = <D extends NodeData>(
  data: NavigatorNode<D>[],
  renderNode: (node: NavigatorNode<D>) => React.ReactNode,
) => {
  return _buildFlowData<D>(data, renderNode);
};

const _buildFlowData = <D extends NodeData>(
  data: NavigatorNode<D>[],
  renderNode: (node: NavigatorNode<D>) => React.ReactNode,
  parentId: string | null = null,
  x: number = 0,
  y: number = 0,
): { nodes: FlowNode[]; edges: FlowEdge[] } => {
  const nodes: FlowNode[] = [];
  const edges: FlowEdge[] = [];

  data.forEach((item, index) => {
    const nodeId = item.data.id;
    nodes.push({
      id: nodeId,
      type: "customNode",
      data: {
        label: item.title,
        subtitle: item.subtitle,
        actions: item.actions || [],
      },
      position: { x: x + index * 200, y: y },
    });

    if (parentId) {
      edges.push({
        id: `e${parentId}-${nodeId}`,
        source: parentId,
        target: nodeId,
      });
    }

    if (item.children) {
      const { nodes: childNodes, edges: childEdges } = _buildFlowData(
        item.children,
        renderNode,
        nodeId,
        x + index * 200,
        y + 100,
      );
      nodes.push(...childNodes);
      edges.push(...childEdges);
    }
  });

  return { nodes, edges };
};
