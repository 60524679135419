import React, { useState, useEffect, useRef, useCallback } from "react";
import * as monaco from "monaco-editor";

import { useAppContext } from "../pages/AppPagesWrapper";

// Interfaces
import {
  EnsembleArtifactHistoryData,
  EnsembleScreenData,
} from "../config/interfaces";
import { getDateTime } from "../utils";
import { useLocation } from "react-router-dom";
import ScriptHeader from "./ScriptHeader";

interface IScriptEditorProps {
  screen: EnsembleScreenData;
  disableButton: boolean;
  onSave: (content: string) => void;
}

const editorConfig: monaco.editor.IStandaloneDiffEditorConstructionOptions = {
  automaticLayout: true,
  theme: "vs-dark",
  minimap: {
    enabled: false,
  },
  quickSuggestions: {
    other: true,
    strings: true,
  },
  scrollbar: {
    vertical: "hidden",
    horizontal: "hidden",
  },
  wordWrap: "off",
  fontSize: 14,
};

const ScriptEditor: React.FC<IScriptEditorProps> = ({
  screen,
  onSave,
  disableButton,
}) => {
  const { isAppReadOnly } = useAppContext();
  const containerElement = useRef<HTMLDivElement>(null);
  const diffEditorRef = useRef<HTMLDivElement>(null);
  const editor = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const line = queryParams.get("line") ?? "";
  const search = queryParams.get("searchTerm") ?? "";
  const lineNumber = parseInt(line);

  const [selectedHistory, setSelectedHistory] =
    useState<EnsembleArtifactHistoryData>();
  const [diffEditorInstance, setDiffEditorInstance] =
    useState<monaco.editor.IStandaloneDiffEditor | null>(null);
  const [scriptContent, setScriptContent] = useState<string>(
    screen.content ?? "",
  );

  useEffect(() => {
    if (selectedHistory) {
      if (!diffEditorInstance && diffEditorRef.current) {
        const diffEditor = monaco.editor.createDiffEditor(
          diffEditorRef.current,
          editorConfig,
        );
        setDiffEditorInstance(diffEditor);

        const originalModel = monaco.editor.createModel(
          selectedHistory.content,
        );
        const modifiedModel = monaco.editor.createModel(
          scriptContent ?? screen.content,
        );
        modifiedModel.onDidChangeContent(() => {
          setScriptContent(modifiedModel.getValue());
        });
        diffEditor.setModel({
          original: originalModel,
          modified: modifiedModel,
        });
      } else {
        const originalModel = monaco.editor.createModel(
          selectedHistory.content,
        );
        const modifiedModel = monaco.editor.createModel(
          scriptContent ?? screen.content,
        );
        diffEditorInstance?.setModel({
          original: originalModel,
          modified: modifiedModel,
        });
      }
    } else {
      setDiffEditorInstance(null);
    }
  }, [diffEditorInstance, screen.content, scriptContent, selectedHistory]);

  const saveScript = useCallback(() => {
    onSave(scriptContent);
  }, [onSave, scriptContent]);

  useEffect(() => {
    if (!selectedHistory && containerElement.current && !editor.current) {
      const yamlInstance = monaco.editor.create(containerElement.current, {
        ...editorConfig,
        language: "javascript",
        value: scriptContent ?? screen.content,
      });

      yamlInstance.updateOptions({
        minimap: {
          enabled: true,
        },
      });

      if (lineNumber) {
        yamlInstance.revealLineInCenterIfOutsideViewport(lineNumber);
        yamlInstance.setSelection({
          startLineNumber: lineNumber,
          endLineNumber: lineNumber,
          startColumn: 1,
          endColumn: 1,
        });
      }

      const model = yamlInstance.getModel();

      if (search) {
        const range = model?.findMatches(
          search,
          true,
          false,
          false,
          null,
          false,
        );
        if (range) {
          yamlInstance.setSelection({
            startLineNumber: range[0].range.startLineNumber,
            startColumn: range[0].range.startColumn,
            endLineNumber: range[0].range.endLineNumber,
            endColumn: range[0].range.endColumn,
          });
        }

        const findAction = yamlInstance.getAction("actions.find");
        if (findAction) {
          findAction.run();
        }
      }

      editor.current = yamlInstance;
      yamlInstance.onDidChangeModelContent(() => {
        setScriptContent(yamlInstance.getValue());
      });
    }
  }, [selectedHistory, screen.content, scriptContent, lineNumber, search]);

  useEffect(() => {
    if (editor.current && saveScript) {
      editor.current.addCommand(
        monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS,
        saveScript,
      );
    }
  }, [editor, saveScript]);

  const handleCloseDiffEditor = () => {
    setSelectedHistory(undefined);
  };

  const handleRestoreVersion = () => {
    setScriptContent(selectedHistory?.content ?? scriptContent);
    editor.current?.setValue(selectedHistory?.content ?? scriptContent);
    setSelectedHistory(undefined);
  };

  useEffect(() => {
    setScriptContent(screen.content ?? "");
  }, [screen.content]);

  return (
    <div key={screen.id}>
      <div className="">
        <ScriptHeader
          scriptId={screen.id}
          handleCloseDiffEditor={handleCloseDiffEditor}
          handleRestoreVersion={handleRestoreVersion}
          selectedHistory={selectedHistory}
          disableButton={disableButton}
          setSelectedHistory={setSelectedHistory}
          scriptContent={scriptContent}
          onSave={onSave}
        />

        {isAppReadOnly && (
          <p className="readOnlyNote">
            This app is read-only, and your changes will be lost.
          </p>
        )}
        {selectedHistory && (
          <div className="title-bar">
            <span className="title">
              {`${
                selectedHistory.label ??
                getDateTime(selectedHistory.updatedAt.toDate())
              } ${
                selectedHistory.updatedBy?.name
                  ? `(${selectedHistory.updatedBy?.name})`
                  : ""
              }`}
            </span>
            <span className="title current-title">Current</span>
          </div>
        )}

        {selectedHistory && (
          <div
            key="diff-script"
            ref={diffEditorRef}
            style={{
              height: window.innerHeight - 80 + "px",
            }}
          />
        )}

        <div
          key="editor-script"
          ref={containerElement}
          id="editor-container"
          style={{
            height: window.innerHeight - 80 + "px",
          }}
        />
      </div>
    </div>
  );
};

export default ScriptEditor;
