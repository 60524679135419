import { create } from "zustand";
import $RefParser from "@apidevtools/json-schema-ref-parser";
import { JSONSchema7 } from "json-schema";
import {
  buildActionSchemaMapAndMutate,
  buildWidgetSchemaMapAndMutate,
  getDefinitionByName,
} from "../utils/schemaUtils";
import clone from "fast-copy";
import { cloneDeep, get, isObject, isString, set } from "lodash-es";
import config from "../config/config";
import { CustomJSONSchema7 } from "../containers/WidgetPropertyPanel/utils/propertyPanelSchemaUtils";
import {
  ChildrenOverride,
  TreeItemWidgetPayload,
} from "../containers/NavigatorPanel/TreeNode";

export interface ActionSchema {
  // a ref to the Action schema without the prefix /$ref/*
  ref: string;
  // a list of nested child events within this Action. We strip these out of the Action
  // and save the list here so we can traverse all Actions on the tree vs the PropertyPanel
  childEvents: ReadonlySet<string>;
}

export type EnsembleSchema = {
  uri: string;
  fileMatch: string[];
  schema: JSONSchema7;
};

export type SchemaStore = {
  // use for the Editor's typeahead
  editorScreenSchema?: EnsembleSchema;
  editorWidgetSchema?: EnsembleSchema;
  // internal screen schema. This is the schema that the Visual Editor uses
  _schema?: JSONSchema7;

  customWidgetDeclarationSchema?: JSONSchema7;
  customWidgetUsageSchema?: JSONSchema7;

  widgetNames: ReadonlySet<string>;
  containerNames: ReadonlySet<string>;
  widgetSchemaMap: ReadonlyMap<string, JSONSchema7>;
  widgetGroups: Map<string, { name: string; description?: string }[]>;

  apiSchema?: JSONSchema7;

  widgetChildrenOverrides: Map<string, ChildrenOverride>;

  // mapping of widget to a child widgets that should appear in the tree
  treeWidgetMapping: Map<string, TreeItemWidgetPayload[]>;

  actionSchemaMap: ReadonlyMap<string, string>;
  actionGroups: Map<string, { name: string; description?: string }[]>;
  actionChildrenOverrides: Map<string, ChildrenOverride>;

  loadSchema: (platform: SchemaPlatform) => void;
  findWidgetSchema: (widgetName: string) => JSONSchema7 | undefined;
  findActionSchema: (actionName: string) => JSONSchema7 | undefined;
};

export enum SchemaPlatform {
  native = "native",
  web = "web",
}

export const useSchemas = create<SchemaStore>((set, get) => ({
  _schema: undefined,
  widgetNames: new Set<string>(),
  containerNames: new Set<string>(),
  widgetSchemaMap: new Map<string, JSONSchema7>(),
  widgetGroups: new Map<string, { name: string; description?: string }[]>(),
  customWidgetDeclarationSchema: undefined,
  apiSchema: undefined,
  widgetChildrenOverrides: new Map<string, ChildrenOverride>(),
  treeWidgetMapping: new Map<string, TreeItemWidgetPayload[]>(),
  actionSchemaMap: new Map<string, string>(),
  actionGroups: new Map<string, { name: string; description?: string }[]>(),
  actionChildrenOverrides: new Map<string, ChildrenOverride>(),
  loadSchema: async (platform: SchemaPlatform) => {
    const schemaURI =
      platform === SchemaPlatform.native
        ? `/schema/${platform}/ensemble_schema.json`
        : `${config.previewReact.url}/schema/react/ensemble_schema.json`;
    const workingSchema = (await $RefParser.bundle(schemaURI)) as JSONSchema7;
    const schema = decodeSchemaURL(workingSchema);
    if (schema) {
      // clone the schema strictly for Monaco Editor
      const { editorScreenSchema, editorWidgetSchema } =
        buildEditorSchemas(schema);

      // build the map of Action names to their schemas
      // This will mutate the original schema by removing child Actions from the root Action
      const { actionSchemaMap, actionGroups, actionChildrenOverrides } =
        buildActionSchemaMapAndMutate(schema);

      // a map of framework widget names to their schemas
      // This will mutate the original schema
      const {
        widgetNames,
        containerNames,
        widgetSchemaMap,
        widgetGroups,
        childrenOverrides,
      } = buildWidgetSchemaMapAndMutate(schema);
      const treeWidgetMapping = processTreeWidgetMapping(schema);

      const { customWidgetDeclarationSchema, customWidgetUsageSchema } =
        buildCustomWidgetSchemaAndMutate(schema);

      const apiSchema = clone(schema?.$defs?.["API"]) as JSONSchema7;
      if (apiSchema) {
        apiSchema.$defs = schema.$defs;
        delete apiSchema.properties!.body;

        delete schema!.$defs!["API"];
      }

      mutateSchema(schema);

      set({
        editorScreenSchema,
        editorWidgetSchema,
        _schema: schema,

        customWidgetDeclarationSchema,
        customWidgetUsageSchema,

        widgetNames,
        containerNames,
        widgetGroups,
        widgetSchemaMap,
        apiSchema,
        widgetChildrenOverrides: childrenOverrides,
        treeWidgetMapping,
        actionSchemaMap,
        actionGroups,
        actionChildrenOverrides,
      });
    } else {
      set({});
    }
  },
  findWidgetSchema: (widgetName: string) => {
    // TODO: cache this once processed the widget. They will not change
    const state = get();
    let workingSchema: JSONSchema7 | undefined = undefined;

    if (state.widgetSchemaMap.has(widgetName)) {
      workingSchema = clone(
        state.widgetSchemaMap.get(widgetName),
      ) as JSONSchema7;
    } else {
      // check if it's in the root (e.g. View)
      const screenSchemaProperties = state._schema?.properties;
      for (const key in screenSchemaProperties) {
        if (key === widgetName) {
          workingSchema = clone(screenSchemaProperties[key]) as JSONSchema7;
          break;
        }
      }

      // check if its a root widget's property (e.g. header, footer)
      if (!workingSchema) {
        const viewSchema = state._schema?.$defs?.View as JSONSchema7;
        if (viewSchema) {
          const properties = viewSchema.properties;
          if (properties && properties[widgetName]) {
            workingSchema = clone(properties[widgetName]) as JSONSchema7;
          }
        }
      }
    }

    if (workingSchema) {
      workingSchema.$defs = state._schema?.$defs ?? {};
      // delete workingSchema.$defs["API"];
      // delete workingSchema.$defs["CustomWidget"];

      // Fix the huge performance issue by stripping out the Widget schema
      // When Widget schema is included, RJSF has to traverse the entire 10k line schema and run validation.
      // Since we have a specialized widget to handle this, we don't technically need this schema
      // delete (workingSchema.$defs["Widget"] as JSONSchema7).oneOf;

      // TODO: combine this with the treeWidgetMapping logic
      if (workingSchema.properties) {
        Object.keys(workingSchema.properties).map((name) => {
          const property = workingSchema!.properties![
            name
          ] as CustomJSONSchema7;
          if (property && property.treeItemWidgetLabel) {
            // remove this widget from schema. We'll show it in the tree instead of the Property Panel
            // we should probably do this in the pre-processing but also make sure typeahead still work
            delete workingSchema!.properties![name];
          }
        });
      }

      // is this logic wasteful? maybe just traverse the tree
      // workingSchema = JSON.parse(
      //   JSON.stringify(workingSchema, (k, v) =>
      //     k === "children" || k === "body" ? undefined : v,
      //   ),
      // );
    }

    return workingSchema;
  },
  findActionSchema: (actionName: string) => {
    const schemaRef = get().actionSchemaMap.get(actionName);
    const originalSchema = get()._schema;
    if (schemaRef && originalSchema) {
      const foundSchema = getDefinitionByName(get()._schema!, schemaRef);
      if (foundSchema) {
        const actionSchema = clone(foundSchema) as JSONSchema7;
        actionSchema.$defs = originalSchema.$defs;
        return actionSchema;
      }
    }
  },
}));

/**
 * Clone a copy strictly for Monaco Editor since we manipulate the schema for Visual Editor
 */
const buildEditorSchemas = (originalSchema: JSONSchema7) => {
  const schema = clone(originalSchema);

  // this is the schema for a View / ViewGroup
  const editorScreenSchema: EnsembleSchema = {
    // We want to show "Documentation" text on hover. The Monaco Editor is picky on how it wants to show it.
    uri: "https://studio.ensembleui.com/Documentation",
    fileMatch: ["*"],
    schema: schema,
  };

  // The custom widget schema (app-level) is a subset of the screen schema
  // We try not to clone the schema but just changing the "properties" (reference the rest)
  const editorWidgetSchema: EnsembleSchema = {
    uri: "https://studio.ensembleui.com/Documentation",
    fileMatch: ["*"],
    schema: {
      ...schema,
      properties: {
        Import: {
          type: "array",
          items: {},
        },
        Widget: schema.additionalProperties ?? {},
      },
    },
  };

  return { editorScreenSchema, editorWidgetSchema };
};

// build the schema for Custom Widget, for both declaration and usage
const buildCustomWidgetSchemaAndMutate = (root: JSONSchema7) => {
  // declaration
  const customWidgetDeclarationSchema = clone(
    root?.$defs?.["CustomWidget"],
  ) as JSONSchema7;
  if (customWidgetDeclarationSchema) {
    // body is handled by the tree
    if ("body" in customWidgetDeclarationSchema.properties!) {
      delete customWidgetDeclarationSchema.properties["body"];
    }
    customWidgetDeclarationSchema.$defs = root?.$defs;
  }

  // usage
  const customWidgetUsageSchema = clone(
    root?.$defs?.["CustomWidgetUsage"],
  ) as JSONSchema7;
  if (customWidgetUsageSchema) {
    customWidgetUsageSchema.$defs = root?.$defs;
  }
  return { customWidgetDeclarationSchema, customWidgetUsageSchema };
};

const mutateSchema = (originalSchema: JSONSchema7) => {
  // Delete the 'body' property from the root View
  delete (originalSchema.$defs?.View as JSONSchema7)?.properties?.body;

  // delete the Widget / Action oneOf since we don't need them anymore.
  // Since they represent almost all the schema (plus circular), this speeds up performance significantly with RJSF.
  delete (originalSchema.$defs?.Widget as JSONSchema7)?.oneOf;
  delete (originalSchema.$defs?.Action as JSONSchema7)?.oneOf;
};

function processWidgetSchema(
  workingSchema: JSONSchema7,
): TreeItemWidgetPayload[] {
  const mappedItems: TreeItemWidgetPayload[] = [];
  if (workingSchema.properties) {
    Object.keys(workingSchema.properties).map((name) => {
      const property = workingSchema!.properties![name] as CustomJSONSchema7;
      if (property && property.treeItemWidgetLabel) {
        mappedItems.push(
          new TreeItemWidgetPayload(name, property.treeItemWidgetLabel),
        );
      }
    });
  }
  return mappedItems;
}

function processTreeWidgetMapping(schema: JSONSchema7) {
  const map: Map<string, TreeItemWidgetPayload[]> = new Map();
  const widgetDefinitions = (schema.$defs?.Widget as JSONSchema7)?.oneOf ?? [];
  for (const item of widgetDefinitions) {
    const properties = (item as JSONSchema7)?.properties as JSONSchema7;

    // note that we can reference a custom widget, which has no properties
    if (!properties) return;

    Object.entries(properties).map(([widgetName, value]) => {
      if (value?.$ref) {
        const ref = value!.$ref.replace("#/$defs/", "");
        const widgetSchema = schema.$defs?.[ref] as JSONSchema7;
        if (widgetSchema) {
          const items = processWidgetSchema(widgetSchema);
          if (items.length > 0) {
            map.set(widgetName, items);
          }
        }
      }
    });
  }
  return map;
}

const decodeSchemaURL = <T>(obj: T): T => {
  let clonedObj = cloneDeep(obj);
  if (isObject(clonedObj)) {
    if (Array.isArray(clonedObj)) {
      // If obj is an array, recursively visit and replace elements
      for (let i = 0; i < clonedObj.length; i++) {
        clonedObj[i] = decodeSchemaURL(clonedObj[i]);
      }
    } else {
      // If obj is an object, recursively visit and replace values
      for (const key in clonedObj) {
        const result = decodeSchemaURL(get(clonedObj, key));
        set(clonedObj, key, result);
      }
    }
  } else if (isString(obj)) {
    try {
      clonedObj = decodeURI(obj) as T;
    } catch (e) {
      //no-op
    }
  }

  return clonedObj;
};
