import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { AuthContext } from "../App";

import { AppData } from "../models/model";
import { useCloneApp } from "../hooks/useFirebase";

interface AppCloneModalProps {
  app: AppData;
}

const AppCloneModal: React.FC<AppCloneModalProps> = ({ app }) => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  const [cloneCollaborators, setCloneCollaborators] =
    React.useState<boolean>(false);
  const appInsertQuery = useCloneApp(currentUser, app.id, cloneCollaborators);

  const handleCreateApp = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    appInsertQuery.mutate();
  };

  useEffect(() => {
    if (appInsertQuery.data?.clone_app) {
      const newAppId = appInsertQuery.data.clone_app.app_id;
      navigate(`/app/${newAppId}/screens`);
    } else if (appInsertQuery.isError) {
      let errorMessage = "Failed to clone app";
      if (appInsertQuery.error) {
        console.error(appInsertQuery.error);
        errorMessage = appInsertQuery.error.toString();
      }
      toast.error(errorMessage, {
        position: "top-right",
        type: toast.TYPE.ERROR,
        theme: "dark",
      });
    }
  }, [appInsertQuery, navigate]);

  return (
    <React.Fragment>
      <p>Are you sure you want to make a copy of {app.name}?</p>
      <label
        style={{
          display: "flex",
          alignItems: "center",
          gap: 5,
        }}
      >
        <input
          type="checkbox"
          checked={cloneCollaborators}
          onChange={(e) => setCloneCollaborators(e.target.checked)}
        />
        Add existing collaborators to the cloned app
      </label>
      <br />
      <br />
      <button
        className="button__primary"
        onClick={handleCreateApp}
        disabled={appInsertQuery.isLoading}
      >
        {appInsertQuery.isIdle && <>Yes, clone app</>}
        {appInsertQuery.isLoading && <>Processing...</>}
      </button>
    </React.Fragment>
  );
};

export default AppCloneModal;
