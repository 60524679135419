import React, { FormEvent, useCallback } from "react";
import { FormProps as RJSFFormProps, IChangeEvent } from "@rjsf/core";
import { Form } from "@rjsf/bootstrap-4";
import validator from "@rjsf/validator-ajv8";
import { FieldTemplate } from "./templates/FieldTemplate";
import { ObjectFieldTemplate } from "./templates/ObjectFieldTemplate";
import { ColorPickerField } from "./fields/ColorPickerField";
import { AlignmentField } from "./fields/AlignmentField";
import { BooleanField } from "./fields/BooleanField";
import { StringField } from "./fields/StringField";
import { TextWidget } from "./widgets/TextWidget";
import { SelectWidget } from "./widgets/SelectWidget";
import { WidgetBuilderField } from "./fields/WidgetBuilderField";
import { FormRefContext } from "../../models/FormRefContext";
import { TextAlignmentField } from "./fields/TextAlignmentField";
import { NumberField } from "./fields/NumberField";
import { ActionBuilderField } from "./fields/ActionBuilderField";
import { ErrorListTemplate } from "./templates/ErrorListTemplate";
import { ArrayFieldTemplate } from "./templates/ArrayFieldTemplate";
import { ObjectField } from "./fields/ObjectField";
import { CustomSchemaField } from "./fields/CustomSchemaField";
import { RJSFValidationError } from "@rjsf/utils";
import { getFormDataByPath, hasExpression } from "./utils/propertyPanelUtils";
import {
  ClearExpressionField,
  ExpressionField,
} from "./fields/ExpressionField";
import { AssetPickerField } from "./fields/AssetPickerField";
import { JSEditorField } from "./fields/JSEditorField";
import {
  ConditionalActionField,
  ConditionalWidgetField,
} from "./fields/ConditionalField";
import PaddingMarginField from "./fields/PaddingMarginField";

export type PropertySetFormProps = Omit<RJSFFormProps, "validator"> & {
  onFormSubmit: (data: IChangeEvent, event: FormEvent) => void;
};

export const PropertySetForm: React.FC<PropertySetFormProps> = ({
  formContext,
  onFormSubmit,
  ...rest
}) => {
  const { formRef, hasFormChanges, setHasFormChanges } =
    React.useContext(FormRefContext);
  const onChange = () => {
    setHasFormChanges(true);
  };

  const onBlurCallback = useCallback(() => {
    if (hasFormChanges) {
      submitForm();
    }
  }, [formRef, hasFormChanges]); // eslint-disable-line react-hooks/exhaustive-deps

  // internal widgets also use this to submit the form
  const submitForm = useCallback(() => {
    formRef?.current?.submit();
  }, [formRef]);

  // submit Form at the next cycle to allow state changes to propagate
  const submitFormLater = () => {
    // this should be a sufficient wait for all setState() to update before submitting the form
    requestAnimationFrame(() => {
      setTimeout(() => {
        submitForm();
      }, 0);
    });
  };

  // callback after the form has been submitted
  const onSubmit = (data: IChangeEvent, event: FormEvent) => {
    // form passed validation, reset changes and let the parent handle the processing
    setHasFormChanges(false);
    onFormSubmit(data, event);
  };

  /**
   * There are a few approaches to handle the expression toggle:
   * 1. schema/uiSchema can be updated from any widget and reload the Form states. This is problematic since we don't want to mutate the shared schema
   * 2. Add string type to all widget schema. This is tedious, plus RJSF don't like multiple types and requires ui:widget set on every field.
   * 3. We feed a copy of schema/uiSchema to each field to toggle Expression on/off, then surgically remove errors that complains about the wrong type.
   *
   * We took approach #3 because it is the least intrusive. But we need to be super careful on which errors to remove.
   */
  const transformErrors = (errors: RJSFValidationError[]) => {
    // console.log(`Transform errors: ${JSON.stringify(errors, null, 2)}`);
    const formData = formRef?.current?.state.formData;
    return errors.filter((error) => {
      // can we assume wrong type error is always the first entry for each error?
      if (error.name === "type" && error.property) {
        const value = getFormDataByPath(formData, error.property);
        // very specific rule. We found expression in the error that complains about the wrong "type"
        if (typeof value === "string" && hasExpression(value)) {
          return false;
        }
      }
      return true;
    });
  };

  return (
    <Form
      ref={formRef}
      noHtml5Validate={true}
      liveValidate={false}
      validator={validator}
      experimental_defaultFormStateBehavior={{
        emptyObjectFields: "skipDefaults",
      }}
      {...rest}
      onChange={onChange}
      onBlur={onBlurCallback}
      onSubmit={onSubmit}
      onError={(errors) =>
        console.log(`Form error: ${JSON.stringify(errors, null, 2)}`)
      }
      transformErrors={transformErrors}
      formContext={{
        ...formContext,
        submitForm,
        submitFormLater,
      }}
      templates={{
        ErrorListTemplate,
        FieldTemplate,
        // DescriptionFieldTemplate: () => null,
        // FieldErrorTemplate,
        ObjectFieldTemplate,
        ArrayFieldTemplate,
      }}
      fields={{
        // more complex types that uses modal
        widgetField: WidgetBuilderField,
        actionField: ActionBuilderField,

        // field that can display any field as an expression
        ExpressionField,
        ClearExpressionField,

        // custom field
        colorField: ColorPickerField,
        textAlignmentField: TextAlignmentField,
        alignmentField: AlignmentField,
        borderRadiusField: StringField,
        paddingField: PaddingMarginField,
        marginField: PaddingMarginField,
        assetPickerField: AssetPickerField,
        conditionalWidgetField: ConditionalWidgetField,
        conditionalActionField: ConditionalActionField,
        jsEditorField: JSEditorField,

        // overriding default Fields
        SchemaField: CustomSchemaField,
        NumberField: NumberField,
        BooleanField: BooleanField,

        // override to modify get default value for additionalProperties
        ObjectField,

        // this will for all with type=string (including enum, ...)
        // StringField: StringField,
      }}
      widgets={{
        TextWidget,
        SelectWidget,
      }}
    />
  );
};
