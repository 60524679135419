import React, { useContext, useState } from "react";
import { Menu, MenuItem, MenuDivider, ClickEvent } from "@szhsin/react-menu";

import "@szhsin/react-menu/dist/index.css";
import LabelMenu from "./LabelMenu";
import { toast } from "react-toastify";
import { AuthContext } from "../../App";
import { useUpdateWidget } from "../../hooks/useAPIs";
import { useAppContext } from "../../pages/AppPagesWrapper";

// Components
import { ReactComponent as IconMenu } from "../../assets/icon_menu.svg";

// Interfaces
import { EnsembleWidgetData } from "../../config/interfaces";
import ScriptRename from "../modals/ScriptRename/ScriptRename";
import ScriptArchive from "../modals/ScriptArchive/ScriptArchive";
import { handleLabelingCard } from "./functions";

interface ScreenActionsMenuProps {
  script: EnsembleWidgetData;
  isReadOnly: boolean;
}

const ScriptActionsMenu: React.FC<ScreenActionsMenuProps> = ({
  script,
  isReadOnly,
}) => {
  const { app } = useAppContext();
  const { currentUser } = useContext(AuthContext);
  const [renameTrigger, setRenameTrigger] = useState<boolean>(false);
  const [archiveTrigger, setArchiveTrigger] = useState<boolean>(false);
  const updateWidgetQuery = useUpdateWidget(currentUser, app.id, script.id);

  const handleRename = (event: ClickEvent) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();
    setRenameTrigger(true);
  };

  const handleCopyName = (event: ClickEvent) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();

    navigator.clipboard.writeText(script?.name);
    toast.success(
      `The import is copied to your clipboard. You can paste it at the top of your screens and widgets.`,
      {
        position: "top-right",
        type: toast.TYPE.INFO,
        theme: "dark",
        autoClose: false,
      },
    );
  };

  const handleArchive = (event: ClickEvent) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();
    setArchiveTrigger(true);
  };

  const HandleUpdateLabel = (labelId: string) =>
    handleLabelingCard(
      labelId,
      updateWidgetQuery.mutate,
      script.labelGroup?.id,
    );

  return (
    <>
      <Menu
        menuButton={
          <button
            className="actions-menu-button"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <IconMenu />
          </button>
        }
        align="end"
        position="auto"
        direction="bottom"
        offsetY={8}
        menuClassName="actions-menu"
      >
        {!isReadOnly && (
          <>
            <MenuItem onClick={handleRename}>Rename</MenuItem>
            <MenuItem onClick={handleCopyName}>Copy Name</MenuItem>
            <MenuDivider />
            <LabelMenu
              app={app}
              isLabeled={!!script.labelGroup}
              onUpdateLabel={HandleUpdateLabel}
            />
            <MenuDivider />
            <MenuItem
              className="actions-menu__delete-item"
              onClick={handleArchive}
            >
              Archive
            </MenuItem>
          </>
        )}
      </Menu>

      <ScriptRename
        script={script}
        displayRenameModal={renameTrigger}
        setRenameTrigger={setRenameTrigger}
      />

      <ScriptArchive
        script={script}
        displayArchiveModal={archiveTrigger}
        setArchiveTrigger={setArchiveTrigger}
      />
    </>
  );
};

export default ScriptActionsMenu;
