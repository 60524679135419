import { useState } from "react";
import { AppData } from "../../models/model";
import LabelManagerModal from "../modals/LabelManagerModal";
import { ClickEvent, MenuDivider, MenuItem, SubMenu } from "@szhsin/react-menu";

interface LabelMenuProps {
  app: AppData;
  isLabeled: boolean;
  onUpdateLabel: (labelId: string) => void;
}

const LabelMenu = ({ app, isLabeled, onUpdateLabel }: LabelMenuProps) => {
  const [displayLabelsModal, setDisplayLabelsModal] = useState(false);

  const openLabelManager = (event: ClickEvent) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();
    setDisplayLabelsModal(true);
  };

  const updateWidgetLabel = (event: ClickEvent, labelId: string) => {
    event.stopPropagation = true;
    event.syntheticEvent.preventDefault();
    onUpdateLabel(labelId);
  };

  return (
    <div
      // Prevent event propagation
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {!!app.groupLabels?.size && (
        <SubMenu menuClassName="actions-menu" label="Apply Label">
          {!!app.groupLabels.size &&
            Array.from(app.groupLabels.entries()).map(
              ([labelId, labelName]) => (
                <MenuItem
                  key={labelId}
                  onClick={(event) => updateWidgetLabel(event, labelId)}
                >
                  {labelName}
                </MenuItem>
              ),
            )}

          {isLabeled && (
            <>
              <MenuDivider />
              <MenuItem
                className="actions-menu__delete-item"
                onClick={(event) => updateWidgetLabel(event, "")}
              >
                None
              </MenuItem>
            </>
          )}
        </SubMenu>
      )}
      <MenuItem onClick={openLabelManager}>Manage Labels</MenuItem>

      <LabelManagerModal
        isModalOpen={displayLabelsModal}
        setModalOpen={setDisplayLabelsModal}
      />
    </div>
  );
};

export default LabelMenu;
