import { Link } from "react-router-dom";
import { useAppContext } from "./AppPagesWrapper";
import { type FunctionComponent, useEffect } from "react";

// APIs
import { useModal } from "../hooks/useModal";

// Components
import DemoAppBadge from "../components/DemoAppBadge";
import Modal from "../components/Modal";
import ScriptCreateModal from "../components/modals/Scripts/ScriptCreateModal";
import ScriptCard from "../components/cards/ScriptCard";
import SearchBar from "../components/SearchBar";
import AppBodyContainer from "../containers/AppBodyContainer";

const ScriptScreens: FunctionComponent = () => {
  const { isModalDisplayed, toggleModal } = useModal();
  const { app, isAppReadOnly, isDemoApp } = useAppContext();

  useEffect(() => {
    if (app.name) document.title = app.name;
  });

  return (
    <div className="screen-content script-screen" key={app.id}>
      {/* breadcrumb */}
      <div className="breadcrumb">
        <Link to="/">Apps</Link>

        {app.isPublic && !isDemoApp && (
          <span>
            {" "}
            / <span className="public-app-badge">Public</span>
          </span>
        )}

        {isDemoApp && (
          <span>
            {" "}
            / <DemoAppBadge />
          </span>
        )}
      </div>

      <div className="page-header">
        <div>
          <h1>Scripts</h1>
        </div>

        {/* Search Bar  */}
        {!!app.internalScripts?.length && (
          <SearchBar searchType="script" searchPlaceholder="Search..." />
        )}

        <div className="page-actions">
          {!isAppReadOnly && (
            <button className="button__primary" onClick={toggleModal}>
              Create new script
            </button>
          )}
        </div>
      </div>

      <p className="info-title">
        Scripts are reusable code written in JavaScript. Define them once and
        reuse by importing where needed.
        <a
          className="doc"
          onClick={() =>
            window.open(
              "https://docs.ensembleui.com/#/javascript-reference/README",
              "_blank",
            )
          }
        >
          <span> Visit Docs</span>
        </a>
      </p>

      <AppBodyContainer
        appId={app.id}
        cardName="script"
        CardComponent={ScriptCard}
        isReadOnly={isAppReadOnly}
        itemList={app.internalScripts}
        dataNotFound="This app does not have custom scripts."
      />

      {app && !isAppReadOnly && (
        <Modal
          isModalDisplayed={isModalDisplayed}
          onHide={toggleModal}
          headerText="Create script"
          modalContent={<ScriptCreateModal />}
        />
      )}
    </div>
  );
};

export default ScriptScreens;
