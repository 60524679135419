import { useAppContext } from "../pages/AppPagesWrapper";
import { useMemo } from "react";
import { useYamlDoc, YAMLDocNode } from "./useYamlDoc";
import { isMap, parse } from "yaml";
import { getCustomWidgetNodes, getKeyValue } from "../utils/schemaUtils";
import { isNil, path, reject } from "ramda";

export interface CustomWidgetMetadata {
  inputs: Set<string>;
  events: Map<string, Set<string>>;
  type: "screen" | "app" | "global";
}

export const useCustomWidgets = () => {
  const appContext = useAppContext();

  // the custom widgets defined on this screen only
  const { doc, nonEditorChanges } = useYamlDoc();
  const screenCustomWidgetNodes = useMemo<YAMLDocNode[]>(() => {
    return doc && isMap(doc.contents) ? getCustomWidgetNodes(doc.contents) : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doc, nonEditorChanges]);

  const appCustomWidgets = useMemo(
    () => appContext.app.internalWidgets?.map((value) => value.name) ?? [],
    [appContext.app.internalWidgets],
  );

  // the list of all custom widgets (defined on the screen + app-level)
  const customWidgets = useMemo<Set<string>>(() => {
    const items = [
      ...reject(isNil, screenCustomWidgetNodes.map(getKeyValue)),
      ...appCustomWidgets,
    ];
    return new Set(items);
  }, [appCustomWidgets, screenCustomWidgetNodes]);

  // get the definition (inputs, events) for all custom widgets
  const customWidgetMetadata = useMemo<
    Map<string, CustomWidgetMetadata>
  >(() => {
    const metadata: Map<string, CustomWidgetMetadata> = new Map();

    // convert raw events to Events
    const processEvents = (rawEvents: Map<string, any>) => {
      const events = new Map<string, Set<string>>();
      Object.entries(rawEvents).forEach(([eventName, eventValue]) => {
        const eventData = new Set<string>(
          typeof eventValue?.data === "object" && eventValue?.data !== null
            ? Object.keys(eventValue.data)
            : [],
        );
        events.set(eventName, eventData);
      });
      return events;
    };

    // start with app-level first
    appContext.app.internalWidgets
      ?.filter((value) => value.content)
      ?.map((value) => {
        try {
          const content = parse(value.content!);
          const inputs =
            path<Array<string>>(["Widget", "inputs"], content) || [];
          const rawEvents =
            path<Map<string, any>>(["Widget", "events"], content) || new Map();

          metadata.set(value.name, {
            inputs: new Set(inputs),
            events: processEvents(rawEvents),
            type: "app",
          });
        } catch (e) {
          console.error(
            `Error parsing widget content (${value.name ?? "unknown"})`,
            e,
          );
        }
      });

    // then screen-level's widgets, which can override app-level's widgets
    const screenWidgets =
      doc && isMap(doc.contents)
        ? getCustomWidgetNodes(doc.contents).map(
            (scalar) => scalar.value as string,
          )
        : [];
    screenWidgets.forEach((widgetName) => {
      const content = doc!.contents!.toJSON();
      const inputs = path<Array<string>>([widgetName, "inputs"], content) || [];
      const rawEvents =
        path<Map<string, any>>([widgetName, "events"], content) || new Map();

      metadata.set(widgetName, {
        inputs: new Set(inputs),
        events: processEvents(rawEvents),
        type: "screen",
      });
    });

    return metadata;
  }, [appContext.app.internalWidgets, doc]);

  return {
    screenCustomWidgetNodes,
    appCustomWidgets,
    customWidgets,
    // only this one is needed. Should deprecate the others
    customWidgetMetadata,
  };
};
