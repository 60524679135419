import { Icon } from "@iconify/react";
import Dropdown from "../../components/Dropdown";

export const HelpDropdown: React.FC = () => {
  return (
    <Dropdown
      className="icon-dropdown"
      activatorText={<Icon icon="octicon:question-24" />}
      hideCaret
      items={[
        {
          id: "help-docs",
          text: "Docs",
          clickHandler: () => {
            window.open("https://docs.ensembleui.com/", "_blank");
          },
        },
        {
          id: "help-question",
          text: "I have a question",
          clickHandler: () => {
            window.open(
              "https://discord.gg/9GbyeMukSN",
              "_blank",
              "noreferrer",
            );
          },
        },
        {
          id: "help-build-for-me",
          text: "Build the app for me",
          clickHandler: () => {
            window.open("https://ensembleui.com/services.html", "_blank");
          },
        },
      ]}
    />
  );
};
