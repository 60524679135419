import React, { useState } from "react";
import { Popover, List } from "antd";
import "./ScreenTypePicker.sass";
import useKeyboardNavigation from "../../hooks/useKeyboardNavigation";

const screenTypes = [
  {
    label: "Screen",
    value: "View",
    description: "Create a single screen",
  },
  {
    label: "Group of Screens",
    value: "ViewGroup",
    description: "Create a grouping for your screens with a navigational menu",
  },
];

export const ScreenTypePicker: React.FC<{
  onSelect: (value: string) => void;
}> = ({ onSelect }) => {
  const [visible, setVisible] = useState(false);

  const [keyboardNavIndex, roofRef] = useKeyboardNavigation<HTMLDivElement>(
    screenTypes.length,
  );

  const handleSelect = (value: string) => {
    onSelect(value);
    setVisible(false);
  };

  const content = (
    <div className={"screen-type-picker-content"}>
      <List
        itemLayout="horizontal"
        dataSource={screenTypes}
        renderItem={(item, i) => (
          <List.Item
            tabIndex={i}
            ref={i === keyboardNavIndex ? roofRef : null}
            role="button"
            onClick={() => handleSelect(item.value)}
            className={"screen-type-picker-content-item"}
          >
            <List.Item.Meta
              title={<a>{item.label}</a>}
              description={item.description}
            />
          </List.Item>
        )}
      />
    </div>
  );

  const title = (
    <div className={"screen-type-picker-content-title"}>
      Please select a Screen Type
    </div>
  );

  return (
    <Popover
      overlayClassName="screen-type-picker"
      open={visible}
      onOpenChange={(open) => setVisible(open)}
      content={content}
      title={title}
      color="#101010"
      trigger="click"
      placement={"right"}
    >
      <div className={"screen-type-picker-anchor"}>Select a Screen Type</div>
    </Popover>
  );
};

export default ScreenTypePicker;
