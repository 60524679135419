import { type FC } from "react";
import ReactTooltip from "react-tooltip";
import AssetsActionsMenu from "./AssetsActionsMenu";
import { timePassed } from "../../utils/functions";

// Assets
import default_screen_image from "../../assets/image_placeholder.svg";

// Interfaces
import { EnsembleAssetData, EnsembleFontData } from "../../config/interfaces";

export interface AssetsCardProps {
  asset: EnsembleAssetData | EnsembleFontData;
  isReadOnly?: boolean;
  assetType?: string;
}

const AssetsCard: FC<AssetsCardProps> = ({ asset, isReadOnly, assetType }) => {
  const imageSrc =
    assetType === "font"
      ? default_screen_image
      : (asset as EnsembleAssetData).publicUrl ?? default_screen_image;

  return (
    <>
      <div className="screen-card assets">
        <div
          className="screen-card__asset-image"
          style={{ backgroundImage: `url(${imageSrc}` }}
        ></div>

        <div className="screen-card__content">
          <h4>{asset.name}</h4>

          <div className="screen-card__content-info">
            {asset.updatedAt && asset.updatedBy && (
              <span>
                {timePassed(asset.updatedAt)}, <>{asset.updatedBy.name}</>
              </span>
            )}
          </div>

          {/* assets actions */}
          {!isReadOnly && (
            <AssetsActionsMenu
              asset={asset}
              isTemplate={false}
              isReadOnly={false}
              assetType={assetType ?? "asset"}
            />
          )}
        </div>
      </div>
      <ReactTooltip effect="solid" place="top" />
    </>
  );
};

export default AssetsCard;
