import { FieldProps } from "@rjsf/utils";
import React, { ChangeEvent, useCallback, useEffect, useMemo } from "react";
import { JSONSchema7 } from "json-schema";
import { ExpressionInput } from "../components/ExpressionInput";
import "./ConditionalField.sass";
import SchemaField from "@rjsf/core/lib/components/fields/SchemaField";

enum BodyType {
  Widget = "widget",
  Action = "action",
}

type ConditionalFieldProps = FieldProps & {
  type: BodyType;
};

export const ConditionalWidgetField: React.FC<FieldProps> = (props) => {
  return <ConditionalField {...props} type={BodyType.Widget} />;
};

export const ConditionalActionField: React.FC<FieldProps> = (props) => {
  return <ConditionalField {...props} type={BodyType.Action} />;
};

const ConditionalField: React.FC<ConditionalFieldProps> = (props) => {
  const { type, formContext, schema, formData, onChange } = props;
  const [conditionKey, setConditionKey] = React.useState<string | undefined>();
  const [conditionValue, setConditionValue] = React.useState<string | null>(
    null,
  );
  const [conditionBody, setConditionBody] = React.useState<any | undefined>();
  const [hasChanges, setHasChanges] = React.useState(false);
  const [handleEditingCompleteLater, setHandleEditingCompleteLater] =
    React.useState<boolean>(false);

  // build the schema for the condition and the body from the root schema
  const { conditionSchema, bodySchema, bodyType } = useMemo(() => {
    let bodyType;
    let bodySchema: JSONSchema7 | undefined;

    // track the condition keys and whether each is required
    const conditions: string[] = [];
    const requiredItems: string[] = [];

    if (schema.anyOf?.length) {
      schema.anyOf.map((value) => {
        const entryProperties = (value as JSONSchema7).properties;
        if (entryProperties) {
          Object.keys(entryProperties as JSONSchema7).map((key) => {
            // save the Action or Widget body schema
            if (key === BodyType.Action || key === BodyType.Widget) {
              // only need to do it once because body type should be exactly the same
              if (!bodySchema) {
                bodyType = key;
                bodySchema = entryProperties[key] as JSONSchema7;
              }
            } else if (
              (entryProperties[key] as JSONSchema7).type === "string" ||
              (entryProperties[key] as JSONSchema7).type === "null"
            ) {
              // assume everything else is a condition
              conditions.push(key);
              if ((value as JSONSchema7).required?.includes(key)) {
                requiredItems.push(key);
              }
            }
          });
        }
      });
    }
    return {
      conditionSchema: {
        type: "string",
        enum: conditions,
        // non-standard but we use this to track which entry is required or not (e.g. else does not require a condition value)
        required: requiredItems,
      },
      bodySchema,
      bodyType,
    };
  }, [schema]);

  // update individual field's formData based on the root formData
  useEffect(() => {
    let conditionKey;
    let conditionValue;
    let conditionBody;

    if (formData && Object.keys(formData).length) {
      if (bodyType) {
        conditionBody = formData[bodyType];
      }
      const key = Object.keys(formData)
        .filter((key) => conditionSchema.enum.includes(key))
        ?.shift();
      if (key) {
        conditionKey = key;
        conditionValue = formData[key];
      }
    }
    setConditionKey(conditionKey ?? "elseif"); // TODO: should be if or elseif
    setConditionValue(conditionValue);
    setConditionBody(conditionBody);
  }, [bodyType, conditionSchema, formData]);

  const handleConditionKeyChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newKey = e.target.value;
    setConditionKey(newKey);

    // if type is null, we have to set the value to null
    if (newKey === "else") {
      setConditionValue(null);
    }

    setHasChanges(true);
  };
  const handleConditionValueChange = (value: string) => {
    let newValue: string | null = value.trim();
    if (newValue === "") {
      newValue = null;
    }

    setConditionValue(newValue);
    setHasChanges(true);
  };

  const handleBodyChange = (widgetOrActionName: any) => {
    setConditionBody({ [widgetOrActionName]: {} });
    setHasChanges(true);

    // on widget select, we wait to invoke handleEditingComplete() later to allow the body to update
    setHandleEditingCompleteLater(true);
  };

  // handle changes from any of the internal fields
  const handleEditingComplete = useCallback(() => {
    if (!hasChanges) return;

    // we always construct a brand new formData to guarantee nice-ness order
    const newFormData = {
      [conditionKey!]: conditionValue,
      [bodyType!]: conditionBody,
    };
    onChange(newFormData);
    formContext.submitFormLater();

    setHasChanges(false);
  }, [
    bodyType,
    conditionBody,
    conditionKey,
    conditionValue,
    formContext,
    hasChanges,
    onChange,
  ]);

  // invoke handleEditingComplete() later
  useEffect(() => {
    if (handleEditingCompleteLater) {
      handleEditingComplete();
    }
    setHandleEditingCompleteLater(false);
  }, [handleEditingComplete, handleEditingCompleteLater]);

  // can only handle known body types
  if (!bodySchema || !bodyType) {
    return <div>Unsupported syntax</div>;
  }

  return (
    <div className={"conditional-field"}>
      <div className={"conditional-field-condition-container"}>
        <select
          value={conditionKey}
          onChange={handleConditionKeyChange}
          onBlur={handleEditingComplete}
        >
          {conditionSchema.enum?.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
        {conditionKey && conditionSchema.required?.includes(conditionKey) && (
          <ExpressionInput
            placeholder={"Enter an expression"}
            value={conditionValue ?? ""}
            onChange={handleConditionValueChange}
            onBlur={handleEditingComplete}
            onEnterKey={handleEditingComplete}
          />
        )}
      </div>

      <SchemaField
        {...props}
        schema={bodySchema}
        uiSchema={{
          "ui:field": type === BodyType.Widget ? "widgetField" : "actionField",
          "ui:fieldReplacesAnyOrOneOf": true,
          "ui:options": {
            // We don't want to show label for ActionField under each condition,
            // but RJSF butchered showLabel so use our own custom one instead
            showLabelOverride: false,
          },
        }}
        onChange={handleBodyChange}
        formData={conditionBody}
      />
    </div>
  );
};
