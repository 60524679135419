import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Tooltip } from "antd";
import {
  ArrayFieldTemplateItemType,
  ArrayFieldTemplateProps,
  RJSFSchema,
} from "@rjsf/utils";
import { RemixIcon } from "../../../components/Widgets";
import { ActionMenu } from "../../../components/VisualEditor/ActionMenu";
import "./ArrayFieldTemplate.sass";
import { camelCaseToWords } from "../utils/propertyPanelUtils";

const DRAG_TYPE = "arrayItem";

interface DragItem {
  formContext: any;
  index: number;
  id: string;
  type: string;
}

interface DraggableItemProps {
  formContext: any;
  index: number;
  element: ArrayFieldTemplateItemType<any, RJSFSchema, any>;
}

const DraggableItem: React.FC<DraggableItemProps> = ({
  formContext,
  index,
  element,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const dragRef = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: DRAG_TYPE,
    drop(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;
      if (hoverClientY < hoverMiddleY) {
        handleMove(dragIndex, hoverIndex);
        item.index = hoverIndex;
      }
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: DRAG_TYPE,
    item: () => ({ index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // only icon initiates drag
  drag(dragRef);
  // the whole row shows as drag preview
  preview(ref);
  // the whole row is a drop target
  drop(ref);

  const handleRemove = (index: number) => {
    // emulate a mouse click to call RJSF remove function
    element.onDropIndexClick(index)({ preventDefault: () => {} });
    formContext.submitFormLater();
  };

  const handleMove = (oldIndex: number, newIndex: number) => {
    // emulate a mouse click to call RJSF reOrder function
    element.onReorderClick(
      oldIndex,
      newIndex,
    )({ preventDefault: () => {}, currentTarget: { blur: () => {} } });
    formContext.submitFormLater();
  };

  return (
    <div
      ref={ref}
      className={"array-field-draggable-item"}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <div className={"array-field-draggable-item-actions"}>
        <span ref={dragRef} className={"array-field-draggable-item-draggable"}>
          <RemixIcon name="draggable" />
        </span>
        <ActionMenu
          anchor={
            <span className={"array-field-draggable-item-remove"}>
              <RemixIcon name={"arrow-drop-down-line"} />
            </span>
          }
          items={[
            {
              iconName: "delete-bin-7-line",
              label: "Delete",
              value: "delete",
            },
          ]}
          onSelect={(value) => {
            if (value === "delete") {
              handleRemove(index);
            }
          }}
        />
      </div>
      <div className={"array-field-draggable-item-content"}>
        {element.children}
      </div>
    </div>
  );
};

export const ArrayFieldTemplate = (props: ArrayFieldTemplateProps) => {
  const { formContext, schema, title, items, canAdd, onAddClick } = props;

  return (
    <div>
      {/* mimic FieldTemplate to show the label and Add button*/}
      <div className="custom-field-template-content">
        <Tooltip
          overlayClassName={"property-panel-tooltip"}
          title={schema.description}
          placement={"left"}
        >
          <label className={`field-label ${schema.required ? "required" : ""}`}>
            {camelCaseToWords(title || "")}
          </label>
        </Tooltip>

        <div className={"field-widget"}>
          {canAdd && (
            <button
              type="button"
              className={"array-field-add-button"}
              onClick={onAddClick}
            >
              <RemixIcon name={"menu-add-line"} />
              <span>Add</span>
            </button>
          )}
        </div>
        <div className={"field-expression-icon"}>
          {/* TODO: expression icon */}
        </div>
      </div>

      {/* Now show the items */}
      <div>
        {items.map((element: any, index: number) => (
          <DraggableItem
            key={element.id}
            formContext={formContext}
            index={index}
            element={element}
          />
        ))}
      </div>
    </div>
  );
};
