import React, { useEffect, useMemo, useState } from "react";
import { Modal, Tooltip } from "antd";
import { RemixIcon } from "../../../components/Widgets";
import { WidgetPropertyPanel } from "../WidgetPropertyPanel";
import { TreeView } from "../../NavigatorPanel/TreeView";
import { FormRefContext } from "../../../models/FormRefContext";
import { NodeAction, useYamlDoc, YAMLDocNode } from "../../../hooks/useYamlDoc";
import { FieldProps } from "@rjsf/utils";
import { useCustomWidgets } from "../../../hooks/useCustomWidgets";
import { RootWidgetContext } from "./RootWidgetContext";
import { buildWidgetTree } from "../../../utils/treeUtils";
import { useSchemas } from "../../../hooks/useSchemas";
import { isMap, Pair, YAMLMap } from "yaml";
import { NodeRef } from "../../NavigatorPanel/TreeNode";
import { addChildWidget, getKeyValue } from "../../../utils/schemaUtils";
import { WidgetItemPicker } from "../../../components/VisualEditor/ItemPicker";
import {
  camelCaseToWords,
  generatedDefaultValuesForWidgets,
} from "../utils/propertyPanelUtils";
import "./WidgetBuilderModal.sass";

type WidgetBuilderModalProps = FieldProps & {
  isOpened: boolean;
  setIsOpened: (isOpened: boolean) => void;
};

export const WidgetBuilderModal: React.FC<WidgetBuilderModalProps> = ({
  idSchema,
  formContext,
  isOpened,
  setIsOpened,
}) => {
  const { doc, updateNode, dispatchVisualEditorChanges } = useYamlDoc();
  const { widgetNames, containerNames, widgetChildrenOverrides } = useSchemas();
  const { customWidgets } = useCustomWidgets();
  const { validateForm, setHasFormChanges } = React.useContext(FormRefContext);

  // find the nodeContext, this allows us to build/update the document tree as needed
  const nodeContext = useMemo(() => {
    // idScheme has the path from the root to the key of the current field,
    // so we skip the first and last item (e.g. root_backgroundImage_fallback => ["backgroundImage_fallback"]
    return new RootWidgetContext(formContext, idSchema.$id);
  }, [formContext.node]); // eslint-disable-line

  // build the widget tree
  const widgetTreeNodes = useMemo(() => {
    const currentNode = nodeContext.getLeafNode();
    // empty tree
    if (!isMap(currentNode) || currentNode.items.length === 0) return null;

    const widgetList: ReadonlySet<string> = new Set([
      ...customWidgets,
      ...widgetNames,
    ]);
    return buildWidgetTree(
      currentNode,
      widgetList,
      containerNames,
      widgetChildrenOverrides,
      new Map(),
    );
  }, [customWidgets, nodeContext]); // eslint-disable-line

  const [selectedNodePair, setSelectedNodePair] = useState<
    YAMLDocNode | undefined
  >();
  const [selectedNodeParent, setSelectedNodeParent] = useState<YAMLMap | null>(
    null,
  );

  // Title for the modal
  const modalTitle = useMemo(() => {
    const rootWidget = getKeyValue(formContext.node);
    return [rootWidget!]
      .concat(nodeContext.paths.map((value) => String(value)))
      .map((value) => camelCaseToWords(value))
      .join(" • ");
  }, [formContext.node, nodeContext.paths]);

  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const onSelect = (key: string | null, ref: NodeRef | null) => {
    setSelectedKey(key);

    if (ref?.node instanceof Pair) {
      setSelectedNodePair(ref.node);
      setSelectedNodeParent(ref.parent);
    }
  };
  // select the first item if none is selected
  useEffect(() => {
    if (!selectedKey && widgetTreeNodes) {
      const firstNode = widgetTreeNodes[0];
      if (firstNode) {
        setSelectedKey(String(firstNode.key));
        onSelect(String(firstNode.key), firstNode.ref);
      }
    }
  }, [selectedKey, widgetTreeNodes]);

  // add a new widget or set the root widget
  const onWidgetSelectedAction = async (
    parentNode: YAMLMap | null,
    widgetKey: string,
    action: NodeAction,
  ) => {
    // create the new widget node
    const widgetRecord = await generatedDefaultValuesForWidgets(widgetKey);
    const widgetNode = doc?.createNode(widgetRecord);

    if (widgetNode) {
      // add a new widget to the existing widget tree
      if (action === NodeAction.AddChild) {
        if (!parentNode) return;
        addChildWidget(parentNode, widgetNode);
        updateNode(parentNode);
      }
      // set a new widget as the root
      else if (action === NodeAction.SetDialogWidget) {
        if (!doc) return;
        // note that the function's parentNode is not what we want.
        // We have to look into the nodeContext to find the correct parent,
        // as it could be nested several level deeps, and the parent might
        // or might not exist yet
        nodeContext.addLeafNode(doc, widgetKey, new YAMLMap());
        // const leafPair = nodeContext.getOrCreateLeafNodePair();
        // leafPair.value?.set(new Scalar(widgetKey), new YAMLMap());
        dispatchVisualEditorChanges();
      }
    }
  };

  // attempting to close the dialog
  const onCloseRequest = () => {
    if (validateForm()) {
      setIsOpened(false);
    } else {
      Modal.confirm({
        wrapClassName: "discard-changes-confirmation",
        title: "You have errors that require your attention",
        onOk: () => {
          setIsOpened(false);
          // discard existing changes
          setHasFormChanges(false);
        },
        okText: "Discard Changes and Continue",
        cancelText: "I'm fixing the problem",
      });
    }
  };

  return (
    <Modal
      className={"modal-container right-modal-container"}
      title={modalTitle}
      open={isOpened}
      closeIcon={
        <Tooltip title={"Go Back"} placement={"left"}>
          <span>
            <RemixIcon
              name={"close-line"}
              onClick={onCloseRequest}
              style={{ cursor: "pointer", fontSize: "1.5em" }}
            />
          </span>
        </Tooltip>
      }
      maskClosable={false}
      destroyOnClose={true}
      footer={null}
    >
      <div className={"widget-builder"}>
        <div className={"widget-builder-tree-panel"}>
          <div className={"tree-panel"}>
            <div className={"tree-block"}>
              {widgetTreeNodes ? (
                <div className={"tree-block-body"}>
                  <TreeView
                    treeData={widgetTreeNodes}
                    expandFirstNode={true}
                    selectedKey={selectedKey}
                    onSelect={onSelect}
                    onWidgetSelectedAction={onWidgetSelectedAction}
                    autoSelect={true}
                  />
                </div>
              ) : (
                <WidgetItemPicker
                  trigger={
                    <div className={"set-screen-or-widget-body"}>
                      Set Widget
                    </div>
                  }
                  onItemSelect={(widgetName) => {
                    onWidgetSelectedAction(
                      null,
                      widgetName,
                      NodeAction.SetDialogWidget,
                    );
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <div className={"widget-builder-property-panel property-panel"}>
          {selectedNodePair && (
            <WidgetPropertyPanel
              node={selectedNodePair}
              parentNode={selectedNodeParent}
              onNodeRemove={() => {
                setSelectedKey(null);
                setSelectedNodePair(undefined);
                setSelectedNodeParent(null);
              }}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};
