import { useMemo } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as IconHome } from "../assets/icon_home.svg";
import { ReactComponent as IconNext } from "../assets/icon_next.svg";

import Dropdown from "./Dropdown";
import { EnsembleArtifactHistoryData } from "../config/interfaces";
import { ReactComponent as IconAlert } from "../assets/icon_alert.svg";
import { ArtifactHistoryDropdown } from "../containers/ArtifactHistoryDropdown";
import { useAppContext } from "../pages/AppPagesWrapper";

type ScriptHeaderProps = {
  scriptId?: string;
  handleRestoreVersion: () => void;
  handleCloseDiffEditor: () => void;
  selectedHistory: EnsembleArtifactHistoryData | undefined;
  disableButton: boolean;
  setSelectedHistory: (history: EnsembleArtifactHistoryData) => void;
  scriptContent: string;
  onSave: (content: string) => void;
};

const ScriptHeader = ({
  scriptId,
  handleCloseDiffEditor,
  handleRestoreVersion,
  selectedHistory,
  disableButton,
  setSelectedHistory,
  scriptContent,
  onSave,
}: ScriptHeaderProps) => {
  const { app, isAppReadOnly } = useAppContext();
  const screenDetails = useMemo(() => {
    return app.internalScripts?.find((script) => script.id === scriptId);
  }, [app.internalScripts, scriptId]);

  const scriptsList = useMemo(() => {
    return app.internalScripts?.map((script) => ({
      id: script.id,
      text: script.name,
      clickHandler: () => {
        window.location.href = `/app/${app.id}/script/${script.id}`;
      },
    }));
  }, [app.id, app.internalScripts]);

  const hasUnsavedChanges = useMemo(() => {
    const normalizedEditorContent = scriptContent.trim();
    const normalizedScriptContent = screenDetails?.content?.trim();
    return (
      screenDetails?.content != undefined &&
      normalizedEditorContent !== normalizedScriptContent
    );
  }, [scriptContent, screenDetails?.content]);

  const isSaveDisabled =
    (!hasUnsavedChanges || disableButton) && !isAppReadOnly;

  return (
    <div className="script-header">
      <div className="breadcrumbs">
        <Link to="/">
          <IconHome className={"nav-icon"} />
        </Link>
        <IconNext className={"arrow-icon"} />
        <Link to={`/app/${app?.id}/screens`}>{app?.name}</Link>
        {scriptId && (
          <>
            <IconNext className={"arrow-icon"} />
            <Link to={`/app/${app?.id}/scripts`}>Scripts</Link>
          </>
        )}
        <IconNext className={"arrow-icon"} />
        <Dropdown activatorText={screenDetails?.name} items={scriptsList} />
      </div>
      <div className="action-menus">
        {!isAppReadOnly && (
          <>
            {selectedHistory ? (
              <div className="selected-history-options">
                <span
                  className="close-diff-text"
                  onClick={handleCloseDiffEditor}
                >
                  Close diff editor
                </span>
                <span
                  className="close-diff-text"
                  onClick={handleRestoreVersion}
                >
                  Restore version
                </span>
              </div>
            ) : null}
            {hasUnsavedChanges && (
              <div className="unsaved-changes">
                <IconAlert className="unsaved-changes-icon" />
                <p>You have unsaved changes</p>
              </div>
            )}
            <ArtifactHistoryDropdown
              appId={app.id}
              artifactId={scriptId!}
              onSelectHistory={setSelectedHistory}
              selectedHistory={selectedHistory}
            />
            <div className="save-script">
              <button
                disabled={isSaveDisabled}
                className={`checkin-button ${
                  isSaveDisabled ? "disable-button" : ""
                }`}
                onClick={() => onSave(scriptContent)}
              >
                {"Save"}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ScriptHeader;
