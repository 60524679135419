import { FieldProps } from "@rjsf/utils";
import { Modal, Tooltip } from "antd";
import { RemixIcon } from "../../../components/Widgets";
import React, { useMemo, useState } from "react";
import { ActionPropertyPanel } from "../../NavigatorPanel/ActionPropertyPanel";
import "./ActionBuilderModal.sass";
import { getKeyValue } from "../../../utils/schemaUtils";
import { ActionNavigator } from "../../../components/VisualEditor/navigator/ActionNavigator";
import { RootWidgetContext } from "./RootWidgetContext";
import { ActionNodeData } from "../../../components/VisualEditor/navigator/ActionTreeBuilder";
import { useYamlDoc } from "../../../hooks/useYamlDoc";

export type ActionBuilderModalProps = FieldProps & {
  isOpened: boolean;
  setIsOpened: (isOpened: React.SetStateAction<boolean>) => void;
};

export const ActionBuilderModal: React.FC<ActionBuilderModalProps> = (
  props,
) => {
  const { formContext, idSchema } = props;
  const { isOpened, setIsOpened } = props;
  const [selectedItem, setSelectedItem] = useState<ActionNodeData | undefined>(
    undefined,
  );
  // const [treeChanges, setTreeChanges] = useState(0);
  const { removeNodeFromParent } = useYamlDoc();

  const handleItemUpdate = () => {
    // nothing to do here
  };

  const handleItemRemove = (actionData: ActionNodeData) => {
    removeNodeFromParent(formContext.node, actionData.rootNode);
    setSelectedItem(undefined);
  };

  const nodeContext = useMemo(
    () => new RootWidgetContext(formContext, idSchema.$id),
    [formContext, idSchema.$id],
  );

  return (
    <Modal
      className={"modal-container right-modal-container"}
      title={getKeyValue(formContext.node)}
      open={isOpened}
      keyboard={false}
      onCancel={(e) => {
        e.stopPropagation();
        setIsOpened(false);
      }}
      closeIcon={
        <Tooltip title={"Go Back"} placement={"left"}>
          <span>
            <RemixIcon
              name={"close-line"}
              style={{ cursor: "pointer", fontSize: "1.5em" }}
            />
          </span>
        </Tooltip>
      }
      maskClosable={false}
      footer={null}
    >
      <div className={"action-builder-panel"}>
        <ActionNavigator
          nodeContext={nodeContext}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
        <div className={"action-property-panel-container"}>
          {selectedItem && (
            <ActionPropertyPanel
              actionPayload={selectedItem}
              onItemUpdate={handleItemUpdate}
              onItemRemove={handleItemRemove}
              rootNode={formContext.node}
            />
          )}
          {/*<p>Select an Action to edit</p>*/}
        </div>
      </div>
    </Modal>
  );
};
