import React, { useState, useEffect, useRef, useCallback } from "react";
import { IDropdownItem } from "../config/interfaces";
import { debounce } from "lodash-es";

interface IDropdownProps {
  activatorText?: string | React.ReactNode;
  items?: IDropdownItem[] | [];
  className?: string;
  wrapperClass?: string;
  listClass?: string;
  itemClass?: string;
  hideCaret?: boolean;
  searchable?: boolean;
  onSearch?: (query: string) => void;
}

const Dropdown = ({
  activatorText = "",
  items = [],
  className = "",
  wrapperClass = "",
  listClass = "",
  itemClass = "",
  hideCaret = false,
  searchable = false,
  onSearch,
}: IDropdownProps) => {
  const listRef = useRef<HTMLUListElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const clicked = useRef(false);

  const handleClick = () => {
    if (clicked.current && !isOpen) {
      clicked.current = false;
    } else {
      setIsOpen((prevState) => !prevState);
      clicked.current = true;
    }
  };

  const handleClickOutside = useCallback(() => {
    clicked.current = true;
    if (isOpen) setIsOpen(false);
  }, [isOpen]);

  const keyHandler = (event: React.KeyboardEvent) => {
    if (isOpen) {
      switch (event.key) {
        case "Escape":
          setIsOpen(false);
          break;
        case "ArrowDown": {
          const nodeList = listRef.current!.querySelectorAll("a");
          if (activeIndex === items.length - 1) return;
          nodeList[activeIndex + 1].focus();
          break;
        }
        case "ArrowUp": {
          const nodeList2 = listRef.current!.querySelectorAll("a");
          if (activeIndex === 0) return;
          nodeList2[activeIndex - 1].focus();
          break;
        }
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mouseup", handleClickOutside);
    } else {
      document.removeEventListener("mouseup", handleClickOutside);
      setTimeout(() => (clicked.current = false), 300);
    }
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [handleClickOutside, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setActiveIndex(-1);
    }
  }, [isOpen]);

  const focusHandler = (index: number) => {
    setActiveIndex(index);
  };

  const debouncedSearch = useRef(
    debounce((query: string) => {
      if (onSearch) {
        onSearch(query);
      }
    }, 300),
  ).current;

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const query = event.target.value;
      setSearchQuery(query);
      debouncedSearch(query);
      if (!isOpen) {
        setIsOpen(true);
      }
    },
    [debouncedSearch, isOpen],
  );

  // Clean up the debounced search on unmount
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleItemClick = (item: IDropdownItem) => {
    const retVal = item.clickHandler?.();
    setSearchQuery(item.text?.toString() ?? "");
    setIsOpen(!!retVal);
  };

  const filteredItems = searchable
    ? items.filter((item) => {
        const itemText = String(item.text ?? "").toLowerCase();
        return itemText.includes(searchQuery.toLowerCase());
      })
    : items;

  return (
    <div className={`dropdown-wrapper ${wrapperClass}`} onKeyUp={keyHandler}>
      {searchable ? (
        <input
          type="text"
          className={`${className} dropdown-input`}
          placeholder={typeof activatorText === "string" ? activatorText : ""}
          value={
            searchQuery !== "" ? searchQuery : activatorText?.toString() ?? ""
          }
          onChange={handleSearchChange}
          onFocus={() => setIsOpen(true)}
        />
      ) : (
        <button
          className={`${className} ${
            hideCaret ? "no-dropdown-icon" : "dropdown-button"
          }`}
          aria-haspopup="false"
          aria-controls="dropdown"
          onClick={handleClick}
          type="button"
          onFocus={() => setActiveIndex(-1)}
        >
          {activatorText}
        </button>
      )}
      <ul
        id="dropdown"
        ref={listRef}
        role="list"
        style={{
          display:
            filteredItems.length === 0 ? "none" : isOpen ? "block" : "none",
        }}
        className={`${listClass}`}
      >
        {filteredItems.map((item, index) => (
          <li key={item.id}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleItemClick(item);
              }}
              onFocus={() => focusHandler(index)}
              className={`${itemClass}`}
            >
              {item.text}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
