import React from "react";
import { FieldProps } from "@rjsf/utils";

export const JSEditorField: React.FC<FieldProps> = ({
  idSchema,
  value,
  formData,
  onChange,
  onBlur,
}) => {
  const handleBlur = () => {
    onBlur(idSchema.$id, value);
  };

  return (
    <div>
      <textarea
        value={formData}
        onChange={(e) => onChange(e.target.value)}
        onBlur={handleBlur}
        style={{
          width: "100%",
          height: "100%",
          minHeight: "120px",
          maxHeight: "200px",
        }}
      />

      {/*<JavascriptEditor*/}
      {/*  value={formData}*/}
      {/*  onChange={onChange}*/}
      {/*  startInFullscreen={false}*/}
      {/*/>*/}
    </div>
  );
};
