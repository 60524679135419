import React, { useState, createRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../pages/AppPagesWrapper";

// APIs
import { useCreateScreens } from "../hooks/useAPIs";
import { AuthContext } from "../App";
import { starterScreen } from "../utils/templateUtils";

interface ScreenCreateModalProps {
  onCancel: () => void;
}

const ScreenCreateModal: React.FC<ScreenCreateModalProps> = () => {
  const navigate = useNavigate();
  const { app } = useAppContext();
  const { currentUser } = useContext(AuthContext);
  const [makeThisRoot, setMakeThisRoot] = useState(false);
  const [screenNameInput, setScreenNameInput] = useState("");

  const screen = {
    name: screenNameInput,
    isRoot: makeThisRoot,
    content: starterScreen,
    isArchived: false,
  };
  const [newScreens, setNewScreens] = useState([screen]);
  const createScreenQuery = useCreateScreens(currentUser, app.id, newScreens);
  const screenNameInputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    // check if this screen should be set to root
    const isFirstScreen = app?.screens?.length === 0;
    setMakeThisRoot(isFirstScreen);

    screenNameInputRef.current?.focus();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const screenNameChange = (event: React.FormEvent<HTMLInputElement>): void => {
    setScreenNameInput(event.currentTarget.value);
  };

  useEffect(() => {
    setNewScreens([screen]);
  }, [screenNameInput]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreateScreen = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    createScreenQuery.mutate();
  };

  useEffect(() => {
    if (screenNameInputRef.current) screenNameInputRef.current.focus();
  }, [screenNameInputRef]);

  useEffect(() => {
    if (createScreenQuery.isSuccess) {
      const newScreenId = createScreenQuery.data?.insert_screen.returning[0].id;
      navigate(`/app/${app.id}/screen/${newScreenId}`);
    }
  }, [createScreenQuery.status]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <form className="screen-create-modal" onSubmit={handleCreateScreen}>
        <label>Screen name</label>
        <input
          type="text"
          value={screenNameInput}
          placeholder="Enter screen name"
          onChange={screenNameChange}
          ref={screenNameInputRef}
        />

        <button
          className="button__primary"
          type="submit"
          disabled={!screenNameInput || createScreenQuery.isLoading}
        >
          {createScreenQuery.isIdle && <>Create screen</>}
          {createScreenQuery.isLoading && <>Processing...</>}
        </button>
      </form>
    </React.Fragment>
  );
};

export default ScreenCreateModal;
