import React, { useEffect, useState } from "react";
import { FieldProps } from "@rjsf/utils";
import "./BooleanField.sass";

export const BooleanField: React.FC<FieldProps<boolean>> = ({
  id,
  formData,
  onChange,
  formContext,
  schema,
}) => {
  const [hasPendingChanges, setHasPendingChanges] = useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
    setHasPendingChanges(true);
  };

  useEffect(() => {
    if (hasPendingChanges) {
      formContext.submitForm();
      setHasPendingChanges(false);
    }
  }, [hasPendingChanges]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={"boolean-field"}>
      <input
        type="checkbox"
        id={id}
        checked={formData ?? Boolean(schema.defaultValue) ?? false}
        onChange={handleChange}
      />
    </div>
  );
};
