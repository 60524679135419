import { type FC } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { timePassed } from "../../utils/functions";
import { DocumentReference } from "firebase/firestore";
import { EnsembleWidgetData } from "../../config/interfaces";
import WidgetActionsMenu from "../ActionMenus/WidgetActionsMenu";

interface WidgetCardProps {
  widget: EnsembleWidgetData;
  appId: string;
  isReadOnly: boolean;
}

const WidgetCard: FC<WidgetCardProps> = ({ widget, appId, isReadOnly }) => {
  return (
    <Link title={widget.description} to={`/app/${appId}/widget/${widget.id}`}>
      <div className="screen-card">
        <div className="screen-card__content">
          {!(widget.labelGroup instanceof DocumentReference) && (
            <div className="screen-card__content-label">
              {widget.labelGroup?.name}
            </div>
          )}
          <h4>{widget.name}</h4>
          <div className="screen-card__content-info">
            {widget.updatedAt && widget.updatedBy && (
              <span>
                {timePassed(widget.updatedAt)}, <>{widget.updatedBy.name}</>
              </span>
            )}
            <span>
              {widget.description && <span>{widget?.description}</span>}
            </span>
          </div>
          {/* screen actions */}
          {!isReadOnly && (
            <WidgetActionsMenu widget={widget} isReadOnly={isReadOnly} />
          )}
        </div>
      </div>
      <ReactTooltip effect="solid" place="top" />
    </Link>
  );
};

export default WidgetCard;
