import { useState, useRef, useEffect } from "react";
import SearchDialog from "./SearchDialog";

type SearchBarProps = {
  searchType: string;
  searchPlaceholder: string;
};

const SearchBar = ({ searchType, searchPlaceholder }: SearchBarProps) => {
  const [showSearchDialog, setShowSearchDialog] = useState(false);
  const [search, setSearch] = useState("");
  const dialogRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dialogRef.current &&
        !dialogRef.current.contains(event.target as Node) &&
        event.target !== document.querySelector(".search-bar input")
      ) {
        handleCloseSearchDialog();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCloseSearchDialog = () => {
    setShowSearchDialog(false);
    setSearch("");
  };

  return (
    <div className="search-bar">
      <input
        type="text"
        placeholder={searchPlaceholder}
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
          setShowSearchDialog(e.target.value !== "");
        }}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            handleCloseSearchDialog();
          }
        }}
      />
      {showSearchDialog && (
        <div ref={dialogRef}>
          <SearchDialog search={search} searchType={searchType} />
        </div>
      )}
    </div>
  );
};

export default SearchBar;
