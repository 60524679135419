import { convertToBase64 } from "./uploadUtils";
import { cloudFunctions } from "../config/firebase";

export const generateEDLFromImage = async (
  appId: string,
  file: File,
  screenName?: string,
) => {
  const fileData = await convertToBase64(file);
  return cloudFunctions.builder_generate_screen({
    appId,
    screenName,
    fileName: file.name,
    fileData,
  });
};
