import React, { useState } from "react";
import { Id, toast } from "react-toastify";
import { verifyAppInvite, rejectAppInvite } from "../../hooks/useAPIs";
import { useNavigate } from "react-router-dom";

export interface Invite {
  ref: string;
  template: {
    data: {
      userName: string;
      appName: string;
      appId: string;
    };
  };
}

const InviteItem: React.FC<{
  invite: Invite;
  refreshInvites: () => void;
}> = ({ invite, refreshInvites }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const notify = (message: string, type: "success" | "error", toastId?: Id) => {
    const options = {
      render: message,
      type,
      isLoading: false,
      autoClose: 4000,
    };

    if (toastId) {
      toast.update(toastId, options);
    } else {
      toast(message, { position: "top-right", theme: "dark", ...options });
    }
  };

  const handleInviteAction = async <T = void,>(
    action: () => Promise<T>,
    successMessage: string,
  ) => {
    const toastId = toast.loading("Please wait...", {
      position: "top-right",
      theme: "dark",
      autoClose: false,
    });

    setLoading(true);
    try {
      const result = await action();
      notify(successMessage, "success", toastId);
      return result;
    } catch (error: any) {
      notify(error.message ?? "An error occurred", "error", toastId);
    } finally {
      refreshInvites();
      setLoading(false);
    }
  };

  const acceptInvite = (ref: string, appId: string) => {
    handleInviteAction(
      () => verifyAppInvite(ref),
      "Successfully added to collaborators.",
    ).then(() => navigate(`/app/${appId}/screens`));
  };

  const rejectInvite = (ref: string) => {
    handleInviteAction(() => rejectAppInvite(ref), "Invite dismissed.");
  };

  return (
    <div className="invite-item">
      <div>
        {invite.template.data.userName} invited you to{" "}
        <span style={{ fontWeight: "bold" }}>
          {invite.template.data.appName}
        </span>
      </div>
      <div className="invite-buttons">
        <button
          className="dismiss-button"
          onClick={() => rejectInvite(invite.ref)}
          disabled={loading}
        >
          Dismiss
        </button>
        <button
          className="button__primary"
          onClick={() => acceptInvite(invite.ref, invite.template.data.appId)}
          disabled={loading}
        >
          Accept invite
        </button>
      </div>
    </div>
  );
};

export default InviteItem;
