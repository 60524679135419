import config from "./config";
import firebase from "firebase/compat/app";
import { labelGroupData } from "./interfaces";
import "firebase/compat/auth";
import { initializeFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import {
  getFunctions,
  httpsCallable,
  // connectFunctionsEmulator,
} from "firebase/functions";

export const firebaseApp = firebase.initializeApp(config.firebase);
export const auth = getAuth(firebaseApp);
export const db = initializeFirestore(firebaseApp, {
  experimentalAutoDetectLongPolling: true,
});

export const analytics = getAnalytics(firebaseApp);

// DEV only, route to localhost
// const functions = getFunctions(firebaseApp);
// connectFunctionsEmulator(functions, "localhost", 5001);

export const cloudFunctions = {
  studio_upload_asset: httpsCallable(getFunctions(), "studio-uploadAsset"),
  studio_delete_asset: httpsCallable(getFunctions(), "studio-deleteAsset"),
  studio_upload_font: httpsCallable(getFunctions(), "studio-addFont"),
  studio_clone_app: httpsCallable<
    { appId: string; cloneCollaborators: boolean },
    { appId: string; cloneCollaborators: boolean }
  >(getFunctions(), "studio-cloneApp"),
  studio_build_config: httpsCallable(getFunctions(), "studio-getBuildConfig"),
  studio_verifyAppInvite: httpsCallable(
    getFunctions(),
    "studio-verifyAppInvite",
  ),
  studio_rejectAppInvite: httpsCallable(
    getFunctions(),
    "studio-rejectAppInvite",
  ),
  studio_getAppsInvites: httpsCallable(getFunctions(), "studio-getAppsInvites"),
  studio_add_labels: httpsCallable<{ appId: string; labels: string[] }>(
    getFunctions(),
    "studio-addLabels",
  ),
  studio_delete_labels: httpsCallable<{ appId: string; labels: string[] }>(
    getFunctions(),
    "studio-deleteLabels",
  ),
  studio_update_labels: httpsCallable<{
    appId: string;
    labels: labelGroupData[];
  }>(getFunctions(), "studio-updateLabels"),

  // builder
  builder_generate_screen: httpsCallable(
    getFunctions(),
    "builder-generatescreen",
    {
      // 10 minutes timeout
      timeout: 600000,
    },
  ),
  builder_query_doc: httpsCallable(getFunctions(), "builder-querydoc"),
};
