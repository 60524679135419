import { useState, useCallback, useMemo } from "react";

const useKeyboardNavigationGrid = (
  groups: { items: { label: string; value: string }[] }[],
  itemsPerRow: number = 3,
) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const flatItems = useMemo(
    () => groups.flatMap((group) => group.items),
    [groups],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!flatItems.length) return;

      let newIndex = activeIndex;

      switch (event.key) {
        case "ArrowRight":
          newIndex = (activeIndex + 1) % flatItems.length;
          break;
        case "ArrowLeft":
          newIndex = (activeIndex - 1 + flatItems.length) % flatItems.length;
          break;
        case "ArrowDown":
          newIndex = (activeIndex + itemsPerRow) % flatItems.length;
          break;
        case "ArrowUp":
          newIndex =
            (activeIndex - itemsPerRow + flatItems.length) % flatItems.length;
          break;
        default:
          break;
      }

      setActiveIndex(newIndex);
    },
    [activeIndex, flatItems, itemsPerRow],
  );

  return [activeIndex, handleKeyDown] as const;
};

export default useKeyboardNavigationGrid;
